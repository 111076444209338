seller_admin.survey = {

    archive: function () {
        $('body').on('click', '.archive-survey', function () {
            var $this = $(this),
                url = $this.attr('href'),
                surveyId = $this.attr('data-survey-id'),
                surveySearchTable = $('table#survey-search-table'),
                surveyRow = $('tr.survey-' + surveyId);

            seller_admin.base.assignLoadingModal("loadingArchiveSurvey", "Archiving your survey...");
            seller_admin.base.enableLoadingModal("#loadingArchiveSurvey", $this);

            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    dataType: 'json',
                    success: function (data) {
                        if (surveySearchTable.length > 0) {
                            surveyRow.replaceWith(data.row);
                        } else {
                            surveyRow.remove();
                        }
                        seller_admin.base.disableLoadingModal("#loadingArchiveSurvey", $this);
                        seller_admin.base.addFlash("success", "Successfully archived a survey.");
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingArchiveSurvey", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    unarchive: function () {
        $('body').on('click', '.unarchive-survey', function () {
            var url = $(this).attr('href'),
                $this = $(this),
                surveyId = $this.attr('data-survey-id'),
                surveySearchTable = $('table#survey-search-table'),
                surveyRow = $('tr.survey-' + surveyId);

            seller_admin.base.assignLoadingModal("loadingUnarchiveSurvey", "Unarchiving your survey...");
            seller_admin.base.enableLoadingModal("#loadingUnarchiveSurvey", $this);

            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    dataType: 'json',
                    success: function (data) {
                        if (surveySearchTable.length > 0) {
                            surveyRow.replaceWith(data.row);
                        } else {
                            surveyRow.remove();
                        }
                        seller_admin.base.disableLoadingModal("#loadingUnarchiveSurvey", $this);
                        seller_admin.base.addFlash("success", "Successfully unarchived a survey.");
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingUnarchiveSurvey", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    initOptionsSortable: function () {
        $('#sortable-survey-option-list').sortable({
            placeholder: "ui-state-highlight",
            forcePlaceholderSize: true,
            tolerance: "pointer",
            update: function (e, ui) {
                seller_admin.base.enableLoadingModal("#loadingSortSurveyOptions", $('#sort-survey-options-form'));
                $.ajax(
                    {
                        url: $(this).data("url"),
                        type: "PATCH",
                        data: $(this).sortable('serialize'),
                        success: function (data) {
                            seller_admin.base.disableLoadingModal("#loadingSortSurveyOptions", $('#sort-survey-options-form'));
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingSortSurveyOptions", $('#sort-survey-options-form'));
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
            }
        });
        $("#sortable-survey-option-list").disableSelection();
    },

    sortTemplateCategoriesModal: function () {
        $('body').on('click', '.reorder-survey-options', function () {
            var url = $(this).attr('href');

            seller_admin.base.assignLoadingModal("loadingSortSurveyOptions", "Sorting your survey options...");
            seller_admin.base.enableLoadingModal("#loadingSortSurveyOptions", $('#sort-survey-options-form'));

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#sort-survey-options-wrapper').html(data.modal);
                        seller_admin.base.disableLoadingModal("#loadingSortSurveyOptions", $('#sort-survey-options-form'));
                        seller_admin.base.openModal('#sort-survey-options-form');
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingSortSurveyOptions", $('#sort-survey-options-form'));
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    addOption: function (link, association, content) {
        var new_id = new Date().getTime(),
            regexp = new RegExp("new_" + association, "g");
        $("#survey-options").append(content.replace(regexp, new_id));
        var selectTags = $('div[data-fields-id="' + new_id + '"] select');
    },

    removeOption: function (link) {
        $(link).prev("input[type=hidden]").val("1");
        $(link).closest(".fields").hide();
    },
}
seller_admin.billing =
    {
        updateCountryAlpha2: function()
        {
            $('#billing_country').change(function () {
                $('#update-payment-submit').attr('disabled', true);
                $.ajax(
                    {
                        url: '/countries/' + $(this).val(),
                        type: 'GET',
                        dataType: 'json',
                        success: function (data) {
                            $('#country_alpha_2').val(data.alpha_2);
                            $('#update-payment-submit').attr('disabled', false);
                        },
                        error: function (xhr, evt, status) {
                            $('#update-payment-submit').attr('disabled', false);
                        }
                    });
            });
        }
    }
window.seller_admin =
    {
        base: {},
        image: {},
        inventory: {},
        collection: {},
        product: {},
        variant: {},
        order: {},
        subscription: {},
        transaction: {},
        tax_rule: {},
        plan: {},
        gift: {},
        stock_movement: {},
        funnel: {},
        onboarding: {},
        notification: {},
        email_integration: {},
        email_integration_rule: {},
        billing: {},
        nav_item: {},
        template: {},
        discount: {},
        setting: {},
        cart_abandonment: {},
        member_dashboard: {},
        member_category: {},
        member_post: {},
        shipping: {},
        order_bump: {},
        blog_category: {},
        blog_post: {},
        page_designer: {},
        dashboard: {},
        customer: {},
        checkout_trust: {},
        checkout_additional_fee: {},
        vendor: {},
        app_integration: {},
        order_item: {},
        pick_up_location: {},
        pick_up_time: {},
        pause_option: {},
        survey: {},
        churn: {},
        incentive_email: {},
        review_email: {},
        dunning_email: {},
        lite: {},
        free_gift: {},
        dunning: {},
        api: {}
    };
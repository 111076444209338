seller_admin.member_category =
    {
        archive: function () {
            $('body').on('click', '.archive-member-category', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    memberCategoryId = $this.attr('data-member-category-id'),
                    memberCategorytSearchTable = $('table#member-category-search-table'),
                    memberCategoryRow = $('tr.member-category-' + memberCategoryId);

                seller_admin.base.assignLoadingModal("loadingArchiveMemberCategory", "Archiving your member category...");
                seller_admin.base.enableLoadingModal("#loadingArchiveMemberCategory", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (memberCategorytSearchTable.length > 0) {
                                memberCategoryRow.replaceWith(data.row);
                            } else {
                                memberCategoryRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingArchiveMemberCategory", $this);
                            seller_admin.base.addFlash("success", "Successfully archived a member category.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingArchiveMemberCategory", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        unarchive: function () {
            $('body').on('click', '.unarchive-member-category', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    memberCategoryId = $this.attr('data-member-category-id'),
                    memberCategorytSearchTable = $('table#member-category-search-table'),
                    memberCategoryRow = $('tr.member-category-' + memberCategoryId);

                seller_admin.base.assignLoadingModal("loadingUnarchiveMemberCategory", "Unarchiving your member category...");
                seller_admin.base.enableLoadingModal("#loadingUnarchiveMemberCategory", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (memberCategorytSearchTable.length > 0) {
                                memberCategoryRow.replaceWith(data.row);
                            } else {
                                memberCategoryRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveMemberCategory", $this);
                            seller_admin.base.addFlash("success", "Successfully unarchived a member category.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveMemberCategory", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        initSortable: function () {
            $('#sortable-member-category-list').sortable({
                placeholder: "ui-state-highlight",
                forcePlaceholderSize: true,
                tolerance: "pointer",
                update: function (e, ui) {
                    seller_admin.base.enableLoadingModal("#loadingSortMemberCategory", $('#sort-member-categories-form'));
                    $.ajax(
                        {
                            url: $(this).data("url"),
                            type: "PATCH",
                            data: $(this).sortable('serialize'),
                            success: function (data) {
                                $('#member-categories-table tbody').html(data.table);
                                seller_admin.base.disableLoadingModal("#loadingSortMemberCategory", $('#sort-member-categories-form'));
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.disableLoadingModal("#loadingSortMemberCategory", $('#sort-member-categories-form'));
                                seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                                seller_admin.base.scrollTop("main");
                            }
                        });
                }
            });
            $("#sortable-member-category-list").disableSelection();
        },

        sortModal: function () {
            $('body').on('click', '#reorder-member-categories', function () {
                var url = $(this).attr('href');

                seller_admin.base.assignLoadingModal("loadingSortMemberCategory", "Sorting your member categories...");
                seller_admin.base.enableLoadingModal("#loadingSortMemberCategory", $('#sort-member-categories-form'));

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#sort-member-categories-wrapper').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingSortMemberCategory", $('#sort-member-categories-form'));
                            seller_admin.base.openModal('#sort-member-categories-form');
                        }
                    });
                return false;
            });
        },

        initIconPicker: function () {
            var formElem = $('.iconpicker');
            formElem.iconpicker();
            formElem.on('iconpickerSelected', function (event) {
                $('label[for="' + formElem.attr('id') + '"').addClass('active');
                $('#member_category_icon_class').val(event.iconpickerValue);
                $('#icon_class_icon').html('<i class="' + event.iconpickerValue + '"></i>');
            });
            formElem.on('iconpickerSetValue', function (event) {
                $('#member_category_icon_class').val(event.iconpickerValue);
                $('#icon_class_icon').html('<i class="' + event.iconpickerValue + '"></i>');
            });
        },

        addAccessRule: function (link, association, content) {
            var new_id = new Date().getTime(),
                regexp = new RegExp("new_" + association, "g");
            $('#access-rules-header').after(content.replace(regexp, new_id));
            var selectTags = $('div[data-fields-id="' + new_id + '"] select');
            selectTags.materialSelect()
            selectTags.each(function () {
                $(this).click(e => e.stopPropagation());
            });
        },

        removeAccessRule: function (link) {
            $(link).prev("input[type=hidden]").val("1");
            $(link).closest(".fields").hide();
            var wrapperElem = $(link).closest('.section');
            wrapperElem.hide();
        }
    }
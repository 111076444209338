seller_admin.dashboard = {

    buildOnPageLoad: function() {
        seller_admin.dashboard.buildInitial();
        // seller_admin.dashboard.buildOrders();
        // seller_admin.dashboard.buildRevenue();
        // seller_admin.dashboard.buildVisitors();
        // seller_admin.dashboard.buildConversions();
    },

    resetData: function() {
        $('body').on('click', '#reset-dashboard-data', function() {

            seller_admin.base.assignLoadingModal("loadingDashboardData", "Refreshing Dashboard...");
            seller_admin.base.enableLoadingModal("#loadingDashboardData", $('main'));

            $.ajax(
                {
                    url: '/admin/dashboard/reset',
                    type: 'PATCH',
                    dataType: 'json',
                    success: function (data) {
                        seller_admin.base.disableLoadingModal("#loadingDashboardData", $('main'));
                        seller_admin.dashboard.buildInitial();
                        // seller_admin.dashboard.buildOrders();
                        // seller_admin.dashboard.buildRevenue();
                        // seller_admin.dashboard.buildVisitors();
                        // seller_admin.dashboard.buildConversions();
                    }
                });
            return false;
        });
    },

    buildInitial: function () {
        $.ajax(
            {
                url: '/admin/dashboard/initial',
                type: "GET",
                dataType: "json",
                success: function (data) {
                    $('#initial-total-active-subscriptions').text(data.total_active_subscriptions);
                    $('#initial-average-order-value').text(data.average_order_value);
                    $('#initial-total-shop-orders').text(data.total_shop_orders);
                    $('#initial-total-revenue').text(data.total_revenue);
                }
            });
    },

    buildOrders: function () {
        $.ajax(
            {
                url: '/admin/dashboard/orders',
                type: "GET",
                dataType: "json",
                success: function (data) {
                    $('#orders-today').text(data.today);
                    $('#orders-yesterday').text(data.yesterday);
                    $('#orders-30-day').text(data.last_30_days);
                    $('#orders-90-day').text(data.last_90_days);
                }
            });
    },

    buildRevenue: function () {
        $.ajax(
            {
                url: '/admin/dashboard/revenue',
                type: "GET",
                dataType: "json",
                success: function (data) {
                    $('#revenue-today').text(data.today);
                    $('#revenue-yesterday').text(data.yesterday);
                    $('#revenue-30-day').text(data.last_30_days);
                    $('#revenue-90-day').text(data.last_90_days);
                }
            });
    },

    buildVisitors: function () {
        $.ajax(
            {
                url: '/admin/dashboard/visitors',
                type: "GET",
                dataType: "json",
                success: function (data) {
                    $('#visitors-today').text(data.today);
                    $('#visitors-yesterday').text(data.yesterday);
                    $('#visitors-30-day').text(data.last_30_days);
                    $('#visitors-90-day').text(data.last_90_days);
                }
            });
    },

    updateDataByDate: function () {
        $('#dashboard-conversions .dateranger').on('apply.daterangepicker', function(ev, picker) {
            var dateRange = $(this).val().replace(/ /g,'');

            seller_admin.dashboard.buildConversions(dateRange);
        });
    },

    buildConversions: function(dateRange)
    {
        $.ajax(
            {
                url: '/admin/dashboard/date_range?start_end_range=' + dateRange,
                type: "GET",
                dataType: "json",
                success: function (data) {
                    $('#dashboard-conversions #added-to-cart-conversion').text(data.added_to_cart + '%');
                    $('#dashboard-conversions #reached-checkout-conversion').text(data.reached_checkout + '%');
                    $('#dashboard-conversions #purchased-conversion').text(data.purchased + '%');
                    seller_admin.chart.completedOrdersByDateRange(data.orders);
                    seller_admin.chart.popularProductsByDateRange(data.products);
                }
            });
    }
}
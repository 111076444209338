seller_admin.churn =
    {
        updateDataByDate: function () {
            $('#churn-date-ranger').on('apply.daterangepicker', function(ev, picker) {
                var dateRange = $(this).val().replace(/ /g,'');

                seller_admin.churn.getChartData(dateRange);
            });
            $('select#churn-product-id').on('change', function()
            {
                var dateRange = $('#churn-date-ranger').val().replace(/ /g,'');
                seller_admin.churn.getChartData(dateRange);
            });
        },

        getChartData: function (dateRange) {
            var productId = $('#churn-product-id').val();

            $.ajax(
                {
                    url: '/admin/churn/analytics/date_range?start_end_range=' + dateRange + '&product_id=' + productId,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#churn-data #save-rate').text(data.save_rate + '%');
                        $('#churn-data #total-cancel-triggers').text(data.total_cancel_triggers);
                        $('#churn-data #total-subscriptions-saved').text(data.total_subscriptions_saved);
                        $('#churn-data #estimated-mrr-saved').text(data.estimated_mrr_saved);
                        seller_admin.chart.surveyAnswersByDateRange(data.survey_options);
                    }
                });
        }
    }
seller_admin.variant =
    {
        add: function () {
            $('body').on('click', '.create-variant', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingAddVariant", "Creating variant...");
                seller_admin.base.enableLoadingModal("#loadingAddVariant", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#variant-wrapper').html(data.modal);
                            seller_admin.base.openModal('#variant-form');
                            $('#variant-wrapper .mdb-select').materialSelect();
                            $('#variant-wrapper-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingAddVariant", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingAddVariant", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        create: function () {
            $('body').on('submit', '#create-variant', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingAddVariant", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#variant-form').modal('hide');
                            $('#variants-table tbody').prepend(data.row);
                            seller_admin.base.addCustomFlash('#variants-section', 'success', 'Successfully added a variant to your product.');
                            seller_admin.base.disableLoadingModal("#loadingAddVariant", $this);
                            seller_admin.base.scrollTop('#variants-section');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingAddVariant", $this);
                        }
                    });
                return false;
            });
        },

        edit: function () {
            $('body').on('click', '.edit-variant', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingEditVariant", "Updating variant...");
                seller_admin.base.enableLoadingModal("#loadingEditVariant", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#variant-wrapper').html(data.modal);
                            seller_admin.base.openModal('#variant-form');
                            $('#variant-wrapper .mdb-select').materialSelect();
                            $('#variant-wrapper-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingEditVariant", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingEditVariant", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        update: function () {
            $('body').on('submit', '#edit-variant', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingEditVariant", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#variant-form').modal('hide');
                            $('#variants-table tbody tr#variant-' + data.variant_id).replaceWith(data.row);
                            seller_admin.base.addCustomFlash('#variants-section', 'success', 'Successfully updated a variant for your product.');
                            seller_admin.base.disableLoadingModal("#loadingEditVariant", $this);
                            seller_admin.base.scrollTop('#variants-section');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingEditVariant", $this);
                        }
                    });
                return false;
            });
        },

        archive: function()
        {
            $('body').on('click', '.prompt-archive-variant', function() {
                var variantId = $(this).attr('data-record-id'),
                    productId = $(this).attr('data-secondary-record-id');

                seller_admin.base.assignConfirmationModal('archive-variant',
                    'Are you sure you wish to remove a variant?', variantId, productId);
                seller_admin.base.enableConfirmationModal();
                return false;
            });
            $('body').on('click', '.archive-variant', function () {
                var $this = $(this),
                    variantId = $this.attr('data-record-id'),
                    productId = $this.attr('data-secondary-record-id');

                seller_admin.base.disableConfirmationModal();
                seller_admin.base.assignLoadingModal("loadingArchiveVariant", "Archiving variant...");
                seller_admin.base.enableLoadingModal("#loadingArchiveVariant", $this);

                $.ajax(
                    {
                        url: '/admin/products/' + productId + '/variants/' + variantId + '/archive',
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('tr#variant-' + variantId).remove();
                            seller_admin.base.addCustomFlash('#variants-section', 'success', 'Successfully archived a variant for your product.');
                            seller_admin.base.disableLoadingModal("#loadingArchiveVariant", $this);
                            seller_admin.base.scrollTop('#variants-section');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingArchiveVariant", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        initTagit: function () {
            $('.option-type-tagit').tagit({
                singleField: true,
                allowSpaces: true,
                afterTagAdded: function (event, ui) {
                    seller_admin.variant.refreshVariants();
                },
                afterTagRemoved: function (event, ui) {
                    seller_admin.variant.refreshVariants();
                }
            });

        },

        addOptionType: function () {
            $('body').on('click', '#add-option-type', function () {
                var $this = $(this);

                seller_admin.base.assignLoadingModal("loadingAddOptionType", "Creating option...");
                seller_admin.base.enableLoadingModal("#loadingAddOptionType", $this);

                $.ajax(
                    {
                        url: '/admin/products/option_types/new',
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            if ($('.option-type-cluster').length < 3) {
                                $('#option-types').append(data.fields);
                            }
                            seller_admin.variant.optionTypeFull();
                            seller_admin.base.disableLoadingModal("#loadingAddOptionType", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingAddOptionType", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        removeOptionType: function () {
            $('body').on('click', '.remove-option-type', function () {
                $(this).parent().parent().parent().remove();

                seller_admin.variant.optionTypeFull();
                seller_admin.variant.refreshVariants();
                return false;
            });
        },

        optionTypeFull: function () {
            if ($('.option-type-cluster').length === 3) {
                $('#add-option-type').hide();
            } else {
                $('#add-option-type').show();
            }
        },

        refreshVariants: function () {
            var variantJson = {},
                masterVariantPrice = $('#master-variant-price').val(),
                masterVariantStock = $('#master-variant-stock').val(),
                productSku = $('#product_sku').val(),
                $this = $(this);

            $('.option-type-cluster').each(function () {
                var optionType = $(this).find('.option-type').val(),
                    optionValue = $(this).find('.option-values').val();

                if (optionType && optionValue) {
                    variantJson[optionType] = optionValue;
                }
            });
            if (Object.keys(variantJson).length > 0) {
                seller_admin.base.assignLoadingModal("loadingUpdateVariants", "Updating variants...");
                seller_admin.base.enableLoadingModal("#loadingUpdateVariants", $this);
                $.ajax(
                    {
                        url: '/admin/products/variants/collection',
                        type: "POST",
                        data: {
                            variant: {variants: variantJson},
                            master_price: masterVariantPrice,
                            master_stock: masterVariantStock,
                            product_sku: productSku
                        },
                        dataType: "json",
                        success: function (data) {
                            $('#new_variants tbody').html(data.new_collection);
                            seller_admin.base.disableLoadingModal("#loadingUpdateVariants", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUpdateVariants", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
            } else {
                $('#new_variants tbody').html('');
            }
        },

        sortEditOptionTypesModal: function () {
            $('body').on('click', '#reorder-option-types', function () {
                var url = $(this).attr('href');


                seller_admin.base.assignLoadingModal("loadingSortOptionValues", "Sorting option types...");
                seller_admin.base.enableLoadingModal("#loadingSortOptionValues", $('#sort-option-types-form'));

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#sort-option-types-wrapper').html(data.modal);
                            seller_admin.base.openModal('#sort-option-types-form');
                            seller_admin.base.disableLoadingModal("#loadingSortOptionValues", $('#sort-option-types-form'));
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingSortOptionValues", $('#sort-option-types-form'));
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        initOptionTypeSortable: function () {
            $('#sortable-option-type-list').sortable({
                placeholder: "ui-state-highlight",
                forcePlaceholderSize: true,
                tolerance: "pointer",
                update: function (e, ui) {
                    seller_admin.base.enableLoadingModal("#loadingSortOptionValues", $('#sort-option-types-form'));
                    $.ajax(
                        {
                            url: $(this).data("url"),
                            type: "PATCH",
                            data: $(this).sortable('serialize'),
                            success: function (data) {
                                $('#variants-table').html(data.table);
                                $('#variant-value-reorder-links').html(data.reorder_links);
                                seller_admin.base.disableLoadingModal("#loadingSortOptionValues", $('#sort-option-types-form'));
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.disableLoadingModal("#loadingSortOptionValues", $('#sort-option-types-form'));
                                seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                                seller_admin.base.scrollTop("main");
                            }
                        });
                }
            });
            $("#sortable-option-type-list").disableSelection();
        },

        sortEditOptionValuesModal: function () {
            $('body').on('click', '.reorder-option-values', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    optionTypeName = $this.attr('data-option-type-name');

                seller_admin.base.assignLoadingModal("loadingSortOptionValues", "Sorting " + optionTypeName + " values...");
                seller_admin.base.enableLoadingModal("#loadingSortOptionValues", $('#sort-option-values-form'));

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#sort-option-values-wrapper').html(data.modal);
                            seller_admin.base.openModal('#sort-option-values-form');
                            seller_admin.base.disableLoadingModal("#loadingSortOptionValues", $('#sort-option-values-form'));
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingSortOptionValues", $('#sort-option-values-form'));
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        initSortableOptionValueList: function () {
            $('#sortable-option-value-list').sortable({
                placeholder: "ui-state-highlight",
                forcePlaceholderSize: true,
                tolerance: "pointer",
                update: function (e, ui) {
                    seller_admin.base.enableLoadingModal("#loadingSortOptionValues", $('#sort-option-values-form'));
                    $.ajax(
                        {
                            url: $(this).data("url"),
                            type: "PATCH",
                            data: $(this).sortable('serialize'),
                            success: function (data) {
                                $('#variants-table').html(data.table);
                                $('#variant-value-reorder-links').html(data.reorder_links);
                                seller_admin.base.disableLoadingModal("#loadingSortOptionValues", $('#sort-option-values-form'));
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.disableLoadingModal("#loadingSortOptionValues",  $('#sort-option-values-form'));
                                seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                                seller_admin.base.scrollTop("main");
                            }
                        });
                }
            });
            $("#sortable-option-value-list").disableSelection();
        },

        createModalOptiontype: function () {
            $('body').on('click', '#add-option-type-edit-product', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingAddOptionType", "Creating option...");
                seller_admin.base.enableLoadingModal("#loadingAddOptionType", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#add-option-types-wrapper').html(data.modal);
                            seller_admin.base.openModal('#add-option-type-form');
                            seller_admin.base.disableLoadingModal("#loadingAddOptionType", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingAddOptionType", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        createOptionType: function () {
            $("body").on("submit", '#create-option-type', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingAddOptionType", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#add-option-type-form').modal('hide');
                            $('#variants-table').html(data.table);
                            $('#variant-value-reorder-links').html(data.reorder_links);
                            seller_admin.base.addCustomFlash('#variants-section', 'success', 'Successfully created an option type from your product.');
                            seller_admin.base.disableLoadingModal("#loadingAddOptionType", $this);
                            seller_admin.base.scrollTop("#variants-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingAddOptionType", $this);
                        }
                    });
                return false;
            });
        },

        removeModalOptiontype: function () {
            $('body').on('click', '#remove-option-type-edit-product', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingRemoveOptionType", "Removing option type...");
                seller_admin.base.enableLoadingModal("#loadingRemoveOptionType", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#remove-option-types-wrapper').html(data.modal);
                            seller_admin.base.openModal('#remove-option-type-form');
                            $('#remove-option-type .mdb-select').materialSelect();
                            $('#remove-option-type .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingRemoveOptionType", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingRemoveOptionType", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        removeModalOptiontypeSubmit: function () {
            $("body").on("submit", '#remove-option-type', function () {
                var $this = $(this),
                    productId = $('#product_id').val(),
                    optionTypeId = $('#option_type_id').val();

                seller_admin.base.enableLoadingModal("#loadingRemoveOptionType", $this);

                $.ajax(
                    {
                        url: '/admin/products/' + productId + '/option_types/' + optionTypeId,
                        type: 'DELETE',
                        dataType: 'json',
                        success: function (data) {
                            $('#remove-option-type-form').modal('hide');
                            $('#variants-wrapper').html(data.variant_edit_product_form);
                            $('#variant-value-reorder-links').html(data.reorder_links);
                            seller_admin.base.addCustomFlash('#variants-section', 'success', 'Successfully removed an option type from your product.');
                            seller_admin.base.disableLoadingModal("#loadingRemoveOptionType", $this);
                            seller_admin.base.scrollTop("#variants-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingRemoveOptionType", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }
seller_admin.gift =
    {
        archive: function()
        {
            $('body').on('click', '.archive-gift', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    giftId = $this.attr('data-gift-id'),
                    giftSearchTable = $('table#gift-search-table'),
                    giftRow = $('tr.gift-' + giftId);

                seller_admin.base.assignLoadingModal("loadingArchiveGift", "Archiving your gift...");
                seller_admin.base.enableLoadingModal("#loadingArchiveGift", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (giftSearchTable.length > 0) {
                                giftRow.replaceWith(data.row);
                            } else {
                                giftRow.remove();
                            }

                            seller_admin.base.disableLoadingModal("#loadingArchiveGift", $this);
                            seller_admin.base.addFlash("success", "Successfully archived a gift.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingArchiveGift", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        initSortableList: function () {
            $('#sortable-product-gift-list').sortable({
                placeholder: "ui-state-highlight",
                forcePlaceholderSize: true,
                tolerance: "pointer",
                update: function (e, ui) {
                    var productId = $(this).data('product-id');
                    seller_admin.base.enableLoadingModal("#loadingSortGift", $('#sort-product-gifts-form'));
                    $.ajax(
                        {
                            url: $(this).data("url"),
                            type: "PATCH",
                            data: $(this).sortable('serialize'),
                            success: function (data) {
                                $('#product-' + productId + '-gift-table tbody').html(data.table);
                                seller_admin.base.disableLoadingModal("#loadingSortGift", $('#sort-product-gifts-form'));
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.disableLoadingModal("#loadingSortGift", $('#sort-product-gifts-form'));
                                seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                                seller_admin.base.scrollTop("main");
                            }
                        });
                }
            });
            $("#sortable-product-gift-list").disableSelection();
        },

        sortModal: function () {
            $('body').on('click', '.reorder-gifts', function () {
                var url = $(this).attr('href');

                seller_admin.base.assignLoadingModal("loadingSortGift", "Sorting your gifts...");
                seller_admin.base.enableLoadingModal("#loadingSortGift", $('#sort-product-gifts-form'));

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#sort-gifts-wrapper').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingSortGift", $('#sort-product-gifts-form'));
                            seller_admin.base.openModal('#sort-product-gifts-form');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingSortGift", $('#sort-product-gifts-form'));
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }
seller_admin.plan =
    {
        purchaseDateRebillSelect: function () {
            $("body").on('change', 'select#plan_purchase_date_rebill', function () {
                var val = $(this).val();
                seller_admin.plan.purchaseDateRebillCondition(val);
            });
        },

        purchaseDateRebillOnLoad: function () {
            var val = $('select#plan_purchase_date_rebill').val();
            seller_admin.plan.purchaseDateRebillCondition(val);
        },

        purchaseDateRebillCondition: function (value) {
            if (value === 'enabled') {
                $('#plan_cutoff_day_wrapper').hide();
                $('#plan_renewal_day_wrapper').hide();
            } else {
                $('#plan_cutoff_day_wrapper').show();
                $('#plan_renewal_day_wrapper').show();
            }
        },

        archive: function()
        {
            $('body').on('click', '.archive-plan', function () {
                var $this = $(this),
                    url = $(this).attr('href'),
                    planId = $this.attr('data-plan-id'),
                    planSearchTable = $('table#plan-search-table'),
                    planRow = $('tr.plan-' + planId);

                seller_admin.base.assignLoadingModal("loadingArchivePlan", "Archiving your subscription...");
                seller_admin.base.enableLoadingModal("#loadingArchivePlan", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (planSearchTable.length > 0) {
                                planRow.replaceWith(data.row);
                            } else {
                                planRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingArchivePlan", $this);
                            seller_admin.base.addFlash("success", "Successfully archived a subscription.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingArchivePlan", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        initSortableList: function () {
            $('#sortable-product-plan-list').sortable({
                placeholder: "ui-state-highlight",
                forcePlaceholderSize: true,
                tolerance: "pointer",
                update: function (e, ui) {
                    var productId = $(this).data('product-id');
                    seller_admin.base.enableLoadingModal("#loadingSortPlan", $('#sort-product-plans-form'));
                    $.ajax(
                        {
                            url: $(this).data("url"),
                            type: "PATCH",
                            data: $(this).sortable('serialize'),
                            success: function (data) {
                                $('#product-' + productId + '-plan-table tbody').html(data.table);
                                seller_admin.base.disableLoadingModal("#loadingSortPlan", $('#sort-product-plans-form'));
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.disableLoadingModal("#loadingSortPlan", $('#sort-product-plans-form'));
                                seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                                seller_admin.base.scrollTop("main");
                            }
                        });
                }
            });
            $("#sortable-product-plan-list").disableSelection();
        },

        sortModal: function () {
            $('body').on('click', '.reorder-plans', function () {
                var url = $(this).attr('href');

                seller_admin.base.assignLoadingModal("loadingSortPlan", "Sorting your subscriptions...");
                seller_admin.base.enableLoadingModal("#loadingSortPlan", $('#sort-product-gifts-form'));

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#sort-plans-wrapper').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingSortPlan", $('#sort-product-plans-form'));
                            seller_admin.base.openModal('#sort-product-plans-form');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingSortPlan", $('#sort-product-plans-form'));
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        planTypeSelect: function () {
            $("body").on('change', 'select#plan_plan_type', function () {
                var val = $(this).val();
                if (val === 'custom')
                {

                    $('#custom-interval-type-wrapper').css('display', 'flex');
                    $('#custom-interval-type').materialSelect();
                    $('#custom-interval-type').click(e => e.stopPropagation());
                    $('#custom-interval-value-wrapper').css('display', 'flex');
                    $('#custom-interval-value').materialSelect();
                    $('#custom-interval-value').click(e => e.stopPropagation());
                    $('#plan-rebilling').hide();
                    $('#custom-interval-save').show();
                } else {
                    $('#custom-interval-type-wrapper').hide();
                    $('#custom-interval-value-wrapper').hide();
                    $('#plan-rebilling').show();
                    $('#custom-interval-save').hide();
                }
            });
        }
    }
seller_admin.member_dashboard =
    {
        saveContent: function () {
            var accessToken = $('meta[name=api-access-token]').attr('content'),
                clientId = $('meta[name=api-client]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                uid = $('meta[name=api-uid]').attr('content'),
                wrapperContent = $('.is-wrapper'),
                html = window.contentBuilder.html();

            $.ajax(
                {
                    url: window.baseApiUrl + '/v1/store/member/dashboard',
                    headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                    type: 'PATCH',
                    data: {
                        'member_dashboard': {'content': html},
                        'version_number': wrapperContent.attr('data-version')
                    },
                    dataType: 'json',
                    success: function (data) {
                        wrapperContent.attr('data-version', data.version_number);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE SUCCESSFUL");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.page_designer.versionMismatchModal(xhr.responseJSON.version_mismatch);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE FAILED");
                    }
                });
        },

        autoSaveContent: function () {
            var accessToken = $('meta[name=api-access-token]').attr('content'),
                clientId = $('meta[name=api-client]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                uid = $('meta[name=api-uid]').attr('content'),
                wrapperContent = $('.is-wrapper'),
                html = window.contentBuilder.html();

            $.ajax(
                {
                    url: window.baseApiUrl + '/v1/store/member/dashboard',
                    headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                    type: 'PATCH',
                    data: {
                        'member_dashboard': {'content': html},
                        'version_number': wrapperContent.attr('data-version')
                    },
                    dataType: 'json',
                    success: function (data) {
                        seller_admin.base.successfulDesignerAutoSave();
                        wrapperContent.attr('data-version', data.version_number);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE SUCCESSFUL");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.failedDesignerAutoSave();
                        seller_admin.page_designer.versionMismatchModal(xhr.responseJSON.version_mismatch);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE FAILED");
                    }
                });
        }
    }
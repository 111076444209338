seller_admin.email_integration_rule =
    {

        add: function () {
            $('body').on('click', '.create-email-integration-rule', function () {
                var $this = $(this),
                    url = $this.attr('data-url');

                seller_admin.base.assignLoadingModal("loadingAddEmailIntegrationRule", "Creating email integration rule...");
                seller_admin.base.enableLoadingModal("#loadingAddEmailIntegrationRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#email-integration-rule-modal').html(data.modal);
                            seller_admin.base.openModal('#email-integration-rule-form');
                            $('#email-integration-rule-modal .mdb-select').materialSelect();
                            $('#email-integration-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingAddEmailIntegrationRule", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingAddEmailIntegrationRule", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        create: function () {
            $('body').on('submit', '#create-email-integration-rule', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingAddEmailIntegrationRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#email-integration-rule-form').modal('hide');
                            $('#email-integration-rules-table tbody').prepend(data.row);
                            seller_admin.base.addCustomFlash('#email-integration-rules-section', 'success', 'Successfully added an email integration rule to your ' + data.provider + ' provider.');
                            seller_admin.base.disableLoadingModal("#loadingAddEmailIntegrationRule", $this);
                            seller_admin.base.scrollTop("#email-integration-rules-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingAddEmailIntegrationRule", $this);
                        }
                    });
                return false;
            });
        },

        edit: function () {
            $('body').on('click', '.edit-email-integration-rule', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingEditEmailIntegrationRule", "Updating an email integration rule...");
                seller_admin.base.enableLoadingModal("#loadingEditEmailIntegrationRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#email-integration-rule-modal').html(data.modal);
                            seller_admin.base.openModal('#email-integration-rule-form');
                            $('#email-integration-rule-modal .mdb-select').materialSelect();
                            $('#email-integration-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingEditEmailIntegrationRule", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingEditEmailIntegrationRule", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        update: function () {
            $('body').on('submit', '#edit-email-integration-rule', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingEditEmailIntegrationRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#email-integration-rule-form').modal('hide');
                            $('#email-integration-rules-table tbody tr#email-integration-rule-' + data.integration_rule_collection_id).replaceWith(data.row);
                            seller_admin.base.addCustomFlash('#email-integration-rules-section', 'success', 'Successfully updated an email integration rule to your ' + data.provider + ' provider.');
                            seller_admin.base.disableLoadingModal("#loadingEditEmailIntegrationRule", $this);
                            seller_admin.base.scrollTop("#email-integration-rules-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingEditEmailIntegrationRule", $this);
                        }
                    });
                return false;
            });
        },

        remove: function()
        {
            $('body').on('click', '.remove-email-integration-rule', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    emailIntegrationRuleId = $this.attr('data-email-integration-rule-id');

                seller_admin.base.assignLoadingModal("loadingRemoveEmailIntegrationRule", "Removing email integration rule from your store...");
                seller_admin.base.enableLoadingModal("#loadingRemoveEmailIntegrationRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'DELETE',
                        dataType: 'json',
                        success: function (data) {
                            $('tr#email-integration-rule-' + emailIntegrationRuleId).remove();
                            seller_admin.base.addCustomFlash('#email-integration-rules-section', 'success', 'Successfully removed an email integration rule from your ' + data.provider + ' store integration.');
                            seller_admin.base.disableLoadingModal("#loadingRemoveEmailIntegrationRule", $this);
                            seller_admin.base.scrollTop("#email-integration-rules-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingRemoveEmailIntegrationRule", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        addRule: function (link, association, content) {
            var new_id = new Date().getTime(),
                regexp = new RegExp("new_" + association, "g");
            $(link).before(content.replace(regexp, new_id));
            var selectTags = $('div[data-fields-id="' + new_id + '"] select');
            selectTags.materialSelect()
            selectTags.each(function () {
                $(this).click(e => e.stopPropagation());
            });
        },

        removeRule: function (link) {
            $(link).prev("input[type=hidden]").val("1");
            $(link).closest(".fields").hide();
            var wrapperElem = $(link).closest('.section');
            wrapperElem.hide();
            wrapperElem.find("input[type=hidden].rule-collection-destroy").val("1");
        },

        changeFilterType: function () {
            $('body').on('change', 'select.email-integration-rule-filter-type', function () {
                var $this = $(this),
                    parentWrapper = $this.parent().parent().parent();
                seller_admin.email_integration_rule.updateConditionSelect(parentWrapper, this.value);
                if (this.value === 'cart_item') {
                    parentWrapper.find('.email-integration-rule-filter-value-wrapper').show();
                    parentWrapper.find('.email-integration-rule-filter-value-wrapper div label').text('Quantity');
                    parentWrapper.find('.email-integration-rule-product-id-wrapper').show();
                } else if (this.value === 'cart_item_count') {
                    parentWrapper.find('.email-integration-rule-filter-value-wrapper').show();
                    parentWrapper.find('.email-integration-rule-filter-value-wrapper div label').text('Quantity');
                    parentWrapper.find('.email-integration-rule-product-id-wrapper').hide();
                } else if (this.value === 'cart_subtotal') {
                    parentWrapper.find('.email-integration-rule-filter-value-wrapper').show();
                    parentWrapper.find('.email-integration-rule-filter-value-wrapper div label').text('Cart value');
                    parentWrapper.find('.email-integration-rule-product-id-wrapper').hide();
                } else if (this.value === 'product_tag') {
                    parentWrapper.find('.email-integration-rule-filter-value-wrapper').show();
                    parentWrapper.find('.email-integration-rule-filter-value-wrapper div label').text('Filter');
                    parentWrapper.find('.email-integration-rule-product-id-wrapper').hide();
                } else if (this.value === 'product_title') {
                    parentWrapper.find('.email-integration-rule-filter-value-wrapper').show();
                    parentWrapper.find('.email-integration-rule-filter-value-wrapper div label').text('Filter');
                    parentWrapper.find('.email-integration-rule-product-id-wrapper').hide();
                } else if (this.value === 'product_collection') {
                    parentWrapper.find('.email-integration-rule-filter-value-wrapper').show();
                    parentWrapper.find('.email-integration-rule-filter-value-wrapper div label').text('Filter');
                    parentWrapper.find('.email-integration-rule-product-id-wrapper').hide();
                }

            });
        },

        updateConditionSelect: function (parentWrapper, selectValue) {
            var conditions = window.email_integration_rule_conditions[selectValue],
                selectElem = parentWrapper.find('select.email-integration-rule-condition');
            selectElem.empty();
            for (var i = 0; i < conditions.length; i++) {
                selectElem.append('<option value="' + conditions[i][0] + '">' + conditions[i][1] + '</option>');
            }
        }
    }



seller_admin.vendor =
    {
        sendAccountSetupEmail: function()
        {
            $('body').on('click', '.send-account-setup-email-team-member', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingAccountSetupEmailTeamAccount", "Sending account setup email to team member...");
                seller_admin.base.enableLoadingModal("#loadingAccountSetupEmailTeamAccount", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        dataType: 'json',
                        success: function (data) {
                            seller_admin.base.addFlash('success', 'Successfully sent an account setup email to ' + data.email + '.');
                            seller_admin.base.disableLoadingModal("#loadingAccountSetupEmailTeamAccount", $this);
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingAccountSetupEmailTeamAccount", $this);
                            seller_admin.base.addFlash('success', 'Vendor has already set up their account');
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        block: function()
        {
            $('body').on('click', '.block-team-member', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    teamMemberId = $this.attr('data-team-member-id');

                seller_admin.base.assignLoadingModal("loadingBlockTeamAccount", "Blocking team member from your store...");
                seller_admin.base.enableLoadingModal("#loadingBlockTeamAccount", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('tr#team-member-' + teamMemberId).replaceWith(data.row);
                            seller_admin.base.addFlash('success', 'Successfully blocked a team member from your store.');
                            seller_admin.base.disableLoadingModal("#loadingBlockTeamAccount", $this);
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingBlockTeamAccount", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },
        
        unblock: function()
        {
            $('body').on('click', '.unblock-team-member', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    teamMemberId = $this.attr('data-team-member-id');

                seller_admin.base.assignLoadingModal("loadingUnblockTeamAccount", "Unblocking team member from your store...");
                seller_admin.base.enableLoadingModal("#loadingUnblockTeamAccount", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('tr#team-member-' + teamMemberId).replaceWith(data.row);
                            seller_admin.base.addFlash('success', 'Successfully unblocked a team member from your store.');
                            seller_admin.base.disableLoadingModal("#loadingUnblockTeamAccount", $this);
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUnblockTeamAccount", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        remove: function()
        {
            $('body').on('click', '.prompt-remove-team-member', function() {
                var teamMemberId = $(this).attr('data-record-id');

                seller_admin.base.assignConfirmationModal('remove-team-member',
                    'Are you sure you wish to remove a team member?', teamMemberId);
                seller_admin.base.enableConfirmationModal();
                return false;
            });
            $('body').on('click', '.remove-team-member', function () {
                var $this = $(this),
                    teamMemberId = $this.attr('data-record-id');

                seller_admin.base.disableConfirmationModal();
                seller_admin.base.assignLoadingModal("loadingRemoveTeamAccount", "Removing team member from your store...");
                seller_admin.base.enableLoadingModal("#loadingRemoveTeamAccount", $this);

                $.ajax(
                    {
                        url: '/admin/vendors/' + teamMemberId,
                        type: 'DELETE',
                        dataType: 'json',
                        success: function (data) {
                            $('tr#team-member-' + teamMemberId).remove();
                            seller_admin.base.addFlash('success', 'Successfully removed a team member from your store.');
                            seller_admin.base.disableLoadingModal("#loadingRemoveTeamAccount", $this);
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingRemoveTeamAccount", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }
$(document).ready(function () {
    var $formElem = $('#process-vendor-card-stripe');
    if ($formElem.length > 0) {
        var stripe = Stripe($('meta[name="stripe-key"]').attr('content'));
        var elements = stripe.elements();
        var style = {
            base: {
                color: '#32325d',
                lineHeight: '24px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };
        var card = elements.create('card', {style: style, hidePostalCode: true});
        card.mount('#card-element');
        card.addEventListener('change', function (event) {
            if (event.error) {
                $('#card-errors').text(event.error.message)
            } else {
                $('#card-errors').text('')
            }
        });


        $('body').on('submit', '#process-vendor-card-stripe', function () {
            $('input[type=submit]').attr('disabled', true);
            var $form = $(this);
            var billing_details = {
                name: document.getElementById('first_name').value + ' ' + document.getElementById('last_name').value,
                address: {
                    line1: document.getElementById('address').value,
                    city: document.getElementById('city').value,
                    state: document.getElementById('county').value,
                    postal_code: document.getElementById('zip').value,
                    country: document.getElementById('country_alpha_2').value
                }
            };
            stripe.createPaymentMethod('card', card, {billing_details}).then(function (result) {
                if (result.error) {
                    $('#card-errors').text(result.error.message);
                    $('input[type=submit]').attr('disabled', false);

                } else {
                    $('#loadingCardUpdate').modal('show');
                    $('#payment_method_id').val(result.paymentMethod.id)
                    $form[0].submit();
                }
            });
            return false;
        });
    }
});

seller_admin.shipping =
    {

        add: function () {
            $('body').on('click', '.create-shipping-rule', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    shippingWeightUnit = $('#shipping-settings').attr('data-current-shipping-weight-unit');

                seller_admin.base.assignLoadingModal("loadingAddShippingRule", "Creating shipping rule...");
                seller_admin.base.enableLoadingModal("#loadingAddShippingRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#shipping-rule-modal').html(data.modal);
                            $('#shipping-rule-form .shipping-weight-unit').text(shippingWeightUnit);
                            seller_admin.base.openModal('#shipping-rule-form');
                            $('#shipping-rule-modal .mdb-select').materialSelect();
                            $('#shipping-rule-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingAddShippingRule", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingAddShippingRule", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        create: function () {
            $('body').on('submit', '#create-shipping-rule', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingAddShippingRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#shipping-rule-form').modal('hide');
                            $('#shipping-rules-table tbody').prepend(data.shipping_rule_row);
                            seller_admin.base.addCustomFlash('#shipping-rules-section', 'success', 'Successfully added a shipping rule to your store.');
                            seller_admin.base.disableLoadingModal("#loadingAddShippingRule", $this);
                            seller_admin.base.scrollTop('#shipping-rules-section');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingAddShippingRule", $this);
                        }
                    });
                return false;
            });
        },

        edit: function () {
            $('body').on('click', '.edit-shipping-rule', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    shippingWeightUnit = $('#shipping-settings').attr('data-current-shipping-weight-unit');

                seller_admin.base.assignLoadingModal("loadingEditShippingRule", "Updating shipping rule...");
                seller_admin.base.enableLoadingModal("#loadingEditShippingRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#shipping-rule-modal').html(data.modal);
                            $('#shipping-rule-form .shipping-weight-unit').text(shippingWeightUnit);
                            seller_admin.base.openModal('#shipping-rule-form');
                            $('#shipping-rule-modal .mdb-select').materialSelect();
                            $('#shipping-rule-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingEditShippingRule", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingEditShippingRule", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        update: function () {
            $('body').on('submit', '#edit-shipping-rule', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingEditShippingRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#shipping-rule-form').modal('hide');
                            $('#shipping-rules-table tbody tr#shipping-rule-' + data.shipping_rule_id).replaceWith(data.shipping_rule_row);
                            seller_admin.base.addCustomFlash('#shipping-rules-section', 'success', 'Successfully updated a shipping rule to your store.');
                            seller_admin.base.disableLoadingModal("#loadingEditShippingRule", $this);
                            seller_admin.base.scrollTop('#shipping-rules-section');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingEditShippingRule", $this);
                        }
                    });
                return false;
            });
        },

        remove: function () {
            $('body').on('click', '.prompt-remove-shipping-rule', function() {
                var shippingRuleId = $(this).attr('data-record-id');

                seller_admin.base.assignConfirmationModal('remove-shipping-rule',
                    'Are you sure you wish to remove your shipping rule?', shippingRuleId);
                seller_admin.base.enableConfirmationModal();
                return false;
            });
            $('body').on('click', '.remove-shipping-rule', function () {
                var $this = $(this),
                    shippingRuleId = $this.attr('data-record-id');

                seller_admin.base.disableConfirmationModal();
                seller_admin.base.assignLoadingModal("loadingRemoveShippingRule", "Removing shipping rule from your store...");
                seller_admin.base.enableLoadingModal("#loadingRemoveShippingRule", $this);

                $.ajax(
                    {
                        url: '/admin/settings/shipping_rules/' + shippingRuleId,
                        type: 'DELETE',
                        dataType: 'json',
                        success: function (data) {
                            $('tr#shipping-rule-' + shippingRuleId).remove();
                            seller_admin.base.addCustomFlash('#shipping-rules-section', 'success', 'Successfully removed a shipping rule from your store.');
                            seller_admin.base.disableLoadingModal("#loadingRemoveShippingRule", $this);
                            seller_admin.base.scrollTop('#shipping-rules-section');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingRemoveShippingRule", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        onChangeWeightUnit: function () {
            $('select#store_shipping_weight_unit').on('change', function () {
                $('#shipping-settings').attr('data-current-shipping-weight-unit', seller_admin.shipping.weightUnitLabel(this.value));
                $('.shipping-weight-unit').text(seller_admin.shipping.weightUnitLabel(this.value));
                seller_admin.base.openModal('#shipping-warning-modal');
            });
        },

        weightUnitLabel: function (value) {
            if (value === 'pounds') {
                return 'lb';
            } else if (value === 'ounces') {
                return 'oz';
            } else if (value === 'kilograms') {
                return 'kg';
            } else if (value === 'grams') {
                return 'g';
            }
        },
    }
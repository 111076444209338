seller_admin.collection =
    {
        updateNameToSlug: function () {
            var nameInput = document.getElementById('collection_name'),
                slugInput = document.getElementById('collection_slug'),
                slugLabel = $('label[for="collection_slug"]');

            // Init a timeout variable to be used below
            var timeout = null;

            // Listen for keystroke events
            if (typeof (nameInput) != 'undefined' && nameInput != null) {
                nameInput.onkeyup = function (e) {

                    // Clear the timeout if it has already been set.
                    // This will prevent the previous task from executing
                    // if it has been less than <MILLISECONDS>
                    clearTimeout(timeout);

                    // Make a new timeout set to go off in 800ms
                    timeout = setTimeout(function () {
                        if (slugInput.value === "") {
                            slugInput.value = seller_admin.base.string_parameterize(nameInput.value);
                            slugLabel.addClass('active');
                        }
                    }, 2000);
                };
            }
        },

        formatSlug: function () {
            var slugInput = document.getElementById('collection_slug');

            // Init a timeout variable to be used below
            var timeout = null;

            // Listen for keystroke events
            if (typeof (slugInput) != 'undefined' && slugInput != null) {
                slugInput.onkeyup = function (e) {

                    // Clear the timeout if it has already been set.
                    // This will prevent the previous task from executing
                    // if it has been less than <MILLISECONDS>
                    clearTimeout(timeout);

                    // Make a new timeout set to go off in 800ms
                    timeout = setTimeout(function () {
                        slugInput.value = seller_admin.base.string_parameterize(slugInput.value);
                    }, 500);
                };
            }
        },

        initProductsSortable: function () {
            $('#sortable-collection-products-list').sortable({
                placeholder: "ui-state-highlight",
                forcePlaceholderSize: true,
                tolerance: "pointer",
                update: function (e, ui) {
                    seller_admin.base.enableLoadingModal("#loadingSortCollectionProducts", $('#sort-collection-products-form'));
                    $.ajax(
                        {
                            url: $(this).data("url"),
                            type: "PATCH",
                            data: $(this).sortable('serialize'),
                            success: function (data) {
                                seller_admin.base.disableLoadingModal("#loadingSortCollectionProducts", $('#sort-collection-products-form'));
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.disableLoadingModal("#loadingSortCollectionProducts", $('#sort-collection-products-form'));
                                seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                                seller_admin.base.scrollTop("main");
                            }
                        });
                }
            });
            $("#sortable-collection-products-list").disableSelection();
        },

        sortProductsModal: function () {
            $('body').on('click', '.collection-reorder-products', function () {
                var collectionId = $(this).attr('data-collection-id');

                seller_admin.base.assignLoadingModal("loadingSortCollectionProducts", "Sorting collection products...");
                seller_admin.base.enableLoadingModal("#loadingSortCollectionProducts", $('#sort-collection-products-form'));


                $.ajax(
                    {
                        url: '/admin/collections/' + collectionId + '/sort_modal',
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#sort-collection-products-wrapper').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingSortCollectionProducts", $('#sort-collection-products-form'));
                            seller_admin.base.openModal('#sort-collection-products-form');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingSortCollectionProducts", $('#sort-collection-products-form'));
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        initNewTags: function () {
            $('#new-collection-tags').materialChip({
                placeholder: 'Enter a tag',
                secondaryPlaceholder: '+Tag',
            });
            seller_admin.collection.tagFieldCallbacks();
        },

        initEditTags: function () {
            seller_admin.base.setupApiAccess();
            var collectionId = $('#edit-collection-header').attr('data-collection-id'),
                accessToken = $('meta[name=api-access-token]').attr('content'),
                clientId = $('meta[name=api-client]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                uid = $('meta[name=api-uid]').attr('content');
            $.ajax(
                {
                    url: window.baseApiUrl + '/v1/store/collections/' + collectionId + '/tags',
                    headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        var dataTags = [];
                        for (var i in data.tags) {
                            var item = data.tags[i];

                            dataTags.push({
                                "tag": item.name
                            })
                        }
                        $('#new-collection-tags').materialChip({
                            placeholder: 'Enter a tag',
                            secondaryPlaceholder: '+Tag',
                            data: dataTags
                        });
                        seller_admin.collection.tagFieldCallbacks();
                    }
                });

        },

        tagFieldCallbacks: function () {
            $('#new-collection-tags').on('chip.add', function (e, chip) {
                var new_id = new Date().getTime();
                $('#collection-tags-fields').append('<div class="fields">' +
                    '<input value="' + chip.tag + '" type="hidden" name="collection[tags_attributes][' + new_id + '][name]" id="collection_tags_attributes_' + new_id + '_name">' +
                    '<input type="hidden" value="0" name="collection[tags_attributes][' + new_id + '][_destroy]" id="collection_tags_attributes_' + new_id + '__destroy" data-input-value="' + chip.tag + '">');
            });
            $('#new-collection-tags').on('chip.delete', function (e, chip) {
                $('#collection-tags-fields input[data-input-value="' + chip.tag + '"]').val('1');
            });
        },

        archive: function () {
            $('body').on('click', '.archive-collection', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    collectionId = $this.attr('data-collection-id'),
                    collectionSearchTable = $('table#collection-search-table'),
                    collectionRow = $('tr.collection-' + collectionId);

                seller_admin.base.assignLoadingModal("loadingArchiveCollection", "Archiving your collection...");
                seller_admin.base.enableLoadingModal("#loadingArchiveCollection", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (collectionSearchTable.length > 0) {
                                collectionRow.replaceWith(data.row);
                            } else {
                                collectionRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingArchiveCollection", $this);
                            seller_admin.base.addFlash("success", "Successfully archived a collection.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingArchiveCollection", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        unarchive: function () {
            $('body').on('click', '.unarchive-collection', function () {
                var url = $(this).attr('href'),
                    $this = $(this),
                    collectionId = $this.attr('data-collection-id'),
                    collectionSearchTable = $('table#collection-search-table'),
                    collectionRow = $('tr.collection-' + collectionId);

                seller_admin.base.assignLoadingModal("loadingUnarchiveCollection", "Unarchiving your collection...");
                seller_admin.base.enableLoadingModal("#loadingUnarchiveCollection", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (collectionSearchTable.length > 0) {
                                collectionRow.replaceWith(data.row);
                            } else {
                                collectionRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveCollection", $this);
                            seller_admin.base.addFlash("success", "Successfully unarchived a collection.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveCollection", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        remove: function () {
            $('body').on('click', '.prompt-remove-collection', function() {
                var collectionId = $(this).attr('data-record-id');

                seller_admin.base.assignConfirmationModal('remove-collection',
                    'Are you sure you wish to remove your collection?', collectionId);
                seller_admin.base.enableConfirmationModal();
                return false;
            });
            $('body').on('click', '.remove-collection', function () {
                var $this = $(this),
                    collectionId = $this.attr('data-record-id'),
                    collectionRow = $('tr.collection-' + collectionId);

                seller_admin.base.disableConfirmationModal();
                seller_admin.base.assignLoadingModal("loadingRemoveCollection", "Removing your collection...");
                seller_admin.base.enableLoadingModal("#loadingRemoveCollection", $this);

                $.ajax(
                    {
                        url: '/admin/collections/' + collectionId,
                        type: 'DELETE',
                        dataType: 'json',
                        success: function (data) {
                            collectionRow.remove();
                            seller_admin.base.disableLoadingModal("#loadingRemoveCollection", $this);
                            seller_admin.base.addFlash("success", "Successfully removed a collection.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingRemoveCollection", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }
seller_admin.chart =
    {

        stepSize: function(number) {
            if (number < 50) {
                return 1;
            } else if (number > 49 && number < 200) {
                return 10;
            } else if (number > 199 && number < 350) {
                return 20;
            } else if (number > 349 && number < 500) {
                return 30;
            } else if (number > 499 && number < 1000) {
                return 50;
            } else if (number > 999)
            {
                return 100;
            }
        },

        activeSubscriptionsByDateRange: function (dateRange) {
            var $elem = document.getElementById("subscriberPastThirtyDaysLine");
            if ($elem) {
                var ctxL = $elem.getContext('2d');
                $.ajax(
                    {
                        url: '/admin/dashboard/subscriptions/date_range?start_end_range=' + dateRange,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            var myLineChart = new Chart(ctxL, {
                                type: 'line',
                                data: {
                                    labels: data.last_30_days_subscription_label,
                                    datasets: [
                                        {
                                            label: "Active Subscriptions",
                                            data: data.last_30_days_subscription_data,
                                            backgroundColor:  'rgba(54, 162, 235, 0.2)',
                                            borderColor: 'rgba(54, 162, 235, 1)',
                                            borderWidth: 2
                                        }
                                    ]
                                },
                                options: {
                                    responsive: true
                                }
                            });
                        }
                    });
            }
        },

        completedOrdersByDateRange: function (data) {
            $('#ordersByDateBar').remove();
            $('#ordersByDateBar-wrapper').html('<canvas id="ordersByDateBar"></canvas>');

            var $elem = document.getElementById("ordersByDateBar");
            if ($elem) {
                var ctxB = document.getElementById("ordersByDateBar").getContext('2d');

                var completedOrdersBarChart = new Chart(ctxB, {
                    type: 'bar',
                    data: {
                        labels: data.labels,
                        datasets: [
                            {
                                label: "Completed Orders",
                                data: data.data,
                                backgroundColor:  'rgba(54, 162, 235, 0.2)',
                                borderColor: 'rgba(54, 162, 235, 1)',
                                borderWidth: 1
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: seller_admin.chart.stepSize(seller_admin.base.largestInArray(data.data))
                                }
                            }]
                        }
                    }
                });
            }
        },

        popularProductsByDateRange: function (data) {
            $('#popularProductsByDateBar').remove();
            $('#popularProductsByDateBar-wrapper').html('<canvas id="popularProductsByDateBar"></canvas>');

            var $elem = document.getElementById("popularProductsByDateBar");
            if ($elem) {
                var ctxC = document.getElementById("popularProductsByDateBar").getContext('2d');

                var popularProductsBarChart = new Chart(ctxC, {
                    type: 'horizontalBar',
                    data: {
                        labels: data.labels,
                        datasets: [
                            {
                                label: "Popular Products",
                                data: data.data,
                                backgroundColor:  'rgba(54, 162, 235, 0.2)',
                                borderColor: 'rgba(54, 162, 235, 1)',
                                borderWidth: 1
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        scales: {
                            xAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: seller_admin.chart.stepSize(seller_admin.base.largestInArray(data.data))
                                }
                            }]
                        }
                    }
                });
            }
        },

        surveyAnswersByDateRange: function (data) {
            $('#surveysByDateBar').remove();
            $('#surveysByDateBar-wrapper').html('<canvas id="surveysByDateBar"></canvas>');

            var $elem = document.getElementById("surveysByDateBar");
            if ($elem) {
                var ctxB = document.getElementById("surveysByDateBar").getContext('2d');

                var completedOrdersBarChart = new Chart(ctxB, {
                    type: 'bar',
                    data: {
                        labels: data.labels,
                        datasets: [
                            {
                                label: "Survey Answers",
                                data: data.data,
                                backgroundColor:  'rgba(54, 162, 235, 0.2)',
                                borderColor: 'rgba(54, 162, 235, 1)',
                                borderWidth: 1
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: seller_admin.chart.stepSize(seller_admin.base.largestInArray(data.data))
                                }
                            }]
                        }
                    }
                });
            }
        },
    }
seller_admin.order_bump =
    {
        create: function () {
            $("body").on("submit", '#new_product_order_bump', function () {
                var $this = $(this),
                    url = $this.attr('action');


                seller_admin.base.assignLoadingModal("loadingCreateOrderBump", "Creating order bump...");
                seller_admin.base.enableLoadingModal("#loadingCreateOrderBump", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $this[0].submit();
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingCreateOrderBump", $this);
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.scrollTop("#add-order-bump-header");
                        }
                    });
                return false;
            });
        },

        edit: function () {
            $("body").on("submit", '.edit_product_order_bump', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.assignLoadingModal("loadingUpdateOrderBump", "Updating order bump...");
                seller_admin.base.enableLoadingModal("#loadingUpdateOrderBump", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $this[0].submit();
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUpdateOrderBump", $this);
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.scrollTop("#edit-order-bump-header");
                        }
                    });
                return false;
            });
        },

        changeFilterType: function () {
            $('body').on('change', 'select.order-bump-rule-filter-type', function () {
                var $this = $(this),
                    parentWrapper = $this.parent().parent().parent();
                seller_admin.order_bump.updateConditionSelect(parentWrapper, this.value);
                if (this.value === 'cart_item') {
                    parentWrapper.find('.order-bump-rule-filter-value-wrapper').show();
                    parentWrapper.find('.order-bump-rule-filter-value-wrapper div label').text('Quantity');
                    parentWrapper.find('.order-bump-rule-product-id-wrapper').show();
                } else if (this.value === 'cart_item_count') {
                    parentWrapper.find('.order-bump-rule-filter-value-wrapper').show();
                    parentWrapper.find('.order-bump-rule-filter-value-wrapper div label').text('Quantity');
                    parentWrapper.find('.order-bump-rule-product-id-wrapper').hide();
                } else if (this.value === 'cart_subtotal') {
                    parentWrapper.find('.order-bump-rule-filter-value-wrapper').show();
                    parentWrapper.find('.order-bump-rule-filter-value-wrapper div label').text('Cart value');
                    parentWrapper.find('.order-bump-rule-product-id-wrapper').hide();
                } else if (this.value === 'product_tag') {
                    parentWrapper.find('.order-bump-rule-filter-value-wrapper').show();
                    parentWrapper.find('.order-bump-rule-filter-value-wrapper div label').text('Filter');
                    parentWrapper.find('.order-bump-rule-product-id-wrapper').hide();
                } else if (this.value === 'product_title') {
                    parentWrapper.find('.order-bump-rule-filter-value-wrapper').show();
                    parentWrapper.find('.order-bump-rule-filter-value-wrapper div label').text('Filter');
                    parentWrapper.find('.order-bump-rule-product-id-wrapper').hide();
                } else if (this.value === 'product_collection') {
                    parentWrapper.find('.order-bump-rule-filter-value-wrapper').show();
                    parentWrapper.find('.order-bump-rule-filter-value-wrapper div label').text('Filter');
                    parentWrapper.find('.order-bump-rule-product-id-wrapper').hide();
                }

            });
        },

        firstRuleCollection: function (link, association, content) {
            var new_id = new Date().getTime(),
                regexp = new RegExp("new_" + association, "g"),
                wrapperElem = $(link).closest('.section');
            $('#order-bump-rules-header').after(content.replace(regexp, new_id));
            var selectTags = $('div[data-fields-id="' + new_id + '"] select');
            selectTags.materialSelect()
            selectTags.each(function () {
                $(this).click(e => e.stopPropagation());
            });
            if ($('section.order-bump-rule-collection:visible').length > 1) {
                wrapperElem.find('.order-bump-or-header').show();
            }
            $('#add-order-bump-rule-collection').show();
            $(link).hide();
        },

        addRuleCollection: function (link, association, content) {
            var new_id = new Date().getTime(),
                regexp = new RegExp("new_" + association, "g"),
                wrapperElem = $(link).closest('.section');
            $(link).before(content.replace(regexp, new_id));
            var selectTags = $('div[data-fields-id="' + new_id + '"] select');
            selectTags.materialSelect()
            selectTags.each(function () {
                $(this).click(e => e.stopPropagation());
            });
            if ($('section.order-bump-rule-collection:visible').length > 1) {
                wrapperElem.find('.order-bump-or-header').show();
            }
        },

        addRule: function (link, association, content) {
            var new_id = new Date().getTime(),
                regexp = new RegExp("new_" + association, "g");
            $(link).before(content.replace(regexp, new_id));
            var selectTags = $('div[data-fields-id="' + new_id + '"] select');
            selectTags.materialSelect()
            selectTags.each(function () {
                $(this).click(e => e.stopPropagation());
            });
        },

        removeRule: function (link) {
            $(link).prev("input[type=hidden]").val("1");
            $(link).closest(".fields").hide();
            var wrapperElem = $(link).closest('.section');
            if (wrapperElem.find('.fields:visible').length < 1) {
                wrapperElem.hide();
                wrapperElem.find("input[type=hidden].rule-collection-destroy").val("1");
            }
            if ($('section.order-bump-rule-collection:visible').length < 1) {
                $('#first-order-bump-rule-wrapper').show();
                $('#first-order-bump-rule-wrapper a').show();
                $('#add-order-bump-rule-collection').hide();
            }
        },

        updateConditionSelect: function (parentWrapper, selectValue) {
            var conditions = window.bump_rule_conditions[selectValue],
                selectElem = parentWrapper.find('select.order-bump-rule-condition');
            selectElem.empty();
            for (var i = 0; i < conditions.length; i++) {
                selectElem.append('<option value="' + conditions[i][0] + '">' + conditions[i][1] + '</option>');
            }
        },

        archive: function () {
            $('body').on('click', '.archive-order-bump', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    orderBumpId = $this.attr('data-order-bump-id'),
                    orderBumpSearchTable = $('table#order-bump-search-table'),
                    orderBumpRow = $('tr.order-bump-' + orderBumpId);

                seller_admin.base.assignLoadingModal("loadingArchiveOrderBump", "Archiving order bump...");
                seller_admin.base.enableLoadingModal("#loadingArchiveOrderBump", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (orderBumpSearchTable.length > 0) {
                                orderBumpRow.replaceWith(data.row);
                            } else {
                                orderBumpRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingArchiveOrderBump", $this);
                            seller_admin.base.addFlash("success", "Successfully archived a Product Order Bump.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingArchiveOrderBump", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        unarchive: function () {
            $('body').on('click', '.unarchive-order-bump', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    orderBumpId = $this.attr('data-order-bump-id'),
                    orderBumpSearchTable = $('table#order-bump-search-table'),
                    orderBumpRow = $('tr.order-bump-' + orderBumpId);

                seller_admin.base.assignLoadingModal("loadingUnarchiveOrderBump", "Unarchiving order bump...");
                seller_admin.base.enableLoadingModal("#loadingUnarchiveOrderBump", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (orderBumpSearchTable.length > 0) {
                                orderBumpRow.replaceWith(data.row);
                            } else {
                                orderBumpRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveOrderBump", $this);
                            seller_admin.base.addFlash("success", "Successfully unarchived a Product Order Bump.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveOrderBump", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        changeOverridePrice: function () {
            $('#product_order_bump_override_price').change(function () {
                if ($(this).val() === 'enabled') {
                    $('#order-bump-quantity-wrapper').show();
                    $('#order-bump-price-wrapper').show();
                } else {
                    $('#order-bump-quantity-wrapper').hide();
                    $('#order-bump-price-wrapper').hide();
                }
            });
        },
    }
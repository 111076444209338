seller_admin.member_post =
    {
        archive: function () {
            $('body').on('click', '.archive-member-post', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    memberPostId = $this.attr('data-member-post-id'),
                    memberPostSearchTable = $('table#member-post-search-table'),
                    memberPostRow = $('tr.member-post-' + memberPostId);

                seller_admin.base.assignLoadingModal("loadingArchiveMemberPost", "Archiving your member post...");
                seller_admin.base.enableLoadingModal("#loadingArchiveMemberPost", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (memberPostSearchTable.length > 0) {
                                memberPostRow.replaceWith(data.row);
                            } else {
                                memberPostRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingArchiveMemberPost", $this);
                            seller_admin.base.addFlash("success", "Successfully archived a member post.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingArchiveMemberPost", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        unarchive: function () {
            $('body').on('click', '.unarchive-member-post', function () {
                var url = $(this).attr('href'),
                    $this = $(this),
                    memberPostId = $this.attr('data-member-post-id'),
                    memberPostSearchTable = $('table#member-post-search-table'),
                    memberPostRow = $('tr.member-post-' + memberPostId);

                seller_admin.base.assignLoadingModal("loadingUnarchiveMemberPost", "Unarchiving your member post...");
                seller_admin.base.enableLoadingModal("#loadingUnarchiveMemberPost", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (memberPostSearchTable.length > 0) {
                                memberPostRow.replaceWith(data.row);
                            } else {
                                memberPostRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveMemberPost", $this);
                            seller_admin.base.addFlash("success", "Successfully unarchived a member post.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveMemberPost", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        bindPublishNotification: function (channel) {
            channel.bind('member_post_publish_list', function (data) {
                var draftMemberPost = $('tr.member-post-' + data.draft_member_post_id);
                if (draftMemberPost.length > 0) {
                    draftMemberPost.find('td.member-post-publish-status').text(data.publish_status);
                }
            });
        },

        newDuplicate: function () {
            $('body').on('click', '.duplicate-member-post', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingDuplicateMemberPost", "Duplicating your member post...");
                seller_admin.base.enableLoadingModal("#loadingDuplicateMemberPost", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#duplicate-member-post-wrapper').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingDuplicateMemberPost", $this);
                            seller_admin.base.openModal('#duplicate-member-post-modal');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingDuplicateMemberPost", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        createDuplicate: function () {
            $('body').on('submit', '#duplicate-member-post-form', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingDuplicateMemberPost", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#duplicate-member-post-modal').modal('hide');
                            $('#member-category-' + data.member_category_id + '-post-table tbody').prepend(data.row);
                            seller_admin.base.disableLoadingModal("#loadingDuplicateMemberPost", $this);
                            seller_admin.base.addFlash("success", "Successfully duplicated the member post, updates will appear in a few moments.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingDuplicateMemberPost", $this);
                        }
                    });
                return false;
            });
        },

        initSortableList: function () {
            $('#sortable-member-category-post-list').sortable({
                placeholder: "ui-state-highlight",
                forcePlaceholderSize: true,
                tolerance: "pointer",
                update: function (e, ui) {
                    var memberCategoryId = $(this).data('member-category-id');
                    seller_admin.base.enableLoadingModal("#loadingSortMemberPosts", $('#sort-member-category-posts-form'));
                    $.ajax(
                        {
                            url: $(this).data("url"),
                            type: "PATCH",
                            data: $(this).sortable('serialize'),
                            success: function (data) {
                                $('#member-category-' + memberCategoryId + '-post-table tbody').html(data.table);
                                seller_admin.base.disableLoadingModal("#loadingSortMemberPosts", $('#sort-member-category-posts-form'));
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.disableLoadingModal("#loadingSortMemberPosts", $('#sort-member-category-posts-form'));
                                seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                                seller_admin.base.scrollTop("main");
                            }
                        });
                }
            });
            $("#sortable-member-category-post-list").disableSelection();
        },

        sortModal: function () {
            $('body').on('click', '.reorder-member-category-posts', function () {
                var url = $(this).attr('href');

                seller_admin.base.assignLoadingModal("loadingSortMemberPosts", "Sorting your member posts...");
                seller_admin.base.enableLoadingModal("#loadingSortMemberPosts", $('#sort-member-category-posts-form'));
                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#sort-member-category-posts-wrapper').html(data.modal);
                            seller_admin.base.openModal('#sort-member-category-posts-form');
                            seller_admin.base.disableLoadingModal("#loadingSortMemberPosts", $('#sort-member-category-posts-form'));
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingSortMemberPosts", $('#sort-member-category-posts-form'));
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        saveContent: function () {
            var $this = $(this),
                accessToken = $('meta[name=api-access-token]').attr('content'),
                clientId = $('meta[name=api-client]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                uid = $('meta[name=api-uid]').attr('content'),
                memberPostId = $('.is-wrapper').attr('data-member-post-id'),
                wrapperContent = $('.is-wrapper'),
                html = window.contentBuilder.html();

            seller_admin.base.assignLoadingModal("loadingSaveMemberPostDesign", "Saving your member post design...");
            seller_admin.base.enableLoadingModal("#loadingSaveMemberPostDesign", $this);

            $.ajax(
                {
                    url: window.baseApiUrl + '/v1/store/member/posts/' + memberPostId,
                    headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                    type: 'PATCH',
                    data: {
                        'member_post': {'content': html},
                        'version_number': wrapperContent.attr('data-version')
                    },
                    dataType: 'json',
                    success: function (data) {
                        seller_admin.base.disableLoadingModal("#loadingSaveMemberPostDesign", $this);
                        wrapperContent.attr('data-version', data.version_number);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE SUCCESSFUL");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingSaveMemberPostDesign", $this);
                        seller_admin.base.addFlash("danger", "Failed to save member post, please try again.");
                        seller_admin.base.scrollTop("main");
                        seller_admin.page_designer.versionMismatchModal(xhr.responseJSON.version_mismatch);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE FAILED");
                    }
                });
        },

        autoSaveContent: function () {
            var accessToken = $('meta[name=api-access-token]').attr('content'),
                clientId = $('meta[name=api-client]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                uid = $('meta[name=api-uid]').attr('content'),
                memberPostId = $('.is-wrapper').attr('data-member-post-id'),
                wrapperContent = $('.is-wrapper'),
                html = window.contentBuilder.html();

            $.ajax(
                {
                    url: window.baseApiUrl + '/v1/store/member/posts/' + memberPostId,
                    headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                    type: 'PATCH',
                    data: {
                        'member_post': {'content': html},
                        'version_number': wrapperContent.attr('data-version')
                    },
                    dataType: 'json',
                    success: function (data) {
                        seller_admin.base.successfulDesignerAutoSave();
                        wrapperContent.attr('data-version', data.version_number);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE SUCCESSFUL");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.failedDesignerAutoSave();
                        seller_admin.page_designer.versionMismatchModal(xhr.responseJSON.version_mismatch);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE FAILED");
                    }
                });
        },

        publish: function () {
            $('body').on('click', '.publish-member-post', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingPublishMemberPost", "Publishing your member post...");
                seller_admin.base.enableLoadingModal("#loadingPublishMemberPost", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        dataType: 'json',
                        success: function (data) {
                            seller_admin.base.disableLoadingModal("#loadingPublishMemberPost", $this);
                            seller_admin.base.addFlash("success", "Updates will appear live in a few moments.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingPublishMemberPost", $this);
                            seller_admin.base.addFlash("danger", "Failed to publish member post, please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        updateTitleToSlug: function () {
            var nameInput = document.getElementById('member_post_title'),
                slugInput = document.getElementById('member_post_slug'),
                slugLabel = $('label[for="member_post_slug"]'),
                timeout = null;

            if (typeof (nameInput) != 'undefined' && nameInput != null) {
                nameInput.onkeyup = function (e) {

                    clearTimeout(timeout);
                    timeout = setTimeout(function () {
                        if (slugInput.value === "") {
                            slugInput.value = seller_admin.base.string_parameterize(nameInput.value);
                            slugLabel.addClass('active');
                        }
                    }, 2000);
                };
            }
        },

        formatSlug: function () {
            var slugInput = document.getElementById('member_post_slug'),
                timeout = null;

            if (typeof (slugInput) != 'undefined' && slugInput != null) {
                slugInput.onkeyup = function (e) {

                    clearTimeout(timeout);
                    timeout = setTimeout(function () {
                        slugInput.value = seller_admin.base.string_parameterize(slugInput.value);
                    }, 500);
                };
            }
        },

        addAccessRule: function (link, association, content) {
            var new_id = new Date().getTime(),
                regexp = new RegExp("new_" + association, "g");
            $('#access-rules-header').after(content.replace(regexp, new_id));
            var selectTags = $('div[data-fields-id="' + new_id + '"] select');
            selectTags.materialSelect()
            selectTags.each(function () {
                $(this).click(e => e.stopPropagation());
            });
        },

        removeAccessRule: function (link) {
            $(link).prev("input[type=hidden]").val("1");
            $(link).closest(".fields").hide();
            var wrapperElem = $(link).closest('.section');
            wrapperElem.hide();
        }
    }
$(document).ready(function () {
    var $formElem = $('#process-onboarding-vendor-sub');
    if ($formElem.length > 0) {
        var stripe = Stripe($('meta[name="stripe-key"]').attr('content'));
        var elements = stripe.elements();
        var style = {
            base: {
                color: '#32325d',
                lineHeight: '24px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };
        var card = elements.create('card', {style: style, hidePostalCode: true});
        card.mount('#card-element');
        card.addEventListener('change', function (event) {
            if (event.error) {
                $('#card-errors').text(event.error.message)
            } else {
                $('#card-errors').text('')
            }
        });


        $('body').on('submit', '#process-onboarding-vendor-sub', function () {
            var countryId = $('#country').val();
            $.ajax(
                {
                    url: '/countries/' + countryId,
                    type: 'GET',
                    dataType: 'json',
                    success: function (data) {
                        $('#onboarding-validation-warning').remove();
                        $('input[type=submit]').attr('disabled', true);
                        var billing_details = {
                            name: document.getElementById('first_name').value + ' ' + document.getElementById('last_name').value,
                            address: {
                                line1: document.getElementById('address').value,
                                city: document.getElementById('city').value,
                                state: document.getElementById('county').value,
                                postal_code: document.getElementById('zip').value,
                                country: data.alpha_2
                            }
                        };
                        stripe.createPaymentMethod('card', card, {billing_details}).then(function (result) {
                            if (result.error) {
                                $('#card-errors').text(result.error.message);
                                $('input[type=submit]').attr('disabled', false);
                            } else {
                                $('#loadingStoreCheckout').modal('show');
                                submitOnboardingForm(result);
                            }
                        });
                    }
                });
            return false;
        });
    }
});

function submitOnboardingForm(result) {
    var $form = $('#process-onboarding-vendor-sub');
    $.ajax(
        {
            url: '/admin/onboarding/confirm',
            type: 'POST',
            data: $form.serialize(),
            dataType: 'json',
            success: function (data) {
                $.ajax(
                    {
                        url: '/admin/onboarding/confirm_subscription',
                        type: 'POST',
                        data: {payment_method_id: result.paymentMethod.id},
                        dataType: 'json',
                        success: function (data) {
                            $form[0].submit();
                        },
                        error: function (xhr, evt, status) {
                            $('input[type=submit]').attr('disabled', false);
                            $('#loadingStoreCheckout').modal('hide');
                            $('<div class="mt-4 alert alert-danger" role="alert" id="onboarding-validation-warning">Your subscription was unsuccessful: <b>' + $.parseJSON(xhr.responseText).error + '</b>. Please make sure you have filled out all required fields in the onboarding form.</div>').insertBefore('#onboarding-section');
                            seller_admin.base.scrollTop("main");
                        }
                    });
            },
            error: function (xhr, evt, status) {
                console.log($.parseJSON(xhr.responseText).errors);
                $('input[type=submit]').attr('disabled', false);
                $('#loadingStoreCheckout').modal('hide');
                $('<div class="mt-4 alert alert-danger" role="alert" id="onboarding-validation-warning">Your subscription was unsuccessful: ' + $.parseJSON(xhr.responseText).errors.join(",") + '. Please make sure you have filled out all required fields in the onboarding form.</div>').insertBefore('#onboarding-section');
                seller_admin.base.scrollTop("main");
            }
        });
}

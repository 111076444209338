seller_admin.funnel =
    {
        duplicate: function () {
        },

        changeFunnelType: function () {
            $('#funnel_funnel_type').on('change', function () {
                if (this.value == 'shop')
                {
                    $('#standalone-product-wrapper').hide();
                    $('#standard-product-wrapper').show();
                    $('#standalone-landing-wrapper').hide();
                } else {
                    $('#standalone-product-wrapper').show();
                    $('#standard-product-wrapper').hide();
                    $('#standalone-landing-wrapper').show();
                }
            });
        },

        scheduledPublishModal: function()
        {
            $('body').on('click', '.scheduled-funnel-publish', function () {
                var url = $(this).attr('href');
                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#scheduled-funnel-publish-wrapper').html(data.modal);
                            seller_admin.base.openModal('#scheduledFunnelPublishModal');
                            seller_admin.base.dateTimePicker();

                        }
                    });
                return false;
            });
        },

        scheduledPublish: function()
        {
            $('body').on('submit', '#scheduled-funnel-publish-form', function () {
                var $this = $(this),
                    url = $this.attr('action'),
                    funnelId = $this.attr('data-funnel-id');

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#scheduledFunnelPublishModal').modal('hide');
                            $('tr.funnel-' + funnelId + ' td.scheduled-at-column').text(data.scheduled_publish_at);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                        }
                    });
                return false;
            });
        }
    }
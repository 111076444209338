seller_admin.blog_category =
    {
        archive: function () {
            $('body').on('click', '.archive-blog-category', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    blogCategorySearchTable = $('table#blog-category-search-table'),
                    blogCategoryId = $this.attr('data-blog-category-id'),
                    blogCategoryRow = $('tr.blog-category-' + blogCategoryId);

                seller_admin.base.assignLoadingModal("loadingArchiveBlogCategory", "Archiving your blog category...");
                seller_admin.base.enableLoadingModal("#loadingArchiveBlogCategory", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (blogCategorySearchTable.length > 0) {
                                blogCategoryRow.replaceWith(data.row);
                            } else {
                                blogCategoryRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingArchiveBlogCategory", $this);
                            seller_admin.base.addFlash("success", "Successfully archived a blog category.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingArchiveBlogCategory", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        unarchive: function () {
            $('body').on('click', '.unarchive-blog-category', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    blogCategorySearchTable = $('table#blog-category-search-table'),
                    blogCategoryId = $this.attr('data-blog-category-id'),
                    blogCategoryRow = $('tr.blog-category-' + blogCategoryId);

                seller_admin.base.assignLoadingModal("loadingUnarchiveBlogCategory", "Unarchiving your blog category...");
                seller_admin.base.enableLoadingModal("#loadingUnarchiveBlogCategory", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (blogCategorySearchTable.length > 0) {
                                blogCategoryRow.replaceWith(data.row);
                            } else {
                                blogCategoryRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveBlogCategory", $this);
                            seller_admin.base.addFlash("success", "Successfully unarchived a blog category.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveBlogCategory", $this);
                        }
                    });
                return false;
            });
        },

        updateNameToSlug: function()
        {
            var nameInput = document.getElementById('seller_blog_category_name'),
                slugInput = document.getElementById('seller_blog_category_slug'),
                slugLabel = $('label[for="seller_blog_category_slug"]');

            var timeout = null;

            if (typeof (nameInput) != 'undefined' && nameInput != null) {
                nameInput.onkeyup = function (e) {

                    clearTimeout(timeout);

                    timeout = setTimeout(function () {
                        if (slugInput.value === "") {
                            slugInput.value = seller_admin.base.string_parameterize(nameInput.value);
                            slugLabel.addClass('active');
                        }
                    }, 2000);
                };
            }
        },

        formatSlug: function()
        {
            var slugInput = document.getElementById('seller_blog_category_slug');

            var timeout = null;

            if (typeof (slugInput) != 'undefined' && slugInput != null) {
                slugInput.onkeyup = function (e) {

                    clearTimeout(timeout);

                    timeout = setTimeout(function () {
                        slugInput.value = seller_admin.base.string_parameterize(slugInput.value);
                    }, 500);
                };
            }
        }
    }
window.jQuery = $;
window.$ = $;

require("@rails/ujs").start();
require("jquery-ui-dist/jquery-ui");
require("jquery-ui-touch-punch");

require("moment-timezone");
require("underscore");
require("tag-it");
require("daterangepicker");
require("jquery-datetimepicker/build/jquery.datetimepicker.full");
require("pusher-js/dist/web/pusher");
require("isotope-layout/dist/isotope.pkgd");
require("imagesloaded/imagesloaded.pkgd");
require("blueimp-file-upload/js/jquery.fileupload");
require("cloudinary-jquery-file-upload/cloudinary-jquery-file-upload");

require("mdbootstrap/popper.min");
require("mdbootstrap/bootstrap");
require("mdbootstrap/mdb");
require("mdbootstrap/modules/chart");
require("mdbootstrap/addons/mdb-file-upload");
require("mdbootstrap/fontawesome-iconpicker");

require('seller/admin/seller.vendor-stripe-card');
require('seller/admin/seller.reactivate-subscription');
require('seller/admin/seller.onboarding-subscription');

// Move to separate file, cleaner.
require('seller/invoicing');

require("seller/admin/_seller");
require("seller/admin/seller.base");
require("seller/admin/seller.chart");
require("seller/admin/seller.inventory");
require("seller/admin/seller.collection");
require("seller/admin/seller.product");
require("seller/admin/seller.variant");
require("seller/admin/seller.order");
require("seller/admin/seller.subscription");
require("seller/admin/seller.transaction");
require("seller/admin/seller.tax_rule");
require("seller/admin/seller.email_integration");
require("seller/admin/seller.email_integration_rule");
require("seller/admin/seller.plan");
require("seller/admin/seller.gift");
require("seller/admin/seller.stock_movement");
require("seller/admin/seller.funnel");
require("seller/admin/seller.onboarding");
require("seller/admin/seller.notification");
require("seller/admin/seller.page");
require("seller/admin/seller.billing");
require("seller/admin/seller.nav_item");
require("seller/admin/seller.template");
require("seller/admin/seller.discount");
require("seller/admin/seller.setting");
require("seller/admin/seller.cart_abandonment");
require("seller/admin/seller.member_dashboard");
require("seller/admin/seller.member_category");
require("seller/admin/seller.member_post");
require("seller/admin/seller.shipping");
require("seller/admin/seller.order_bump");
require("seller/admin/seller.blog_category");
require("seller/admin/seller.blog_post");
require("seller/admin/seller.page_designer");
require("seller/admin/seller.dashboard");
require("seller/admin/seller.customer");
require("seller/admin/seller.checkout_trust");
require("seller/admin/seller.checkout_additional_fee");
require("seller/admin/seller.vendor");
require("seller/admin/seller.app_integration");
require("seller/admin/seller.order_item");
require("seller/admin/seller.pick_up_location");
require("seller/admin/seller.pick_up_time");
require("seller/admin/seller.pause_option");
require("seller/admin/seller.survey");
require("seller/admin/seller.churn");
require("seller/admin/seller.review");
require("seller/admin/seller.lite");
require("seller/admin/seller.free_gift");
require("seller/admin/seller.dunning");
require("seller/admin/email_templates/seller.incentive_email");
require("seller/admin/email_templates/seller.review_email");
require("seller/admin/email_templates/seller.dunning_email");
require("seller/admin/seller.api");
require("seller/admin/seller.ready");
seller_admin.tax_rule =
    {
        add: function () {
            $('body').on('click', '.create-tax-rule', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingAddTaxRule", "Creating tax rule...");
                seller_admin.base.enableLoadingModal("#loadingAddTaxRule", $this);

                $.ajax(
                    {
                         url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#tax-rule-modal').html(data.modal);
                            seller_admin.base.openModal('#tax-rule-form');
                            $('#tax-rule-modal .mdb-select').materialSelect();
                            $('#tax-rule-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingAddTaxRule", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingAddTaxRule", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        create: function () {
            $('body').on('submit', '#create-tax-rule', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingAddTaxRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#tax-rule-form').modal('hide');
                            $('#tax-rules-table tbody').prepend(data.tax_rule_row);
                            seller_admin.base.addCustomFlash('#tax-rules-section', 'success', 'Successfully added a tax rule to your store.');
                            seller_admin.base.disableLoadingModal("#loadingAddTaxRule", $this);
                            seller_admin.base.scrollTop('#tax-rules-section');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingAddTaxRule", $this);
                        }
                    });
                return false;
            });
        },

        edit: function () {
            $('body').on('click', '.edit-tax-rule', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingEditTaxRule", "Updating tax rule...");
                seller_admin.base.enableLoadingModal("#loadingEditTaxRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#tax-rule-modal').html(data.modal);
                            seller_admin.base.openModal('#tax-rule-form');
                            $('#tax-rule-modal .mdb-select').materialSelect();
                            $('#tax-rule-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingEditTaxRule", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingEditTaxRule", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        update: function () {
            $('body').on('submit', '#edit-tax-rule', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingEditTaxRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#tax-rule-form').modal('hide');
                            $('#tax-rules-table tbody tr#tax-rule-' + data.tax_rule_id).replaceWith(data.tax_rule_row);
                            seller_admin.base.addCustomFlash('#tax-rules-section', 'success', 'Successfully updated a tax rule for your store.');
                            seller_admin.base.disableLoadingModal("#loadingEditTaxRule", $this);
                            seller_admin.base.scrollTop('#tax-rules-section');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingEditTaxRule", $this);
                        }
                    });
                return false;
            });
        },

        archive: function()
        {
            $('body').on('click', '.archive-tax-rule', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    taxRuleId = $this.attr('data-tax-rule-id');

                seller_admin.base.assignLoadingModal("loadingArchiveTaxRule", "Archiving tax rule from your store...");
                seller_admin.base.enableLoadingModal("#loadingArchiveTaxRule", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('tr#tax-rule-' + taxRuleId).remove();
                            seller_admin.base.addCustomFlash('#tax-rules-section', 'success', 'Successfully archived a tax rule from your store.');
                            seller_admin.base.disableLoadingModal("#loadingArchiveTaxRule", $this);
                            seller_admin.base.scrollTop('#tax-rules-section');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingArchiveTaxRule", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }
seller_admin.nav_item = {

    addFormChangeNavType: function () {
        $('body').on('change', '#change-nav-item-type', function () {
            var accessToken = $('meta[name=api-access-token]').attr('content'),
                clientId = $('meta[name=api-client]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                uid = $('meta[name=api-uid]').attr('content');
            if (this.value == 'Page') {
                seller_admin.nav_item.listPages(accessToken, clientId, uid, storeId);
            } else if (this.value == 'Collection') {
                seller_admin.nav_item.listCollections(accessToken, clientId, uid, storeId);
            } else if (this.value == 'Product') {
                seller_admin.nav_item.listProducts(accessToken, clientId, uid, storeId);
            }
        });
    },

    listPages: function (accessToken, clientId, uid, storeId) {
        var selectInput = document.getElementById("navable-id-selector");
        $.ajax(
            {
                url: window.baseApiUrl + '/v1/store/pages?status=active&publish_status=published&page_type=standard',
                headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                type: 'GET',
                dataType: 'json',
                success: function (data) {
                    seller_admin.base.removeSelectOptions(selectInput);
                    data.pages.forEach(function(page)
                    {
                        var opt = document.createElement("option");
                        opt.value= page.uid;
                        opt.innerHTML = page.name; // whatever property it has

                        // then append it to the select element
                        selectInput.appendChild(opt);
                    });
                    console.log(data.pages);
                }
            });
    },

    listCollections: function (accessToken, clientId, uid, storeId) {
        var selectInput = document.getElementById("navable-id-selector");
        $.ajax(
            {
                url: window.baseApiUrl + '/v1/store/collections?status=active',
                headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                type: 'GET',
                dataType: 'json',
                success: function (data) {
                    seller_admin.base.removeSelectOptions(selectInput);
                    data.collections.forEach(function(collection)
                    {
                        var opt = document.createElement("option");
                        opt.value= collection.uid;
                        opt.innerHTML = collection.name;

                        selectInput.appendChild(opt);
                    });
                    console.log(data.collections);
                }
            });
    },

    listProducts: function (accessToken, clientId, uid, storeId) {
        var selectInput = document.getElementById("navable-id-selector");
        $.ajax(
            {
                url: window.baseApiUrl + '/v1/store/products?status=active',
                headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                type: 'GET',
                dataType: 'json',
                success: function (data) {
                    seller_admin.base.removeSelectOptions(selectInput);
                    data.products.forEach(function(product)
                    {
                        var opt = document.createElement("option");
                        opt.value= product.uid;
                        opt.innerHTML = product.name;

                        selectInput.appendChild(opt);
                    });
                    console.log(data.products);
                }
            });
    },

    initSortable: function () {
        $('#sortable-nav-item-list').sortable({
            placeholder: "ui-state-highlight",
            forcePlaceholderSize: true,
            tolerance: "pointer",
            update: function (e, ui) {
                seller_admin.base.enableLoadingModal("#loadingSortNavItem", $('#sort-nav-items-form'));
                $.ajax(
                    {
                        url: $(this).data("url"),
                        type: "PATCH",
                        data: $(this).sortable('serialize'),
                        success: function (data) {
                            $('#nav-items-table').html(data.table);
                            seller_admin.base.disableLoadingModal("#loadingSortNavItem", $('#sort-nav-items-form'));
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingSortNavItem", $('#sort-nav-items-form'));
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
            }
        });
        $("#sortable-nav-item-list").disableSelection();
    },

    sortModal: function () {
        $('body').on('click', '#reorder-nav-items', function () {
            var url = $(this).attr('href');

            seller_admin.base.assignLoadingModal("loadingSortNavItem", "Sorting your navigation items...");
            seller_admin.base.enableLoadingModal("#loadingSortNavItem", $('#sort-nav-items-form'));

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#sort-nav-items-wrapper').html(data.modal);
                        seller_admin.base.disableLoadingModal("#loadingSortNavItem", $('#sort-nav-items-form'));
                        seller_admin.base.openModal('#sort-nav-items-form');
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingSortNavItem", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    remove: function()
    {
        $('body').on('click', '.prompt-remove-nav-item', function() {
            var navItemId = $(this).attr('data-record-id');

            seller_admin.base.assignConfirmationModal('remove-nav-item',
                'Are you sure you wish to remove your navigation item?', navItemId);
            seller_admin.base.enableConfirmationModal();
            return false;
        });
        $('body').on('click', '.remove-nav-item', function () {
            var $this = $(this),
                navItemId = $this.attr('data-record-id');

            seller_admin.base.disableConfirmationModal();
            seller_admin.base.assignLoadingModal("loadingRemoveNavItem", "Removing navigation item from your store...");
            seller_admin.base.enableLoadingModal("#loadingRemoveNavItem", $this);

            $.ajax(
                {
                    url: '/admin/nav_items/' + navItemId,
                    type: 'DELETE',
                    dataType: 'json',
                    success: function (data) {
                        $('tr#nav-item-' + navItemId).remove();
                        seller_admin.base.addFlash('success', 'Successfully removed a navigation item from your store.');
                        seller_admin.base.disableLoadingModal("#loadingRemoveNavItem", $this);
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingRemoveNavItem", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    }
}
seller_admin.page = {


    bindPublishNotification: function (channel) {
        channel.bind('page_publish_list', function (data) {
            var draftPage = $('table#pages-list tr#page-' + data.draft_page_id);
            if (draftPage.length > 0) {
                draftPage.find('td.page-draft-version').text(data.current_draft_version);
                draftPage.find('td.page-live-version').text(data.current_live_version);
                draftPage.find('td.page-publish-status').text(data.publish_status);
                draftPage.find('td.page-published-at').text(data.published_at);
            }
        });
    },

    updateNameToSlug: function () {
        var nameInput = document.getElementById('page_name'),
            slugInput = document.getElementById('page_slug'),
            slugLabel = $('label[for="page_slug"]');

        // Init a timeout variable to be used below
        var timeout = null;

        // Listen for keystroke events
        if (typeof (nameInput) != 'undefined' && nameInput != null) {
            nameInput.onkeyup = function (e) {

                // Clear the timeout if it has already been set.
                // This will prevent the previous task from executing
                // if it has been less than <MILLISECONDS>
                clearTimeout(timeout);

                // Make a new timeout set to go off in 800ms
                timeout = setTimeout(function () {
                    if (slugInput.value === "") {
                        slugInput.value = seller_admin.base.string_parameterize(nameInput.value);
                        slugLabel.addClass('active');
                    }
                }, 2000);
            };
        }
    },

    formatSlug: function () {
        var slugInput = document.getElementById('page_slug');

        // Init a timeout variable to be used below
        var timeout = null;

        // Listen for keystroke events
        if (typeof (slugInput) != 'undefined' && slugInput != null) {
            slugInput.onkeyup = function (e) {

                // Clear the timeout if it has already been set.
                // This will prevent the previous task from executing
                // if it has been less than <MILLISECONDS>
                clearTimeout(timeout);

                // Make a new timeout set to go off in 800ms
                timeout = setTimeout(function () {
                    slugInput.value = seller_admin.base.string_parameterize(slugInput.value);
                }, 500);
            };
        }
    },

    initSortable: function () {
        $('#sortable-page-list').sortable({
            placeholder: "ui-state-highlight",
            forcePlaceholderSize: true,
            tolerance: "pointer",
            update: function (e, ui) {
                seller_admin.base.enableLoadingModal("#loadingSortPages", $('#sort-pages-form'));
                $.ajax(
                    {
                        url: $(this).data("url"),
                        type: "PATCH",
                        data: $(this).sortable('serialize'),
                        success: function (data) {
                            $('#pages-table').html(data.table);
                            seller_admin.base.disableLoadingModal("#loadingSortPages", $('#sort-pages-form'));
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingSortPages", $('#sort-pages-form'));
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
            }
        });
        $("#sortable-page-list").disableSelection();
    },

    sortPagesModal: function () {
        $('body').on('click', '#reorder-pages', function () {
            var url = $(this).attr('href');

            seller_admin.base.assignLoadingModal("loadingSortPages", "Sorting your pages...");
            seller_admin.base.enableLoadingModal("#loadingSortPages", $('#sort-pages-form'));

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#sort-pages-wrapper').html(data.modal);
                        seller_admin.base.disableLoadingModal("#loadingSortPages", $('#sort-pages-form'));
                        seller_admin.base.openModal('#sort-pages-form');
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingSortPages", $('#sort-pages-form'));
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    newDuplicate: function () {
        $('body').on('click', '.duplicate-page', function () {
            var $this = $(this),
                url = $this.attr('href');

            seller_admin.base.assignLoadingModal("loadingDuplicatePage", "Duplicating your page...");
            seller_admin.base.enableLoadingModal("#loadingDuplicatePage", $this);
            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#duplicate-page-wrapper').html(data.modal);
                        seller_admin.base.disableLoadingModal("#loadingDuplicatePage", $this);
                        seller_admin.base.openModal('#duplicate-page-modal');
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingDuplicatePage", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    createDuplicate: function () {
        $('body').on('submit', '#duplicate-page-form', function () {
            var $this = $(this),
                url = $this.attr('action');

            seller_admin.base.enableLoadingModal("#loadingDuplicatePage", $this);

            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    data: $this.serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $('#duplicate-page-modal').modal('hide');
                        seller_admin.base.disableLoadingModal("#loadingDuplicatePage", $this);
                        seller_admin.base.addFlash("success", "Successfully duplicated the page, updates will appear in a few moments.");
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.jsonErrors(xhr, evt, status, $this);
                        seller_admin.base.disableLoadingModal("#loadingDuplicatePage", $this);
                    }
                });
            return false;
        });
    },

    saveContent: function () {
        var $this = $(this),
            accessToken = $('meta[name=api-access-token]').attr('content'),
            clientId = $('meta[name=api-client]').attr('content'),
            storeId = $('meta[name=store-id]').attr('content'),
            uid = $('meta[name=api-uid]').attr('content'),
            pageId = $('.is-wrapper').attr('data-page-id'),
            wrapperContent = $('.is-wrapper'),
            html = window.contentBuilder.html();

        seller_admin.base.assignLoadingModal("loadingSavePageDesign", "Saving your page design...");
        seller_admin.base.enableLoadingModal("#loadingSavePageDesign", $this);

        $.ajax(
            {
                url: window.baseApiUrl + '/v1/store/pages/' + pageId,
                headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                type: 'PATCH',
                data: {
                    'page': {'content': html},
                    'version_number': wrapperContent.attr('data-version')
                },
                dataType: 'json',
                success: function (data) {
                    seller_admin.base.disableLoadingModal("#loadingSavePageDesign", $this);
                    wrapperContent.attr('data-version', data.version_number);
                    $('#design-image-progress .progress-bar').css('width', '0%');
                    console.log("DESIGN UPDATE SUCCESSFUL");
                },
                error: function (xhr, evt, status) {
                    seller_admin.base.disableLoadingModal("#loadingSavePageDesign", $this);
                    seller_admin.base.addFlash("danger", "Failed to save page, please try again.");
                    seller_admin.base.scrollTop("main");
                    seller_admin.page_designer.versionMismatchModal(xhr.responseJSON.version_mismatch);
                    $('#design-image-progress .progress-bar').css('width', '0%');
                    console.log("DESIGN UPDATE FAILED");
                }
            });
    },

    autoSaveContent: function () {
        var accessToken = $('meta[name=api-access-token]').attr('content'),
            clientId = $('meta[name=api-client]').attr('content'),
            storeId = $('meta[name=store-id]').attr('content'),
            uid = $('meta[name=api-uid]').attr('content'),
            pageId = $('.is-wrapper').attr('data-page-id'),
            wrapperContent = $('.is-wrapper'),
            html = window.contentBuilder.html();

        $('#design-image-progress .progress-bar').css('width', '100%');

        $.ajax(
            {
                url: window.baseApiUrl + '/v1/store/pages/' + pageId,
                headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                type: 'PATCH',
                data: {
                    'page': {'content': html},
                    'version_number': wrapperContent.attr('data-version')
                },
                dataType: 'json',
                success: function (data) {
                    seller_admin.base.successfulDesignerAutoSave();
                    wrapperContent.attr('data-version', data.version_number);
                    $('#design-image-progress .progress-bar').css('width', '0%');
                    console.log("DESIGN UPDATE SUCCESSFUL");
                },
                error: function (xhr, evt, status) {
                    seller_admin.base.failedDesignerAutoSave();
                    seller_admin.page_designer.versionMismatchModal(xhr.responseJSON.version_mismatch);
                    $('#design-image-progress .progress-bar').css('width', '0%');
                    console.log("DESIGN UPDATE FAILED");
                }
            });
    },

    publish: function () {
        $('body').on('click', '.publish-page', function () {
            var $this = $(this),
                url = $this.attr('href');

            seller_admin.base.assignLoadingModal("loadingPublishPageDesign", "Publishing your page design...");
            seller_admin.base.enableLoadingModal("#loadingPublishPageDesign", $this);

            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    success: function (data) {
                        seller_admin.base.disableLoadingModal("#loadingPublishPageDesign", $this);
                        seller_admin.base.addFlash("success", "Updates will appear live in a few moments.");
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingPublishPageDesign", $this);
                        seller_admin.base.addFlash("danger", "Failed to publish page, please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    remove: function () {
        $('body').on('click', '.prompt-remove-page', function () {
            var pageId = $(this).attr('data-record-id');

            seller_admin.base.assignConfirmationModal('remove-page',
                'Are you sure you wish to remove your page?', pageId);
            seller_admin.base.enableConfirmationModal();
            return false;
        });
        $('body').on('click', '.remove-page', function () {
            var $this = $(this),
                pageId = $this.attr('data-record-id');

            seller_admin.base.disableConfirmationModal();
            seller_admin.base.assignLoadingModal("loadingRemovePage", "Removing page from your store...");
            seller_admin.base.enableLoadingModal("#loadingRemovePage", $this);

            $.ajax(
                {
                    url: '/admin/pages/' + pageId,
                    type: 'DELETE',
                    dataType: 'json',
                    success: function (data) {
                        $('tr#page-' + pageId).remove();
                        seller_admin.base.addFlash('success', 'Successfully removed a page from your store.');
                        seller_admin.base.disableLoadingModal("#loadingRemovePage", $this);
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingRemovePage", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    }
}
seller_admin.transaction =
    {
        newRefund: function () {
            $('body').on('click', '.create-transaction-refund', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingRefundTransaction", "Creating your refund...");
                seller_admin.base.enableLoadingModal("#loadingRefundTransaction", $this);
                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#transaction-refund-modal').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingRefundTransaction", $this);
                            seller_admin.base.openModal('#transaction-refund-form');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingRefundTransaction", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        createRefund: function () {
            $('body').on('submit', '.transaction-refund-form', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingRefundTransaction", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#transaction-refund-form').modal('hide');
                            $('#refunds-table tbody').prepend(data.row);
                            seller_admin.base.disableLoadingModal("#loadingRefundTransaction", $this);
                            seller_admin.base.addFlash("success", "Successfully created a refund for this order.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingRefundTransaction", $this);
                        }
                    });
                return false;
            });
        }
    }
seller_admin.subscription = {

    createSingleEmailPdf: function () {
        $('#create-subscription-email-pdf').on('click', function () {
            var $this = $(this),
                orderId = $this.attr('data-order-id');
            $('.email-pdf-flash').remove();


            seller_admin.base.assignLoadingModal("loadingCreateOrderEmailPdf", "Creating Email PDF for your order...");
            seller_admin.base.enableLoadingModal("#loadingCreateOrderEmailPdf", $this);

            $.ajax(
                {
                    url: '/admin/order_email_pdfs/subscription',
                    type: 'POST',
                    data: {'order_ids': [orderId]},
                    dataType: 'json',
                    success: function (data) {
                        seller_admin.base.addFlash("success email-pdf-flash", "Successfully created an Email PDF. Please check your email for a link to the file.");
                        seller_admin.base.disableLoadingModal("#loadingCreateOrderEmailPdf", $this);
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.addFlash("warning email-pdf-flash", "Unable to create an Email PDF. Please try again.");
                        seller_admin.base.disableLoadingModal("#loadingCreateOrderEmailPdf", $this);
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    createCombinedEmailPdf: function () {
        $('#export-subscription-pdfs-button').on('click', function () {
            var $this = $(this);
            $('.export-pdfs-flash').remove();

            seller_admin.base.assignLoadingModal("loadingCreateCombinedPDF", "Creating combined PDF invoice for your orders...");
            seller_admin.base.enableLoadingModal("#loadingCreateCombinedPDF", $this);

            var orderIds = [];
            $.each($(".order_checkbox:checked"), function () {
                orderIds.push($(this).val());
            });
            if (orderIds.length === 0) {
                seller_admin.base.addFlash("warning export-pdfs-flash", "You need to select some orders before requesting a combined PDF invoice.");
                seller_admin.base.disableLoadingModal("#loadingCreateCombinedPDF", $this);
                seller_admin.base.scrollTop("main");
            } else {
                $.ajax(
                    {
                        url: '/admin/order_email_pdfs/subscription',
                        type: 'POST',
                        data: {'order_ids': orderIds},
                        dataType: 'json',
                        success: function (data) {
                            seller_admin.base.addFlash("success export-pdfs-flash", "Successfully created a combined PDF invoice. Please check your store email for a link to the file.");
                            seller_admin.base.disableLoadingModal("#loadingCreateCombinedPDF", $this);
                            seller_admin.base.scrollTop("main");
                            $('.order_checkbox').prop('checked', false);
                            $('#check_all_orders').prop('checked', false);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.addFlash("warning export-pdfs-flash", "Unable to create a combined PDF invoice. Please try again.");
                            seller_admin.base.disableLoadingModal("#loadingCreateCombinedPDF", $this);
                            seller_admin.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    },

    pause: function()
    {
        $('body').on('click', '.pause-customer-subscription', function () {
            var modalId = $(this).attr('data-modal-id'),
                $this = $('#' + modalId),
                pauseDateTime = $(this).attr('data-pause-timestamp'),
                subscriptionId = $(this).attr('data-subscription-id');

            seller_admin.base.assignLoadingModal("loadingPauseCustomerSubscription", "Pausing subscription...");
            seller_admin.base.enableLoadingModal("#loadingPauseCustomerSubscription", $this);

            $.ajax(
                {
                    url: '/admin/customers/stripe/subscriptions/' + subscriptionId +'/pause',
                    type: 'PATCH',
                    data: { pause_datetime: pauseDateTime },
                    dataType: 'json',
                    success: function (data) {
                        $('#pause-customer-subscription').remove();
                        $('.subscription-status-badge').replaceWith('<span class="subscription-status-badge ml-2 badge badge-warning">Paused</span>');
                        $('#customer-unpause-subscription').show();
                        $('#next-rebill-wrapper').text(data.resume_paused_at);
                        seller_admin.base.disableLoadingModal("#loadingPauseCustomerSubscription", $this);
                        $('#' + modalId).modal('hide');
                        seller_admin.base.addFlash("success", "Successfully paused this subscription. Payments will resume on " + data.resume_paused_at_datetime + ".");
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingPauseCustomerSubscription", $this);
                        $('#' + modalId).modal('hide');
                        seller_admin.base.addFlash("danger", "Failed to pause subscription, please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    }
}
seller_admin.order_item = {

    editTracking: function () {
        $('body').on('click', '.edit-order-item-tracking', function () {
            var $this = $(this),
                url = $this.attr('href');

            seller_admin.base.assignLoadingModal("loadingEditOrderItemTracking", "Updating order item tracking...");
            seller_admin.base.enableLoadingModal("#loadingEditOrderItemTracking", $('#order-item-tracking-form'));

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#order-item-tracking-modal').html(data.modal);
                        seller_admin.base.openModal('#order-item-tracking-form');
                        seller_admin.base.disableLoadingModal("#loadingEditOrderItemTracking", $('#order-item-tracking-form'));
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingEditOrderItemTracking", $('#order-item-tracking-form'));
                        seller_admin.base.addCustomFlash('#shop-order-items', "danger", "Something went wrong. Please try again.");
                    }
                });
            return false;
        });
    },

    updateTracking: function () {
        $('body').on('submit', '#edit-order-item-tracking', function () {
            var $this = $(this),
                url = $this.attr('action');

            $('.tracking-flash').remove();
            seller_admin.base.enableLoadingModal("#loadingEditOrderItemTracking", $('#order-item-tracking-form'));

            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    data: $this.serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $('#order-item-tracking-form').modal('hide');
                        seller_admin.base.addCustomFlash('#shop-order-items', 'success tracking-flash', 'Successfully updated an order item tracking.')
                        seller_admin.base.disableLoadingModal("#loadingEditOrderItemTracking", $('#order-item-tracking-form'));
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.jsonErrors(xhr, evt, status, $this);
                        seller_admin.base.disableLoadingModal("#loadingEditOrderItemTracking", $('#order-item-tracking-form'));
                    }
                });
            return false;
        });
    },

    singleFulfillOrder: function () {
        $('body').on('click', '.fulfill-shop-order-item', function () {
            var $this = $(this),
                orderItemId = $this.attr('data-order-item-id');

            $('.fulfilled-flash').remove();

            seller_admin.base.assignLoadingModal("loadingFulfillShopOrderItem", "Sending your order item to be fulfilled...");
            seller_admin.base.enableLoadingModal("#loadingFulfillShopOrderItem", $this);

            $.ajax(
                {
                    url: '/admin/orders/shop/fulfillment/single_order_item',
                    type: 'POST',
                    data: {'order_item_id': orderItemId},
                    dataType: 'json',
                    success: function (data) {
                        $('tr#order-item-' + orderItemId).replaceWith(data.row);
                        $('#order-fulfillment-status').text(data.order_fulfillment_status);
                        seller_admin.base.addCustomFlash('#shop-order-items', 'success fulfilled-flash', 'Your order item has been successfully fulfilled.')
                        seller_admin.base.disableLoadingModal("#loadingFulfillShopOrderItem", $this);
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.addCustomFlash('#shop-order-items', "warning fulfilled-flash", "Unable to send your order item for fulfillment. Please try again.");
                        seller_admin.base.disableLoadingModal("#loadingFulfillShopOrderItem", $this);
                    }
                });
            return false;
        });
    },

    bulkFulfillOrder: function () {
        $('#fulfill-shop-orders').on('click', function () {
            var $this = $(this);
            $('.fulfilled-flash').remove();

            seller_admin.base.assignLoadingModal("loadingFulfillShopOrders", "Sending your orders to be fulfilled...");
            seller_admin.base.enableLoadingModal("#loadingFulfillShopOrders", $this);

            var orderIds = [];
            $.each($(".order_checkbox:checked"), function () {
                orderIds.push($(this).val());
            });
            if (orderIds.length === 0) {
                seller_admin.base.addFlash("warning fulfilled-flash", "You need to select some orders before sending them to be fulfilled.");
                seller_admin.base.disableLoadingModal("#loadingFulfillShopOrders", $this);
                seller_admin.base.scrollTop("main");
            } else {
                $.ajax(
                    {
                        url: '/admin/orders/shop/fulfillment/bulk',
                        type: 'POST',
                        data: {'order_ids': orderIds},
                        dataType: 'json',
                        success: function (data) {
                            seller_admin.base.addFlash("success fulfilled-flash", "Your orders are being fulfilled now. When completed, you will be notified via email. You can safely leave this screen and return at any time.");
                            seller_admin.base.disableLoadingModal("#loadingFulfillShopOrders", $this);
                            seller_admin.base.scrollTop("main");
                            $('.order_checkbox').prop('checked', false);
                            $('#check_all_orders').prop('checked', false);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.addFlash("warning fulfilled-flash", "Unable to send your orders for fulfillment. Please try again.");
                            seller_admin.base.disableLoadingModal("#loadingFulfillShopOrders", $this);
                            seller_admin.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    },

    markSingleAsShipped: function () {
        $('body').on('click', '.mark-shop-as-shipped', function () {
            var $this = $(this),
                orderItemId = $this.attr('data-order-item-id');

            seller_admin.base.assignLoadingModal("loadingMarkSingleShopShipped", "Marking your order item as shipped...");
            seller_admin.base.enableLoadingModal("#loadingMarkSingleShopShipped", $this);

            $.ajax(
                {
                    url: '/admin/orders/shop/shipping/single_order_item',
                    type: 'POST',
                    data: {'order_item_id': orderItemId},
                    dataType: 'json',
                    success: function (data) {
                        $('tr#order-item-' + orderItemId).replaceWith(data.row);
                        $('#order-shipping-status').text(data.order_shipping_status);
                        seller_admin.base.addCustomFlash('#shop-order-items', "success", "Your order item has been marked as shipped.");
                        seller_admin.base.disableLoadingModal("#loadingMarkSingleShopShipped", $this);
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingMarkSingleShopShipped", $this);
                        seller_admin.base.addCustomFlash('#shop-order-items', "danger", "Something went wrong. Please try again.");
                    }
                });
            return false;
        });
    },

    markCollectionAsShipped: function () {
        $('#mark-shops-as-shipped').on('click', function () {
            var $this = $(this);
            $('.shipped-flash').remove();

            seller_admin.base.assignLoadingModal("loadingMarkShopsShipped", "Marking your orders as shipped...");
            seller_admin.base.enableLoadingModal("#loadingMarkShopsShipped", $this);

            var orderIds = [];
            $.each($(".order_checkbox:checked"), function () {
                orderIds.push($(this).val());
            });
            if (orderIds.length === 0) {
                seller_admin.base.addFlash("warning shipped-flash", "You need to select some orders before marking them as shipped.");
                seller_admin.base.disableLoadingModal("#loadingMarkShopsShipped", $this);
                seller_admin.base.scrollTop("main");
            } else {
                $.ajax(
                    {
                        url: '/admin/orders/shop/shipping/bulk',
                        type: 'POST',
                        data: {'order_ids': orderIds},
                        dataType: 'json',
                        success: function (data) {
                            if (data.order_ids !== undefined) {
                                for (_i = 0, _len = data.order_ids.length; _i < _len; _i++) {
                                    value = data.order_ids[_i]
                                    $('tr.order_' + value + ' td.shipping-status').text('Completed');
                                }
                            }
                            seller_admin.base.addFlash("success shipped-flash", "Your orders have been marked as shipped.");
                            seller_admin.base.disableLoadingModal("#loadingMarkShopsShipped", $this);
                            seller_admin.base.scrollTop("main");
                            $('.order_checkbox').prop('checked', false);
                            $('#check_all_orders').prop('checked', false);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.addFlash("warning shipped-flash", "Unable to mark your orders as shipped. Please try again.");
                            seller_admin.base.disableLoadingModal("#loadingMarkShopsShipped", $this);
                            seller_admin.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    }
}
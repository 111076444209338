seller_admin.incentive_email =
    {
        publish: function () {
            $('body').on('click', '.publish-incentive-email', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    incentiveEmailId = $this.attr('data-incentive-email-id');

                seller_admin.base.assignLoadingModal("loadingPublishIncentiveEmail", "Publishing your incentive email template...");
                seller_admin.base.enableLoadingModal("#loadingPublishIncentiveEmail", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('tr.incentive-email-' + incentiveEmailId).replaceWith(data.row);
                            seller_admin.base.disableLoadingModal("#loadingPublishIncentiveEmail", $this);
                            seller_admin.base.addFlash("success", "Successfully published an incentive email template.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingPublishIncentiveEmail", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        unpublish: function () {
            $('body').on('click', '.unpublish-incentive-email', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    incentiveEmailId = $this.attr('data-incentive-email-id');

                seller_admin.base.assignLoadingModal("loadingUnpublishIncentiveEmail", "Unpublishing your incentive email template...");
                seller_admin.base.enableLoadingModal("#loadingUnpublishIncentiveEmail", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('tr.incentive-email-' + incentiveEmailId).replaceWith(data.row);
                            seller_admin.base.disableLoadingModal("#loadingUnpublishIncentiveEmail", $this);
                            seller_admin.base.addFlash("success", "Successfully unpublished an incentive email template.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUnpublishIncentiveEmail", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        sendTestEmailModal: function () {
            $('body').on('click', '.send-incentive-test-email-modal', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingTestIncentiveEmail", "Sending test incentive email...");
                seller_admin.base.enableLoadingModal("#loadingTestIncentiveEmail", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#send-test-email-wrapper').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingTestIncentiveEmail", $this);
                            seller_admin.base.openModal('#test-incentive-email-form');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingTestIncentiveEmail", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        sendTestEmail: function () {
            $('body').on('submit', '#send-test-incentive-email', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingTestIncentiveEmail", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#test-incentive-email-form').modal('hide');
                            seller_admin.base.disableLoadingModal("#loadingTestIncentiveEmail", $this);
                            seller_admin.base.addFlash("success", "Successfully sent a test email for " + data.incentive_email.subject + ".");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            $('#test-incentive-email-form #errors ul').html("<li><i class='fa fa-times-circle'></i>Invalid email, please try again.</li>");
                            $('#test-incentive-email-form .json-error-wrapper').show();
                            seller_admin.base.disableLoadingModal("#loadingTestIncentiveEmail", $this);
                        }
                    });
                return false;
            });
        }
    }
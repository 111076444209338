seller_admin.order =
    {
        selectAllOrders: function () {
            $("#check_all_orders").on('click', function () {
                $('.order_checkbox').prop('checked', this.checked);
            });
        },

        selectAllOrderAttributes: function () {
            $('#order_csv_attribute_select_all').on('click', function () {
                $('.order_csv_attribute_checkbox').prop('checked', this.checked);
            });
        },

        openCsvReportModal: function () {
            $('#export-order-csv-button').on('click', function () {
                var $this = $(this);
                $('.csv-report-flash').remove();

                seller_admin.base.assignLoadingModal("loadingCreateOrderCSVReport", "Creating CSV report for your orders...");
                seller_admin.base.enableLoadingModal("#loadingCreateOrderCSVReport", $this);

                var orderIds = [];
                $.each($(".order_checkbox:checked"), function () {
                    orderIds.push($(this).val());
                });
                if (orderIds.length === 0) {
                    seller_admin.base.addFlash("warning csv-report-flash", "You need to select some orders before requesting a CSV report.");
                    seller_admin.base.disableLoadingModal("#loadingCreateOrderCSVReport", $this);
                    seller_admin.base.scrollTop("main");
                } else {
                    seller_admin.base.disableLoadingModal("#loadingCreateOrderCSVReport", $(this));
                    seller_admin.base.openModal('#create-order-csv-form');
                }
                return false;
            });
        },

        createCsvReport: function () {
            $('body').on('submit', '#create-order-csv', function () {
                var $this = $(this);

                $('#create-order-csv .json-error-wrapper').hide();
                $('#create-order-csv .json-error-wrapper #errors ul').empty();

                seller_admin.base.assignLoadingModal("loadingCreateOrderCSVReport", "Creating CSV report for your orders...");
                seller_admin.base.enableLoadingModal("#loadingCreateOrderCSVReport", $('#create-order-csv-form'));
                var orderIds = [];
                $.each($(".order_checkbox:checked"), function () {
                    orderIds.push($(this).val());
                });
                var attributeValues = []
                $.each($(".order_csv_attribute_checkbox:checked"), function () {
                    attributeValues.push($(this).val());
                });
                if (attributeValues.length === 0) {
                    seller_admin.base.customJsonErrors(['Please select at least one checkbox.'], $('#create-order-csv-form'));
                    seller_admin.base.disableLoadingModal("#loadingCreateOrderCSVReport", $('#create-order-csv-form'));
                } else {
                    $.ajax(
                        {
                            url: '/admin/order_csvs',
                            type: 'POST',
                            data: {
                                'order_ids': orderIds,
                                'selected_attributes': attributeValues
                            },
                            dataType: 'json',
                            success: function (data) {
                                seller_admin.base.addFlash("success csv-report-flash", "Successfully created the CSV report. Please check your store email for a link to the file.");
                                seller_admin.base.disableLoadingModal("#loadingCreateOrderCSVReport", $('#create-order-csv-form'));
                                $('#create-order-csv-form').modal('hide');
                                seller_admin.base.scrollTop("main");
                                $('.order_checkbox').prop('checked', false);
                                $('.order_csv_attribute_checkbox').prop('checked', false);
                                $('#check_all_orders').prop('checked', false);
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.disableLoadingModal("#loadingCreateOrderCSVReport", $('#create-order-csv-form'));
                                seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            }
                        });
                }
                return false;
            });
        },

        createSingleEmailPdf: function () {
            $('#create-email-pdf').on('click', function () {
                var $this = $(this),
                    orderId = $this.attr('data-order-id');
                $('.email-pdf-flash').remove();


                seller_admin.base.assignLoadingModal("loadingCreateOrderEmailPdf", "Creating Email PDF for your order...");
                seller_admin.base.enableLoadingModal("#loadingCreateOrderEmailPdf", $this);

                $.ajax(
                    {
                        url: '/admin/order_email_pdfs/shop',
                        type: 'POST',
                        data: {'order_ids': [orderId]},
                        dataType: 'json',
                        success: function (data) {
                            seller_admin.base.addFlash("success email-pdf-flash", "Successfully created an Email PDF. Please check your email for a link to the file.");
                            seller_admin.base.disableLoadingModal("#loadingCreateOrderEmailPdf", $this);
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.addFlash("warning email-pdf-flash", "Unable to create an Email PDF. Please try again.");
                            seller_admin.base.disableLoadingModal("#loadingCreateOrderEmailPdf", $this);
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        createCombinedEmailPdf: function () {
            $('#export-pdfs-button').on('click', function () {
                var $this = $(this);
                $('.export-pdfs-flash').remove();


                seller_admin.base.assignLoadingModal("loadingCreateCombinedPDF", "Creating combined PDF invoice for your orders...");
                seller_admin.base.enableLoadingModal("#loadingCreateCombinedPDF", $this);

                var orderIds = [];
                $.each($(".order_checkbox:checked"), function () {
                    orderIds.push($(this).val());
                });
                if (orderIds.length === 0) {
                    seller_admin.base.addFlash("warning export-pdfs-flash", "You need to select some orders before requesting a combined PDF invoice.");
                    seller_admin.base.disableLoadingModal("#loadingCreateCombinedPDF", $this);
                    seller_admin.base.scrollTop("main");
                } else {
                    $.ajax(
                        {
                            url: '/admin/order_email_pdfs/shop',
                            type: 'POST',
                            data: {'order_ids': orderIds},
                            dataType: 'json',
                            success: function (data) {
                                seller_admin.base.addFlash("success export-pdfs-flash", "Successfully created a combined PDF invoice. Please check your store email for a link to the file.");
                                seller_admin.base.disableLoadingModal("#loadingCreateCombinedPDF", $this);
                                seller_admin.base.scrollTop("main");
                                $('.order_checkbox').prop('checked', false);
                                $('#check_all_orders').prop('checked', false);
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.addFlash("warning export-pdfs-flash", "Unable to create a combined PDF invoice. Please try again.");
                                seller_admin.base.disableLoadingModal("#loadingCreateCombinedPDF", $this);
                                seller_admin.base.scrollTop("main");
                            }
                        });
                }
                return false;
            });
        },

        retryPayment: function () {
            $('body').on('click', '.retry-payment', function () {
                var url = $(this).attr('href'),
                    $this = $(this);

                seller_admin.base.assignLoadingModal("loadingRetryPaymentOrder", "Retrying payment for your order...");
                seller_admin.base.enableLoadingModal("#loadingRetryPaymentOrder", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('#order-payment-status').text('Completed');
                            $('.retry-payment').remove();
                            seller_admin.base.disableLoadingModal("#loadingRetryPaymentOrder", $this);
                            seller_admin.base.addFlash("success", "Successfully captured payment for the order. Updates will appear momentarily.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            var error = $.parseJSON(xhr.responseText).error;
                            seller_admin.base.disableLoadingModal("#loadingRetryPaymentOrder", $this);
                            seller_admin.base.addFlash("warning", error);
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        shopSingleFulfillOrder: function () {
            $('#fulfill-shop-order').on('click', function () {
                var $this = $(this),
                    orderId = $this.attr('data-order-id');

                $('.fulfilled-flash').remove();

                seller_admin.base.assignLoadingModal("loadingSingleFulfillShopOrder", "Sending your order to be fulfilled...");
                seller_admin.base.enableLoadingModal("#loadingSingleFulfillShopOrder", $this);

                $.ajax(
                    {
                        url: '/admin/orders/shop/fulfillment/single_order',
                        type: 'POST',
                        data: {'order_id': orderId},
                        dataType: 'json',
                        success: function (data) {
                            $('#order-fulfillment-status').text('Completed');
                            $('#fulfill-shop-order').remove();
                            seller_admin.base.addFlash("success fulfilled-flash", "Your order has been successfully fulfilled.");
                            seller_admin.base.disableLoadingModal("#loadingSingleFulfillShopOrder", $this);
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.addFlash("warning fulfilled-flash", "Unable to send your order for fulfillment. Please try again.");
                            seller_admin.base.disableLoadingModal("#loadingSingleFulfillShopOrder", $this);
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        shopMarkSingleAsShipped: function () {
            $('#mark-shop-as-shipped').on('click', function () {
                var $this = $(this),
                    orderId = $this.attr('data-order-id');
                $('.shipped-flash').remove();

                seller_admin.base.assignLoadingModal("loadingMarkSingleShopShipped", "Marking your order as shipped...");
                seller_admin.base.enableLoadingModal("#loadingMarkSingleShopShipped", $this);

                $.ajax(
                    {
                        url: '/admin/orders/shop/shipping/bulk',
                        type: 'POST',
                        data: {'order_ids': [orderId]},
                        dataType: 'json',
                        success: function (data) {
                            seller_admin.base.addFlash("success shipped-flash", "Your order has been marked as shipped.");
                            seller_admin.base.disableLoadingModal("#loadingMarkSingleShopShipped", $this);
                            seller_admin.base.scrollTop("main");
                            $('#mark-shop-as-shipped').remove();
                            $('#order-shipping-status').text('Completed');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.addFlash("warning shipped-flash", "Unable to mark your order as shipped. Please try again.");
                            seller_admin.base.disableLoadingModal("#loadingMarkSingleShopShipped", $this);
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        singleFulfillOrder: function () {
            $('#fulfill-subscription-order').on('click', function () {
                var $this = $(this),
                    orderId = $this.attr('data-order-id');

                $('.fulfilled-flash').remove();

                seller_admin.base.assignLoadingModal("loadingSingleFulfillSubscriptionOrder", "Sending your order to be fulfilled...");
                seller_admin.base.enableLoadingModal("#loadingSingleFulfillSubscriptionOrder", $this);

                $.ajax(
                    {
                        url: '/admin/orders/subscription/fulfillment/single',
                        type: 'POST',
                        data: {'order_id': orderId},
                        dataType: 'json',
                        success: function (data) {
                            $('#order-fulfillment-status').text('Completed');
                            $('#fulfill-subscription-order').remove();
                            seller_admin.base.addFlash("success fulfilled-flash", "Your order has been successfully fulfilled.");
                            seller_admin.base.disableLoadingModal("#loadingSingleFulfillSubscriptionOrder", $this);
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.addFlash("warning fulfilled-flash", "Unable to send your order for fulfillment. Please try again.");
                            seller_admin.base.disableLoadingModal("#loadingSingleFulfillSubscriptionOrder", $this);
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        bulkFulfillOrder: function () {
            $('#fulfill-subscription-orders').on('click', function () {
                var $this = $(this);
                $('.fulfilled-flash').remove();

                seller_admin.base.assignLoadingModal("loadingFulfillSubscriptionOrders", "Sending your orders to be fulfilled...");
                seller_admin.base.enableLoadingModal("#loadingFulfillSubscriptionOrders", $this);

                var orderIds = [];
                $.each($(".order_checkbox:checked"), function () {
                    orderIds.push($(this).val());
                });
                if (orderIds.length === 0) {
                    seller_admin.base.addFlash("warning fulfilled-flash", "You need to select some orders before sending them to be fulfilled.");
                    seller_admin.base.disableLoadingModal("#loadingFulfillSubscriptionOrders", $this);
                    seller_admin.base.scrollTop("main");
                } else {
                    $.ajax(
                        {
                            url: '/admin/orders/subscription/fulfillment/bulk',
                            type: 'POST',
                            data: {'order_ids': orderIds},
                            dataType: 'json',
                            success: function (data) {
                                seller_admin.base.addFlash("success fulfilled-flash", "Your orders are being fulfilled now. When completed, you will be notified via email. You can safely leave this screen and return at any time.");
                                seller_admin.base.disableLoadingModal("#loadingFulfillSubscriptionOrders", $this);
                                seller_admin.base.scrollTop("main");
                                $('.order_checkbox').prop('checked', false);
                                $('#check_all_orders').prop('checked', false);
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.addFlash("warning fulfilled-flash", "Unable to send your orders for fulfillment. Please try again.");
                                seller_admin.base.disableLoadingModal("#loadingFulfillSubscriptionOrders", $this);
                                seller_admin.base.scrollTop("main");
                            }
                        });
                }
                return false;
            });
        },

        markSingleAsShipped: function () {
            $('#mark-subscription-as-shipped').on('click', function () {
                var $this = $(this),
                    orderId = $this.attr('data-order-id');
                $('.shipped-flash').remove();

                seller_admin.base.assignLoadingModal("loadingMarkSingleSubscriptionShipped", "Marking your order as shipped...");
                seller_admin.base.enableLoadingModal("#loadingMarkSingleSubscriptionShipped", $this);

                $.ajax(
                    {
                        url: '/admin/orders/subscription/shipping/bulk',
                        type: 'POST',
                        data: {'order_ids': [orderId]},
                        dataType: 'json',
                        success: function (data) {
                            seller_admin.base.addFlash("success shipped-flash", "Your order has been marked as shipped.");
                            seller_admin.base.disableLoadingModal("#loadingMarkSingleSubscriptionShipped", $this);
                            seller_admin.base.scrollTop("main");
                            $('#mark-subscription-as-shipped').remove();
                            $('#order-shipping-status').text('Completed');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.addFlash("warning shipped-flash", "Unable to mark your order as shipped. Please try again.");
                            seller_admin.base.disableLoadingModal("#loadingMarkSingleSubscriptionShipped", $this);
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        markCollectionAsShipped: function () {
            $('#mark-subscriptions-as-shipped').on('click', function () {
                var $this = $(this);
                $('.shipped-flash').remove();

                seller_admin.base.assignLoadingModal("loadingMarkSubscriptionsShipped", "Marking your orders as shipped...");
                seller_admin.base.enableLoadingModal("#loadingMarkSubscriptionsShipped", $this);

                var orderIds = [];
                $.each($(".order_checkbox:checked"), function () {
                    orderIds.push($(this).val());
                });
                if (orderIds.length === 0) {
                    seller_admin.base.addFlash("warning shipped-flash", "You need to select some orders before marking them as shipped.");
                    seller_admin.base.disableLoadingModal("#loadingMarkSubscriptionsShipped", $this);
                    seller_admin.base.scrollTop("main");
                } else {
                    $.ajax(
                        {
                            url: '/admin/orders/subscription/shipping/bulk',
                            type: 'POST',
                            data: {'order_ids': orderIds},
                            dataType: 'json',
                            success: function (data) {
                                if (data.order_ids !== undefined) {
                                    for (_i = 0, _len = data.order_ids.length; _i < _len; _i++) {
                                        value = data.order_ids[_i]
                                        $('tr.order_' + value + ' td.shipping-status').text('Completed');
                                    }
                                }
                                seller_admin.base.addFlash("success shipped-flash", "Your orders have been marked as shipped.");
                                seller_admin.base.disableLoadingModal("#loadingMarkSubscriptionsShipped", $this);
                                seller_admin.base.scrollTop("main");
                                $('.order_checkbox').prop('checked', false);
                                $('#check_all_orders').prop('checked', false);
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.addFlash("warning shipped-flash", "Unable to mark your orders as shipped. Please try again.");
                                seller_admin.base.disableLoadingModal("#loadingMarkSubscriptionsShipped", $this);
                                seller_admin.base.scrollTop("main");
                            }
                        });
                }
                return false;
            });
        },

        pickedUpOrder: function () {
            $('body').on('click', '#prompt-picked-up-order', function () {
                var orderId = $(this).attr('data-record-id');

                seller_admin.base.assignConfirmationModal('picked-up-order',
                    'Are you sure you wish to mark this order with a successful picked up status?', orderId);
                seller_admin.base.enableConfirmationModal();
                return false;
            });

            $('body').on('click', '.picked-up-order', function () {
                var $this = $(this),
                    orderId = $this.attr('data-record-id');

                seller_admin.base.disableConfirmationModal();
                seller_admin.base.assignLoadingModal("loadingOrderPickedUp", "Marking order with successful pick up...");
                seller_admin.base.enableLoadingModal("#loadingOrderPickedUp", $this);

                $.ajax(
                    {
                        url: '/admin/orders/picked_up/' + orderId,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('#pick-up-status').text('Completed');
                            seller_admin.base.addFlash('success', 'Successfully marked the order with a successful picked up status.');
                            seller_admin.base.disableLoadingModal("#loadingOrderPickedUp", $this);
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingOrderPickedUp", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }

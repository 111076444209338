seller_admin.pick_up_time = {

    add: function (link, association, content) {
        var new_id = new Date().getTime(),
            regexp = new RegExp("new_" + association, "g");
        $('#pick-up-times').append(content.replace(regexp, new_id));
        var selectTags = $('div[data-fields-id="' + new_id + '"] select');
        selectTags.materialSelect()
        selectTags.each(function () {
            $(this).click(e => e.stopPropagation());
        });
    },

    remove: function (link) {
        $(link).prev("input[type=hidden]").val("1");
        $(link).closest(".fields").hide();
    }
}
seller_admin.pause_option = {

    archive: function () {
        $('body').on('click', '.archive-pause-option', function () {
            var $this = $(this),
                url = $this.attr('href'),
                pauseOptionId = $this.attr('data-pause-option-id');

            seller_admin.base.assignLoadingModal("loadingArchivePauseOption", "Disabling your pause option...");
            seller_admin.base.enableLoadingModal("#loadingArchivePauseOption", $this);

            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    dataType: 'json',
                    success: function (data) {
                        $('tr.pause-option-' + pauseOptionId).replaceWith(data.row);
                        seller_admin.base.disableLoadingModal("#loadingArchivePauseOption", $this);
                        seller_admin.base.addFlash("success", "Successfully disabled a pause option.");
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingArchivePauseOption", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    unarchive: function () {
        $('body').on('click', '.unarchive-pause-option', function () {
            var $this = $(this),
                url = $this.attr('href'),
                pauseOptionId = $this.attr('data-pause-option-id');

            seller_admin.base.assignLoadingModal("loadingUarchivePauseOption", "Enabling your pause option...");
            seller_admin.base.enableLoadingModal("#loadingUarchivePauseOption", $this);

            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    dataType: 'json',
                    success: function (data) {
                        $('tr.pause-option-' + pauseOptionId).replaceWith(data.row);
                        seller_admin.base.disableLoadingModal("#loadingUarchivePauseOption", $this);
                        seller_admin.base.addFlash("success", "Successfully enabled a pause option.");
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingUarchivePauseOption", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    }
}
seller_admin.inventory =
    {
        newStock: function () {
            $('body').on('click', '#add-stock-inventory', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingUpdateInventory", "Updating inventory...");
                seller_admin.base.enableLoadingModal("#loadingUpdateInventory", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#add-stock-modal').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingUpdateInventory", $this);
                            seller_admin.base.openModal('#stock-movement-form');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUpdateInventory", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        createStock: function () {
            $('body').on('submit', '#create-stock-movement', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingUpdateInventory", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#stock-movement-form').modal('hide');
                            $('#stock-table tbody tr td:last-child').removeClass('bg-success white-text');
                            $('#stock-table tbody').prepend(data.row);
                            seller_admin.base.disableLoadingModal("#loadingUpdateInventory", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingUpdateInventory", $this);
                        }
                    });
                return false;
            });
        }
    }
seller_admin.pick_up_location = {
    add: function () {
        $('body').on('click', '.create-pick-up-location', function () {
            var $this = $(this),
                url = $this.attr('href');

            seller_admin.base.assignLoadingModal("loadingAddPickUpLocation", "Creating pickup location...");
            seller_admin.base.enableLoadingModal("#loadingAddPickUpLocation", $this);

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#pick-up-location-modal').html(data.modal);
                        seller_admin.base.openModal('#pick-up-location-form');
                        $('#pick-up-location-modal .mdb-select').materialSelect();
                        $('#pick-up-location-modal .mdb-select').click(e => e.stopPropagation());
                        seller_admin.base.disableLoadingModal("#loadingAddPickUpLocation", $this);
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingAddPickUpLocation", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    create: function () {
        $('body').on('submit', '#create-pick-up-location', function () {
            var $this = $(this),
                url = $this.attr('action');

            seller_admin.base.enableLoadingModal("#loadingAddPickUpLocation", $this);

            $.ajax(
                {
                    url: url,
                    type: 'POST',
                    data: $(this).serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $('#pick-up-location-form').modal('hide');
                        $('#pick-up-locations-table tbody').prepend(data.row);
                        seller_admin.base.addCustomFlash('#pick-up-shipping-section', 'success', 'Successfully added a pickup location to your store.');
                        seller_admin.base.disableLoadingModal("#loadingAddPickUpLocation", $this);
                        seller_admin.base.scrollTop("#pick-up-shipping-section");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.jsonErrors(xhr, evt, status, $this);
                        seller_admin.base.disableLoadingModal("#loadingAddPickUpLocation", $this);
                    }
                });
            return false;
        });
    },

    edit: function () {
        $('body').on('click', '.edit-pick-up-location', function () {
            var $this = $(this),
                url = $this.attr('href');

            seller_admin.base.assignLoadingModal("loadingEditPickUpLocation", "Updating pickup location...");
            seller_admin.base.enableLoadingModal("#loadingEditPickUpLocation", $this);


            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#pick-up-location-modal').html(data.modal);
                        seller_admin.base.openModal('#pick-up-location-form');
                        $('#pick-up-location-modal .mdb-select').materialSelect();
                        $('#pick-up-location-modal .mdb-select').click(e => e.stopPropagation());
                        seller_admin.base.disableLoadingModal("#loadingEditPickUpLocation", $this);
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingEditPickUpLocation", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    update: function () {
        $('body').on('submit', '#edit-pick-up-location', function () {
            var $this = $(this),
                url = $this.attr('action');

            seller_admin.base.enableLoadingModal("#loadingEditPickUpLocation", $this);

            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    data: $(this).serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $('#pick-up-location-form').modal('hide');
                        $('#pick-up-locations-table tbody tr#pick-up-location-' + data.pick_up_location_id).replaceWith(data.row);
                        seller_admin.base.addCustomFlash('#pick-up-shipping-section', 'success', 'Successfully updated a pickup location in your store.');
                        seller_admin.base.disableLoadingModal("#loadingEditPickUpLocation", $this);
                        seller_admin.base.scrollTop("#pick-up-shipping-section");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.jsonErrors(xhr, evt, status, $this);
                        seller_admin.base.disableLoadingModal("#loadingEditPickUpLocation", $this);
                    }
                });
            return false;
        });
    },

    initSortable: function () {
        $('#sortable-pick-up-location-list').sortable({
            placeholder: "ui-state-highlight",
            forcePlaceholderSize: true,
            tolerance: "pointer",
            update: function (e, ui) {
                seller_admin.base.enableLoadingModal("#loadingSortPickUpLocation", $('#sort-pick-up-locations-form'));
                $.ajax(
                    {
                        url: $(this).data("url"),
                        type: "PATCH",
                        data: $(this).sortable('serialize'),
                        success: function (data) {
                            $('#pick-up-locations-table tbody').html(data.table);
                            seller_admin.base.disableLoadingModal("#loadingSortPickUpLocation", $('#sort-pick-up-locations-form'));
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingSortPickUpLocation", $('#sort-pick-up-locations-form'));
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
            }
        });
        $("#sortable-pick-up-location-list").disableSelection();
    },

    sortModal: function () {
        $('body').on('click', '#reorder-pick-up-locations', function () {
            var url = $(this).attr('href');

            seller_admin.base.assignLoadingModal("loadingSortPickUpLocation", "Sorting your pickup locations...");
            seller_admin.base.enableLoadingModal("#loadingSortPickUpLocation", $('#sort-pick-up-locations-form'));

            $.ajax(
                {
                    url: url,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#sort-pick-up-locations-wrapper').html(data.modal);
                        seller_admin.base.disableLoadingModal("#loadingSortPickUpLocation", $('#sort-pick-up-locations-form'));
                        seller_admin.base.openModal('#sort-pick-up-locations-form');
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingSortPickUpLocation", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    remove: function()
    {
        $('body').on('click', '.prompt-remove-pick-up-location', function() {
            var pickUpLocationId = $(this).attr('data-record-id');

            seller_admin.base.assignConfirmationModal('remove-pick-up-location',
                'Are you sure you wish to remove a pick up location?', pickUpLocationId);
            seller_admin.base.enableConfirmationModal();
            return false;
        });
        $('body').on('click', '.remove-pick-up-location', function () {
            var $this = $(this),
                pickUpLocationId = $this.attr('data-record-id');

            seller_admin.base.disableConfirmationModal();
            seller_admin.base.assignLoadingModal("loadingRemovePickUpLocation", "Removing pickup location from your store...");
            seller_admin.base.enableLoadingModal("#loadingRemovePickUpLocation", $this);

            $.ajax(
                {
                    url: '/admin/settings/pick_ups/locations/' + pickUpLocationId,
                    type: 'DELETE',
                    dataType: 'json',
                    success: function (data) {
                        $('tr#pick-up-location-' + pickUpLocationId).remove();
                        seller_admin.base.addCustomFlash('#increase-trust-section','success', 'Successfully removed a pickup location from your store.');
                        seller_admin.base.disableLoadingModal("#loadingRemovePickUpLocation", $this);
                        seller_admin.base.scrollTop("#pick-up-shipping-section");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingRemovePickUpLocation", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    }
}
seller_admin.setting = {

    copyCnameRecord: function()
    {
        new ClipboardJS('#copy-cname-record');
    },

    changeDefaultShippingCountry: function()
    {
        $('#store_default_shipping_country').change(function () {
            if ($(this).val() === 'enabled') {
                $('#default-shipping-country-wrapper').show();
            } else {
                $('#default-shipping-country-wrapper').hide();
            }
        });
    }
}
seller_admin.template =
    {
        initTemplateCategoryFilter: function () {
            var $grid = $('#template-grid.grid').isotope({
            });
            $grid.imagesLoaded().progress( function() {
                $grid.isotope('layout');
            });
            $('.template-category-group').on('click', 'button', function () {
                var filterValue = $(this).attr('data-filter');
                $grid.isotope({filter: filterValue});
                return false;
            });
        },

        previewModal: function()
        {
            $('body').on('click', '.preview-template-button', function () {
                var $this = $(this),
                    templateId = $this.attr('data-template-id');

                seller_admin.base.assignLoadingModal("loadingPreviewTemplate", "Loading preview template...");
                seller_admin.base.enableLoadingModal("#loadingPreviewTemplate", $this);

                $.ajax(
                    {
                        url: '/admin/templates/' + templateId + '/preview_modal',
                        type: 'GET',
                        dataType: 'json',
                        success: function (data) {
                            $('#preview-template-wrapper').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingPreviewTemplate", $this);
                            seller_admin.base.openModal('#preview-template-modal');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingPreviewTemplate", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }
seller_admin.page_designer =
    {

        prepareContent: function () {
            $('body').on('click', '#save-content', function () {
                $('#design-image-progress .progress-bar').css('width', '100%');
                seller_admin.page_designer.uploadBase64Images();
                return false;
            });
            window.addEventListener('load', function () {
                seller_admin.page_designer.enableNavbarActions();
            });
            setTimeout(function () {
                seller_admin.page_designer.enableNavbarActions();
            }, 10000);
        },

        enableNavbarActions: function () {
            $('#publish-page-designer').removeClass('disabled');
            $('.exit-page-designer').removeClass('disabled');
            $('#btnPreview').removeClass('disabled');
            $('#save-content').removeClass('disabled');
        },

        clearContent: function()
        {
            $('#clear-content-designer').on('click', function()
            {
                seller_admin.base.openModal('#clearContentModal');
            });
            $('#clear-design-trigger').on('click', function()
            {
                var url = $(this).attr('href');
                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            window.location = data.redirect_url;
                        },
                    });
                return false;
            });
        },

        versionMismatchModal: function(version_mismatch) {
            if (version_mismatch === true)
            {
                seller_admin.base.openModal('#versionMismatchModal');
            }
        },

        uploadCoverImageToCloudinary: function (e) {
            const selectedFile = e.target.files[0],
                cloudinaryEnv = $('meta[name=cloudinary-env]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                cloudName = $('meta[name=cloudinary-cloud-name]').attr('content'),
                uploadPreset = $('meta[name=cloudinary-upload-preset]').attr('content');
            $(e.target).unsigned_cloudinary_upload(uploadPreset,
                {
                    cloud_name: cloudName,
                    folder: cloudinaryEnv + '/stores/' + storeId + '/designs'
                }
            ).cloudinary_upload_url(selectedFile)
                .on('cloudinarydone', function (e, data) {
                        var url = data.result.secure_url;
                        var surl = url.split('/upload/');
                        var furl = surl[0] + '/upload/fl_lossy/q_auto/' + surl[1];
                        window.contentBuilder.boxImage(furl);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                    }
                ).on('cloudinaryprogress', function (e, data) {
                $('#design-image-progress .progress-bar').css('width',
                    Math.round((data.loaded * 100.0) / data.total) + '%');

            });
        },

        uploadImageToCloudinary: function (img) {
            console.log('hello');
            const cloudinaryEnv = $('meta[name=cloudinary-env]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                cloudName = $('meta[name=cloudinary-cloud-name]').attr('content'),
                uploadPreset = $('meta[name=cloudinary-upload-preset]').attr('content'),
                imgSrc = img.src;
            $(img).unsigned_cloudinary_upload(uploadPreset,
                {
                    cloud_name: cloudName,
                    folder: cloudinaryEnv + '/stores/' + storeId + '/designs'
                }
            ).cloudinary_upload_url(imgSrc)
                .on('cloudinarydone', function (e, data) {
                        var url = data.result.secure_url;
                        var surl = url.split('/upload/');
                        var furl = surl[0] + '/upload/fl_lossy/q_auto/' + surl[1];
                        $(img).attr('src', furl);
                        $(img).attr('data-cloudinary-public-id', data.result.public_id);
                    }
                ).on('cloudinaryprogress', function (e, data) {
                $('#design-image-progress .progress-bar').css('width',
                    Math.round((data.loaded * 100.0) / data.total) + '%');

            });
        },

        uploadBase64Images: function () {
            window.contentBuilder.saveImages('', () => {
                eval(window.saveContentFunction);
            }, (img, base64, filename) => {
                seller_admin.page_designer.uploadImageToCloudinary(img);
            });
        },
    }
seller_admin.api = {
    revealAuthToken: function () {
        $('.reveal-api-token').on('click', function () {
            $('#store-api-token').removeClass('blur');
            $('.hide-auth-token').show();
            $('.reveal-auth-token').hide();
            return false;
        });
    },

    hideAuthToken: function () {
        $('.hide-api-token').on('click', function () {
            $('#store-api-token').addClass('blur');
            $('.reveal-auth-token').show();
            $('.hide-auth-token').hide();
            return false;
        });
    },
}
seller_admin.review_email =
    {
        publish: function () {
            $('body').on('click', '.publish-review-email', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    reviewEmailId = $this.attr('data-review-email-id');

                seller_admin.base.assignLoadingModal("loadingPublishReviewEmail", "Publishing your review email template...");
                seller_admin.base.enableLoadingModal("#loadingPublishReviewEmail", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('tr.review-email-' + reviewEmailId).replaceWith(data.row);
                            seller_admin.base.disableLoadingModal("#loadingPublishReviewEmail", $this);
                            seller_admin.base.addFlash("success", "Successfully published an review email template.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingPublishReviewEmail", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        unpublish: function () {
            $('body').on('click', '.unpublish-review-email', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    reviewEmailId = $this.attr('data-review-email-id');

                seller_admin.base.assignLoadingModal("loadingUnpublishReviewEmail", "Unpublishing your review email template...");
                seller_admin.base.enableLoadingModal("#loadingUnpublishReviewEmail", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('tr.review-email-' + reviewEmailId).replaceWith(data.row);
                            seller_admin.base.disableLoadingModal("#loadingUnpublishReviewEmail", $this);
                            seller_admin.base.addFlash("success", "Successfully unpublished an review email template.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUnpublishReviewEmail", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        sendTestEmailModal: function () {
            $('body').on('click', '.send-review-test-email-modal', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingTestReviewEmail", "Sending test review email...");
                seller_admin.base.enableLoadingModal("#loadingTestReviewEmail", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#send-test-email-wrapper').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingTestReviewEmail", $this);
                            seller_admin.base.openModal('#test-review-email-form');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingTestReviewEmail", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        sendTestEmail: function () {
            $('body').on('submit', '#send-test-review-email', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingTestReviewEmail", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#test-review-email-form').modal('hide');
                            seller_admin.base.disableLoadingModal("#loadingTestReviewEmail", $this);
                            seller_admin.base.addFlash("success", "Successfully sent a test email for " + data.email_template.subject + ".");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            $('#test-review-email-form #errors ul').html("<li><i class='fa fa-times-circle'></i>Invalid email, please try again.</li>");
                            $('#test-review-email-form .json-error-wrapper').show();
                            seller_admin.base.disableLoadingModal("#loadingTestReviewEmail", $this);
                        }
                    });
                return false;
            });
        }
    }
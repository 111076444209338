seller_admin.app_integration =
    {
        initAppCategoryFilter: function () {
            var $grid = $('#app-integration.grid').isotope({});
            $grid.imagesLoaded().progress( function() {
                $grid.isotope('layout');
            });
            $('.app-integration-category-group').on('click', 'li', function () {
                var filterValue = $(this).attr('data-filter');
                $grid.isotope({filter: filterValue});
                return false;
            });
        },
    }
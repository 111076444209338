seller_admin.free_gift = {

    addRule: function (link, association, content) {
        var new_id = new Date().getTime(),
            regexp = new RegExp("new_" + association, "g");
        $("#free-gift-rules").append(content.replace(regexp, new_id));
        var selectTags = $('div[data-fields-id="' + new_id + '"] select');
        selectTags.materialSelect();
        selectTags.each(function () {
            $(this).click(e => e.stopPropagation());
        });
    },

    removeRule: function (link) {
        $(link).prev("input[type=hidden]").val("1");
        $(link).closest(".fields").hide();
    },

    changeFilterType: function () {
        $('body').on('change', 'select.free-gift-rule-filter-type', function () {
            var $this = $(this),
                parentWrapper = $this.parent().parent().parent();
            seller_admin.free_gift.updateConditionSelect(parentWrapper, this.value);
            if (this.value === 'cart_item') {
                parentWrapper.find('.free-gift-rule-filter-value-wrapper').show();
                parentWrapper.find('.free-gift-rule-filter-value-wrapper div label').text('Quantity');
                parentWrapper.find('.free-gift-rule-product-id-wrapper').show();
            } else if (this.value === 'cart_subtotal') {
                parentWrapper.find('.free-gift-rule-filter-value-wrapper').show();
                parentWrapper.find('.free-gift-rule-filter-value-wrapper div label').text('Cart value');
                parentWrapper.find('.free-gift-rule-product-id-wrapper').hide();
            }
        });
    },

    updateConditionSelect: function (parentWrapper, selectValue) {
        var conditions = window.free_gift_rule_conditions[selectValue],
            selectElem = parentWrapper.find('select.free-gift-rule-condition');
        selectElem.empty();
        for (var i = 0; i < conditions.length; i++) {
            selectElem.append('<option value="' + conditions[i][0] + '">' + conditions[i][1] + '</option>');
        }
    },

    updateVariantSelect: function(productId)
    {
        var $this = $(this),
            accessToken = $('meta[name=api-access-token]').attr('content'),
            clientId = $('meta[name=api-client]').attr('content'),
            storeId = $('meta[name=store-id]').attr('content'),
            uid = $('meta[name=api-uid]').attr('content'),
            selectElemVariant = $('#free_gift_variant_id');

        $.ajax(
            {
                url: window.baseApiUrl + '/v1/store/products/' + productId + '/variants',
                headers: {
                    'access-token': accessToken,
                    'client': clientId,
                    'uid': uid,
                    'store-id': storeId
                },
                type: "GET",
                dataType: "json",
                success: function (data) {
                    selectElemVariant.empty();
                    for (var i = 0; i < data.variants.length; i++) {
                        var skuName = data.variants[i]['sku'],
                            skuName = skuName === 'MA' ? 'MA (Master)' : skuName;
                        selectElemVariant.append('<option value="' + data.variants[i]['uid'] + '">' + skuName + '</option>');
                    }
                },
                error: function (xhr, evt, status) {
                }
            });
    },

    selectProduct: function()
    {
        $('body').on('change', '#free_gift_product_id', function() {
            seller_admin.free_gift.updateVariantSelect($(this).val());
        });
    },

    archive: function () {
        $('body').on('click', '.archive-free-gift', function () {
            var $this = $(this),
                url = $this.attr('href'),
                freeGiftId = $this.attr('data-free-gift-id'),
                freeGiftSearchTable = $('table#free-gift-search-table'),
                freeGiftRow = $('tr.free_gift-' + freeGiftId);

            seller_admin.base.assignLoadingModal("loadingArchiveFreeGift", "Archiving your free gift...");
            seller_admin.base.enableLoadingModal("#loadingArchiveFreeGift", $this);

            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    dataType: 'json',
                    success: function (data) {
                        if (freeGiftSearchTable.length > 0) {
                            freeGiftRow.replaceWith(data.row);
                        } else {
                            freeGiftRow.remove();
                        }
                        seller_admin.base.disableLoadingModal("#loadingArchiveFreeGift", $this);
                        seller_admin.base.addFlash("success", "Successfully archived a free gift.");
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingArchiveFreeGift", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    unarchive: function () {
        $('body').on('click', '.unarchive-free-gift', function () {
            var url = $(this).attr('href'),
                $this = $(this),
                freeGiftId = $this.attr('data-free-gift-id'),
                freeGiftSearchTable = $('table#free-gift-search-table'),
                freeGiftRow = $('tr.free_gift-' + freeGiftId);

            seller_admin.base.assignLoadingModal("loadingUnarchiveFreeGift", "Unarchiving your free gift...");
            seller_admin.base.enableLoadingModal("#loadingUnarchiveFreeGift", $this);

            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    dataType: 'json',
                    success: function (data) {
                        if (freeGiftSearchTable.length > 0) {
                            freeGiftRow.replaceWith(data.row);
                        } else {
                            freeGiftRow.remove();
                        }
                        seller_admin.base.disableLoadingModal("#loadingUnarchiveFreeGift", $this);
                        seller_admin.base.addFlash("success", "Successfully unarchived a free gift.");
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingUnarchiveFreeGift", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },

    remove: function () {
        $('body').on('click', '.prompt-remove-free-gift', function() {
            var freeGiftId = $(this).attr('data-record-id');

            seller_admin.base.assignConfirmationModal('remove-free-gift',
                'Are you sure you wish to remove your free gift?', freeGiftId);
            seller_admin.base.enableConfirmationModal();
            return false;
        });
        $('body').on('click', '.remove-free-gift', function () {
            var $this = $(this),
                freeGiftId = $this.attr('data-record-id'),
                freeGiftRow = $('tr.free_gift-' + freeGiftId);

            seller_admin.base.disableConfirmationModal();
            seller_admin.base.assignLoadingModal("loadingRemoveFreeGift", "Removing your free gift...");
            seller_admin.base.enableLoadingModal("#loadingRemoveFreeGift", $this);

            $.ajax(
                {
                    url: '/admin/free_gifts/' + freeGiftId,
                    type: 'DELETE',
                    dataType: 'json',
                    success: function (data) {
                        freeGiftRow.remove();
                        seller_admin.base.disableLoadingModal("#loadingRemoveFreeGift", $this);
                        seller_admin.base.addFlash("success", "Successfully removed a free gift.");
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingRemoveFreeGift", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    }
}
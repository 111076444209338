seller_admin.onboarding =
    {
        changePlan: function () {
            $("body").on("submit", '#change-onboarding-plan', function () {
                var url = $(this).attr('action'),
                    $this = $(this);
                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            window.location.reload();
                        },
                        error: function (xhr, evt, status) {
                            $('#change-onboarding-plan #errors').html("<li><i class='fa fa-times-circle'></i>Plan does not exist</li>");
                        }
                    });
                return false;
            });
        },

        termsDisablePlaceOrder: function () {
            $('#onboardingTerms').change(function () {
                if (this.checked) {
                    $('#place-order-button').removeAttr('disabled');
                } else {
                    $('#place-order-button').attr('disabled', 'true');
                }
            });
        },

        pollStoreSetupValidation: function () {
            var storeSetupPollingInterval = window.setInterval(function () {
                $.ajax(
                    {
                        url: '/store/validate_setup',
                        type: 'GET',
                        dataType: 'json',
                        success: function (data) {
                            window.clearInterval(storeSetupPollingInterval);
                            window.redirectUrl = data.redirect_url;
                        }
                    });
            }, 3000);
        },

        storeSetupVerticalAlign: function () {
            $(function () {
                $(window).on('resize', function resize() {
                    $(window).off('resize', resize);
                    setTimeout(function () {
                        var content = $('#store-setup-content'),
                            top = (window.innerHeight - content.height()) / 2;
                        content.css('top', Math.max(0, top) + 'px');
                        $(window).on('resize', resize);
                    }, 50);
                }).resize();
            });
        },

        storeSetupAnimations: function () {
            window.stepsCompleted = false;
            var stepElem = $('#store-setup-step');

            setTimeout(function () {
                stepElem.text('2 of 3: Initializing your store');
                setTimeout(function () {
                    stepElem.text('3 of 3: Applying store settings');
                    window.stepsCompleted = true;
                }, 5000);
            }, 5000);
        },

        storeSetupRedirect: function () {
            var redirectInterval = window.setInterval(function () {
                var validUrl = window.redirectUrl !== undefined && seller_admin.base.isValidURL(window.redirectUrl);
                if (validUrl && window.stepsCompleted === true) {
                    window.clearInterval(redirectInterval);
                    window.location = window.redirectUrl;
                }
            }, 1000);
        }
    }
$(document).ready(function () {
    var $formElem = $('#process-invoice-payment');
    if ($formElem.length > 0) {
        var stripe = Stripe($('meta[name="stripe-key"]').attr('content'));
        var elements = stripe.elements();
        var style = {
            base: {
                color: '#32325d',
                lineHeight: '24px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };
        var card = elements.create('card', {style: style, hidePostalCode: true});
        card.mount('#card-element');
        card.addEventListener('change', function (event) {
            if (event.error) {
                $('#card-errors').text(event.error.message)
            } else {
                $('#card-errors').text('')
            }
        });


        $('body').on('submit', '#process-invoice-payment', function () {
            var countryId = $('#country').val();
            $.ajax(
                {
                    url: '/countries/' + countryId,
                    type: 'GET',
                    dataType: 'json',
                    success: function (data) {
                        $('#invoice-validation-warning').remove();
                        $('input[type=submit]').attr('disabled', true);
                        var billing_details = {
                            name: document.getElementById('first_name').value + ' ' + document.getElementById('last_name').value,
                            address: {
                                line1: document.getElementById('address').value,
                                city: document.getElementById('city').value,
                                state: document.getElementById('county').value,
                                postal_code: document.getElementById('zip').value,
                                country: data.alpha_2
                            }
                        };
                        stripe.createPaymentMethod('card', card, {billing_details}).then(function (result) {
                            if (result.error) {
                                $('#card-errors').text(result.error.message);
                                $('input[type=submit]').attr('disabled', false);
                            } else {
                                $('#loadingInvoiceComplete').modal('show');
                                submitInvoiceForm(result);
                            }
                        });
                    }
                });
            return false;
        });
    }
});

function submitInvoiceForm(result) {
    var $form = $('#process-invoice-payment'),
        invoiceToken = $('#invoice-header').attr('data-token');
    $.ajax(
        {
            url: '/pending/' + invoiceToken + '/confirm',
            type: 'PATCH',
            data: $form.serialize(),
            dataType: 'json',
            success: function (data) {
                $.ajax(
                    {
                        url: '/pending/' + invoiceToken + '/confirm_payment',
                        type: 'POST',
                        data: {payment_method_id: result.paymentMethod.id},
                        dataType: 'json',
                        success: function (data) {
                            handleInvoiceServerResponse(data, result.paymentMethod.id)
                        }
                    });
            },
            error: function (xhr, evt, status) {
                console.log($.parseJSON(xhr.responseText).errors);
                $('#loadingInvoiceComplete').modal('hide');
                $('<div class="mt-4 alert alert-danger" role="alert" id="invoice-validation-warning">Your payment was unsuccessful: ' + $.parseJSON(xhr.responseText).errors.join(",") + '. Please make sure you have filled out all required fields in the form.</div>').insertBefore('#onboarding-section');
            }
        });
}

function handleInvoiceServerResponse(response, paymentMethodId) {
    var stripe = Stripe($('meta[name="stripe-key"]').attr('content')),
        invoiceToken = $('#invoice-header').attr('data-token');
    if (response.requires_action) {
        stripe.handleCardAction(
            response.payment_intent_client_secret
        ).then(function (result) {
            if (result.error) {
                failed3dSecureShop(result.error.payment_intent.id, paymentMethodId, invoiceToken);
            } else {
                $.ajax(
                    {
                        url: '/' + funnelSlug + '/confirm_payment',
                        type: 'POST',
                        data: {payment_intent_id: result.paymentIntent.id},
                        dataType: 'json',
                        success: function (data) {
                            handleInvoiceServerResponse(data, paymentMethodId);
                        }
                    });
            }
        });
    } else {
        submitFinalForm(paymentMethodId);
    }
}

function failed3dSecureShop(intentId, paymentMethodId, invoiceToken) {
    $.ajax(
        {
            url: '/pending/' + invoiceToken + '/failed_3d_secure',
            type: 'POST',
            data: {payment_intent_id: intentId},
            dataType: 'json',
            success: function (data) {
                submitFinalForm(paymentMethodId);
            }
        });
}

function submitFinalForm(paymentMethodId) {
    $('#invoice-payment-method-id').val(paymentMethodId);
    $('#process-invoice-payment')[0].submit();
}
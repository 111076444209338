seller_admin.checkout_trust =
    {
        add: function () {
            $('body').on('click', '.create-checkout-trust', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingAddCheckoutTrust", "Creating checkout trust...");
                seller_admin.base.enableLoadingModal("#loadingAddCheckoutTrust", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#checkout-trust-modal').html(data.modal);
                            seller_admin.base.openModal('#checkout-trust-form');
                            $('#checkout-trust-modal .mdb-select').materialSelect();
                            $('#checkout-trust-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingAddCheckoutTrust", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingAddCheckoutTrust", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        create: function () {
            $('body').on('submit', '#create-checkout-trust', function () {
                var $this = $(this),
                    url = $this.attr('action'),
                    formData = new FormData(),
                    serializedData = seller_admin.base.getSerializedUrlVars($(this).serialize());

                seller_admin.base.enableLoadingModal("#loadingAddCheckoutTrust", $this);

                formData.append('checkout_trust[headline]', serializedData['checkout_trust[headline]'].replace(/\+/g, ' '));
                formData.append('checkout_trust[body]', serializedData['checkout_trust[body]'].replace(/\+/g, ' '));
                formData.append('checkout_trust[image]', $('#checkout_trust_image')[0].files[0]);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: formData,
                        cache: false,
                        processData: false,
                        contentType: false,
                        success: function (data) {
                            $('#checkout-trust-form').modal('hide');
                            $('#checkout-trusts-table tbody').prepend(data.row);
                            seller_admin.base.addCustomFlash('#increase-trust-section', 'success', 'Successfully added a checkout trust to your store.');
                            seller_admin.base.disableLoadingModal("#loadingAddCheckoutTrust", $this);
                            seller_admin.base.scrollTop("#increase-trust-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingAddCheckoutTrust", $this);
                        }
                    });
                return false;
            });
        },

        edit: function () {
            $('body').on('click', '.edit-checkout-trust', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingEditCheckoutTrust", "Updating checkout trust...");
                seller_admin.base.enableLoadingModal("#loadingEditCheckoutTrust", $this);


                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#checkout-trust-modal').html(data.modal);
                            seller_admin.base.openModal('#checkout-trust-form');
                            $('#checkout-trust-modal .mdb-select').materialSelect();
                            $('#checkout-trust-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingEditCheckoutTrust", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingEditCheckoutTrust", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        update: function () {
            $('body').on('submit', '#edit-checkout-trust', function () {
                var $this = $(this),
                    url = $this.attr('action'),
                    formData = new FormData(),
                    serializedData = seller_admin.base.getSerializedUrlVars($(this).serialize()),
                    checkoutTrustFile = $('#checkout_trust_image')[0].files[0];

                seller_admin.base.enableLoadingModal("#loadingEditCheckoutTrust", $this);

                formData.append('checkout_trust[headline]', serializedData['checkout_trust[headline]'].replace(/\+/g, ' '));
                formData.append('checkout_trust[body]', serializedData['checkout_trust[body]'].replace(/\+/g, ' '));
                if(checkoutTrustFile !== undefined) {
                    formData.append('checkout_trust[image]', checkoutTrustFile);
                }

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: formData,
                        cache: false,
                        processData: false,
                        contentType: false,
                        success: function (data) {
                            $('#checkout-trust-form').modal('hide');
                            $('#checkout-trusts-table tbody tr#checkout-trust-' + data.checkout_trust_id).replaceWith(data.row);
                            seller_admin.base.addCustomFlash('#increase-trust-section', 'success', 'Successfully updated a checkout trust to your store.');
                            seller_admin.base.disableLoadingModal("#loadingEditCheckoutTrust", $this);
                            seller_admin.base.scrollTop("#increase-trust-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingEditCheckoutTrust", $this);
                        }
                    });
                return false;
            });
        },

        initSortable: function () {
            $('#sortable-checkout-trust-list').sortable({
                placeholder: "ui-state-highlight",
                forcePlaceholderSize: true,
                tolerance: "pointer",
                update: function (e, ui) {
                    seller_admin.base.enableLoadingModal("#loadingSortCheckoutTrust", $('#sort-checkout-trusts-form'));
                    $.ajax(
                        {
                            url: $(this).data("url"),
                            type: "PATCH",
                            data: $(this).sortable('serialize'),
                            success: function (data) {
                                $('#checkout-trusts-table tbody').html(data.table);
                                seller_admin.base.disableLoadingModal("#loadingSortCheckoutTrust", $('#sort-checkout-trusts-form'));
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.disableLoadingModal("#loadingSortCheckoutTrust", $('#sort-checkout-trusts-form'));
                                seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                                seller_admin.base.scrollTop("main");
                            }
                        });
                }
            });
            $("#sortable-checkout-trust-list").disableSelection();
        },

        sortModal: function () {
            $('body').on('click', '#reorder-checkout-trusts', function () {
                var url = $(this).attr('href');

                seller_admin.base.assignLoadingModal("loadingSortCheckoutTrust", "Sorting your checkout trusts...");
                seller_admin.base.enableLoadingModal("#loadingSortCheckoutTrust", $('#sort-checkout-trusts-form'));

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#sort-checkout-trusts-wrapper').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingSortCheckoutTrust", $('#sort-checkout-trusts-form'));
                            seller_admin.base.openModal('#sort-checkout-trusts-form');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingSortCheckoutTrust", $('#sort-checkout-trusts-form'));
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        remove: function()
        {
            $('body').on('click', '.prompt-remove-checkout-trust', function() {
                var checkoutTrustId = $(this).attr('data-record-id');

                seller_admin.base.assignConfirmationModal('remove-checkout-trust',
                    'Are you sure you wish to remove a checkout trust?', checkoutTrustId);
                seller_admin.base.enableConfirmationModal();
                return false;
            });
            $('body').on('click', '.remove-checkout-trust', function () {
                var $this = $(this),
                    checkoutTrustId = $this.attr('data-record-id');

                seller_admin.base.disableConfirmationModal();
                seller_admin.base.assignLoadingModal("loadingRemoveCheckoutTrust", "Removing checkout trust from your store...");
                seller_admin.base.enableLoadingModal("#loadingRemoveCheckoutTrust", $this);

                $.ajax(
                    {
                        url: '/admin/settings/checkout_trusts/' + checkoutTrustId,
                        type: 'DELETE',
                        dataType: 'json',
                        success: function (data) {
                            $('tr#checkout-trust-' + checkoutTrustId).remove();
                            seller_admin.base.addCustomFlash('#increase-trust-section','success', 'Successfully removed a checkout trust from your store.');
                            seller_admin.base.disableLoadingModal("#loadingRemoveCheckoutTrust", $this);
                            seller_admin.base.scrollTop("#increase-trust-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingRemoveCheckoutTrust", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }
seller_admin.blog_post =
    {
        archive: function () {
            $('body').on('click', '.archive-blog-post', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    blogPostSearchTable = $('table#blog-post-search-table'),
                    blogPostId = $this.attr('data-blog-post-id'),
                    blogPostRow = $('tr.blog-post-' + blogPostId);

                seller_admin.base.assignLoadingModal("loadingArchiveBlogPost", "Archiving your blog post...");
                seller_admin.base.enableLoadingModal("#loadingArchiveBlogPost", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (blogPostSearchTable.length > 0) {
                                blogPostRow.replaceWith(data.row);
                            } else {
                                blogPostRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingArchiveBlogPost", $this);
                            seller_admin.base.addFlash("success", "Successfully archived a blog post.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingArchiveBlogPost", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        unarchive: function () {
            $('body').on('click', '.unarchive-blog-post', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    blogPostSearchTable = $('table#blog-post-search-table'),
                    blogPostId = $this.attr('data-blog-post-id'),
                    blogPostRow = $('tr.blog-post-' + blogPostId);

                seller_admin.base.assignLoadingModal("loadingUnarchiveBlogPost", "Unarchiving your blog post...");
                seller_admin.base.enableLoadingModal("#loadingUnarchiveBlogPost", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (blogPostSearchTable.length > 0) {
                                blogPostRow.replaceWith(data.row);
                            } else {
                                blogPostRow.remove();
                            }
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveBlogPost", $this);
                            seller_admin.base.addFlash("success", "Successfully unarchived a blog post.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveBlogPost", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        updateNameToSlug: function () {
            var nameInput = document.getElementById('seller_blog_post_title'),
                slugInput = document.getElementById('seller_blog_post_slug'),
                slugLabel = $('label[for="seller_blog_post_slug"]');

            var timeout = null;

            if (typeof (nameInput) != 'undefined' && nameInput != null) {
                nameInput.onkeyup = function (e) {

                    clearTimeout(timeout);

                    timeout = setTimeout(function () {
                        if (slugInput.value === "") {
                            slugInput.value = seller_admin.base.string_parameterize(nameInput.value);
                            slugLabel.addClass('active');
                        }
                    }, 2000);
                };
            }
        },

        formatSlug: function () {
            var slugInput = document.getElementById('seller_blog_post_slug');

            var timeout = null;

            if (typeof (slugInput) != 'undefined' && slugInput != null) {
                slugInput.onkeyup = function (e) {

                    clearTimeout(timeout);

                    timeout = setTimeout(function () {
                        slugInput.value = seller_admin.base.string_parameterize(slugInput.value);
                    }, 500);
                };
            }
        },

        bindBlogPostNotification: function (channel) {
            channel.bind('blog_post_publish_list', function (data) {
                var draftBlogPost = $('tbody#blog-posts-table tr.blog-post-' + data.draft_blog_post_id);
                if (draftBlogPost.length > 0) {
                    draftBlogPost.find('td.blog-post-publish-status').text(data.publish_status);
                }
            });
        },

        saveContent: function () {
            var $this = $(this),
                accessToken = $('meta[name=api-access-token]').attr('content'),
                clientId = $('meta[name=api-client]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                uid = $('meta[name=api-uid]').attr('content'),
                blogPostId = $('.is-wrapper').attr('data-blog-post-id'),
                wrapperContent = $('.is-wrapper'),
                html = window.contentBuilder.html();

            seller_admin.base.assignLoadingModal("loadingSaveBlogPostDesign", "Saving your blog post design...");
            seller_admin.base.enableLoadingModal("#loadingSaveBlogPostDesign", $this);

            $.ajax(
                {
                    url: window.baseApiUrl + '/v1/store/blog/posts/' + blogPostId,
                    headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                    type: 'PATCH',
                    data: {
                        'seller_blog_post': {'content': html},
                        'version_number': wrapperContent.attr('data-version')
                    },
                    dataType: 'json',
                    success: function (data) {
                        seller_admin.base.disableLoadingModal("#loadingSaveBlogPostDesign", $this);
                        wrapperContent.attr('data-version', data.version_number);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE SUCCESSFUL");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingSaveBlogPostDesign", $this);
                        seller_admin.base.addFlash("danger", "Failed to save blog post, please try again.");
                        seller_admin.base.scrollTop("main");
                        seller_admin.page_designer.versionMismatchModal(xhr.responseJSON.version_mismatch);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE FAILED");
                    }
                });
        },

        autoSaveContent: function () {
            var accessToken = $('meta[name=api-access-token]').attr('content'),
                clientId = $('meta[name=api-client]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                uid = $('meta[name=api-uid]').attr('content'),
                blogPostId = $('.is-wrapper').attr('data-blog-post-id'),
                wrapperContent = $('.is-wrapper'),
                html = window.contentBuilder.html();

            $('#design-image-progress .progress-bar').css('width', '100%');

            $.ajax(
                {
                    url: window.baseApiUrl + '/v1/store/blog/posts/' + blogPostId,
                    headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                    type: 'PATCH',
                    data: {
                        'seller_blog_post': {'content': html},
                        'version_number': wrapperContent.attr('data-version')
                    },
                    dataType: 'json',
                    success: function (data) {
                        seller_admin.base.successfulDesignerAutoSave();
                        wrapperContent.attr('data-version', data.version_number);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE SUCCESSFUL");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.failedDesignerAutoSave();
                        seller_admin.page_designer.versionMismatchModal(xhr.responseJSON.version_mismatch);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE FAILED");
                    }
                });
        },

        publish: function () {
            $('body').on('click', '.publish-blog-post', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingPublishBlogPost", "Publishing your blog post...");
                seller_admin.base.enableLoadingModal("#loadingPublishBlogPost", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        dataType: 'json',
                        success: function (data) {
                            seller_admin.base.disableLoadingModal("#loadingPublishBlogPost", $this);
                            seller_admin.base.addFlash("success", "Updates will appear live in a few moments.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingPublishBlogPost", $this);
                            seller_admin.base.addFlash("danger", "Failed to publish blog post, please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }
seller_admin.base =
    {
        openModal: function (target) {
            $(target).modal(
                {
                    backdrop: 'static',
                    keyboard: false
                });
        },

        jsonErrors: function (xhr, evt, status, form) {
            var content, value, _i, _len, _ref, $this, contentWrapper;
            $this = form;
            contentWrapper = $this.find('.json-error-wrapper');
            content = contentWrapper.find('#errors');
            content.find('ul').empty();
            _ref = $.parseJSON(xhr.responseText).errors;
            // Append errors to list on page
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                value = _ref[_i];
                content.show().find('ul').append('<li><i class="fa fa-times-circle"></i>' + value + '</li>');
            }
            contentWrapper.show();
        },

        customJsonErrors: function(error_messages, form)
        {
            var content, value, _i, _len, _ref, $this, contentWrapper;
            $this = form;
            contentWrapper = $this.find('.json-error-wrapper');
            content = contentWrapper.find('#errors');
            content.find('ul').empty();
            _ref = error_messages;
            // Append errors to list on page
            for (_i = 0, _len = _ref.length; _i < _len; _i++) {
                value = _ref[_i];
                content.show().find('ul').append('<li><i class="fa fa-times-circle"></i>' + value + '</li>');
            }
            contentWrapper.show();
        },

        initDragDropFileUpload: function (target_elem) {
            target_elem.file_upload();
        },

        initCharCounter: function (formElem, counterElem, count) {
            $(formElem).keyup(function () {
                $(counterElem).html("<small><i>Characters left: " + (count - $(this).val().length) + "</i></small>");
            });
        },

        initPusher: function () {
            var pusherKey = $('meta[name="pusher-key"]').attr('content'),
                pusherCluster = $('meta[name="pusher-cluster"]').attr('content');
            if (pusherKey != undefined && pusherCluster != undefined) {
                var pusher = new Pusher(pusherKey, {
                    cluster: pusherCluster,
                    forceTLS: true
                });
            }
            return pusher;
        },

        subscribeStoreChannel: function () {
            var pusher = seller_admin.base.initPusher();
            if (pusher != undefined) {
                var storeToken = $('meta[name="store-token"]').attr('content'),
                    channel = pusher.subscribe('store_' + storeToken + '_channel');
                pusher.connection.bind('connected', function () {
                    seller_admin.notification.bindNavbarNotifications(channel);
                    seller_admin.page.bindPublishNotification(channel);
                    seller_admin.member_post.bindPublishNotification(channel);
                    seller_admin.blog_post.bindBlogPostNotification(channel)
                });
            }
        },

        getUrlVars: function () {
            var vars = {};
            var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
                vars[key] = value;
            });
            return vars;
        },

        getSerializedUrlVars: function (queryHash) {
            var queryHash = (queryHash == null ? window.location.href.slice(window.location.href.indexOf('?') + 1) : decodeURIComponent(queryHash))
            var vars = [], hash;
            var hashes = queryHash.split('&');
            for (var i = 0; i < hashes.length; i++) {
                hash = hashes[i].split('=');
                vars.push(hash[0]);
                vars[hash[0]] = hash[1];
            }
            return vars;
        },

        updateQueryStringParameter: function (uri, key, value) {
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf('?') !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2');
            } else {
                return uri + separator + key + "=" + value;
            }
        },

        datePicker: function () {
            var dateFormat = $('meta[name=date-format]').attr('content');

            $('.datepicker').pickadate({
                clear: 'Clear selection',
                close: 'Cancel',
                format: dateFormat,
                formatSubmit: 'yyyy-mm-dd',
                hiddenName: true,
            })
        },

        dateTimePicker: function () {
            var date = new Date();
            date.setDate(date.getDate());
            var dateFormat = $('meta[name=date-format]').attr('content');
            if (dateFormat === 'dd/mm/yyyy') {
                var formattedDateFormat = 'd/m/Y';
            } else if (dateFormat === 'mm/dd/yyyy') {
                var formattedDateFormat = 'm/d/Y';
            } else if (dateFormat === 'yyyy/mm/dd') {
                var formattedDateFormat = 'Y/m/d';

            } else if (dateFormat === 'yyyy/dd/mm') {
                var formattedDateFormat = 'Y/d/m';
            }
                $('.datetimepicker').datetimepicker(
                {
                    format: formattedDateFormat + " H:i",
                    minDate: date,
                    step: 10,
                });
        },

        dateRangePicker: function () {
            var dateFormat = $('meta[name=date-format]').attr('content');
            if (dateFormat === 'dd/mm/yyyy') {
                var formattedDateFormat = 'DD/MM/YYYY';
            } else if (dateFormat === 'mm/dd/yyyy') {
                var formattedDateFormat = 'MM/DD/YYYY';
            } else if (dateFormat === 'yyyy/mm/dd') {
                var formattedDateFormat = 'YYYY/MM/DD';

            } else if (dateFormat === 'yyyy/dd/mm') {
                var formattedDateFormat = 'YYYY/DD/MM';
            }
            moment.defaultFormat = formattedDateFormat;

            var startEndParam = seller_admin.base.getUrlVars()["search%5Bstart_end_range%5D"]
            if (startEndParam === undefined) {
                var start = moment(moment(), moment.defaultFormat);
                var end = moment(moment(), moment.defaultFormat);
            } else {
                var startParam = startEndParam.split('-')[0];
                var start = moment(decodeURIComponent(startParam.replace(/\+/g, '%20')).trim(), moment.defaultFormat);

                var endParam = startEndParam.split('-')[1];
                var end = moment(decodeURIComponent(endParam.replace(/\+/g, '%20')).trim(), moment.defaultFormat);
            }


            function cb(start, end) {
                $('.dateranger span').html(start + ' - ' + end);
            }

            $('.dateranger').daterangepicker({
                startDate: start,
                endDate: end,
                opens: 'left',
                cancelLabel: 'Clear',
                locale: {
                    format: moment.defaultFormat
                },
                ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }
            }, cb);

            cb(start, end);
        },

        mdbootstrap: function () {
            $('.mdb-select').materialSelect();
            // SideNav Button Initialization
            $(".button-collapse").sideNav({
                breakpoint: 1026
            });
            // SideNav Scrollbar Initialization
            var sideNavScrollbar = document.querySelector('.custom-scrollbar');
            if (sideNavScrollbar != null) {
                var ps = new PerfectScrollbar(sideNavScrollbar);
            }
            // fix for error wrapping on form text field labels
            $('.field_with_errors + label').addClass('active');
            // fix for double click dropdown arrow
            $('.mdb-select').click(e => e.stopPropagation());

            $(function () {
                $('[data-toggle="tooltip"]').tooltip()
            });

            // fix for dropdowns inside table-responsive class
            $('.table-responsive').on('shown.bs.dropdown', function (e) {
                var t = $(this),
                    m = $(e.target).find('.dropdown-menu'),
                    tb = t.offset().top + t.height(),
                    mb = m.offset().top + m.outerHeight(true),
                    d = 20; // Space for shadow + scrollbar.
                if (t[0].scrollWidth > t.innerWidth()) {
                    if (mb + d > tb) {
                        t.css('padding-bottom', ((mb + d) - tb));
                    }
                } else {
                    t.css('overflow', 'visible');
                }
            }).on('hidden.bs.dropdown', function () {
                $(this).css({
                    'padding-bottom': '',
                    'overflow': ''
                });
            });
        },

        initDataTables: function(targetElem, columnCount){
            $(targetElem).DataTable(
                {
                    searching: false,
                    info: false,
                    paging: false,
                    columnDefs: [
                        { orderable: false, targets: columnCount }
                    ]
                }
            );
        },

        // Possibly now a redundant js function
        populateCountryAlpha2: function () {
            $('body').on('change', '#billing_country', function () {
            });
        },

        string_parameterize: function (string) {
            return string.trim().toLowerCase().replace(/[^a-zA-Z0-9 -]/, "").replace(/\s/g, "-");
        },

        setupApiAccess: function () {
            window.baseApiUrl = $('meta[name=base-api-url]').attr('content');
            $.ajax(
                {
                    url: '/admin/api/retrieve_token',
                    type: "GET",
                    async: false,
                    dataType: "json",
                    success: function (data) {
                        $('meta[name=api-access-token]').attr('content', data["access-token"]);
                        $('meta[name=api-client]').attr('content', data["client"]);
                    }
                });
        },

        removeSelectOptions: function (selectInput) {
            var i;
            for (i = selectInput.options.length - 1; i >= 0; i--) {
                selectInput.remove(i);
            }
        },

        makeId: function (length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },

        newColorPicker: function (targetElem, defaultColor, inputField) {
            new Pickr({
                el: targetElem,
                default: defaultColor,
                components: {
                    preview: true,
                    opacity: true,
                    hue: true,

                    interaction: {
                        hex: true,
                        rgba: true,
                        input: true,
                        clear: true,
                        save: true
                    }
                },
                onChange(hsva, instance) {
                    $(inputField).val(hsva.toRGBA().toString());
                }
            });
        },

        isValidURL: function (u) {
            var elm;
            if (!elm) {
                elm = document.createElement('input');
                elm.setAttribute('type', 'url');
            }
            elm.value = u;
            return elm.validity.valid;
        },

        createDesignPreviewButton: function (element_id) {
            $('body').on('click', element_id, function (e) {
                var modal = document.querySelector(".is-modal.previewcontent");

                _cb.showModal(modal);

                //check if builder is inside iframe
                if (window.frameElement) {
                    var c = getFramedWindow(window.frameElement);
                    var doc = c.document;
                } else {
                    var doc = parent.document;
                }

                var basehref = "";
                var base = doc.querySelectorAll("base[href]");
                if (base.length > 0) {
                    basehref = '<base href="' + base[0].href + '" />';
                }

                var csslinks = "";
                var styles = doc.querySelectorAll("link[href]");
                for (var i = 0; i < styles.length; i++) {
                    if (
                        styles[i].href.indexOf(".css") != -1 &&
                        styles[i].href.indexOf("contentbox.css") == -1 &&
                        styles[i].href.indexOf("contentbuilder.css") == -1
                    ) {
                        csslinks +=
                            '<link href="' +
                            styles[i].href +
                            '" rel="stylesheet" type="text/css" />';
                    }
                }

                var jsincludes = "";
                var scripts = doc.querySelectorAll("script[src]");
                for (var i = 0; i < scripts.length; i++) {
                    if (
                        scripts[i].src.indexOf(".js") != -1 &&
                        scripts[i].src.indexOf("index.js") == -1 &&
                        scripts[i].src.indexOf("contentbox.js") == -1 &&
                        scripts[i].src.indexOf("contentbox.min.js") == -1 &&
                        scripts[i].src.indexOf("contentbuilder.js") == -1 &&
                        scripts[i].src.indexOf("contentbuilder.min.js") == -1 &&
                        scripts[i].src.indexOf("plugin.js") == -1 &&
                        scripts[i].src.indexOf("config.js") == -1 &&
                        scripts[i].src.indexOf("en.js") == -1 &&
                        scripts[i].src.indexOf("minimalist-blocks") == -1
                    ) {
                        jsincludes +=
                            '<script src="' +
                            scripts[i].src +
                            '" type="text/javascript"></script>';
                    }
                }

                // No script
                jsincludes = '';

                /* Get Page */
                if (!document.querySelector(".is-wrapper")) {
                    var maxwidth = "800px";
                    var maxw = window.getComputedStyle(document.querySelector(".is-builder")).getPropertyValue('max-width');
                    if (!isNaN(parseInt(maxw))) maxwidth = maxw;

                    var content = _cb.html();

                    var doc = modal.querySelector('iframe').contentWindow.document;
                    doc.open();
                    doc.write(
                        "<html>" +
                        "<head>" +
                        basehref +
                        '<meta charset="utf-8">' +
                        "<title></title>" +
                        csslinks +
                        "<style>" +
                        ".slider-image { display:block !important; }" +
                        ".container {margin:35px auto 0; max-width: " +
                        maxwidth +
                        "; width:100%; padding:0 35px; box-sizing: border-box;}" +
                        "</style>" +
                        '<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>' +
                        "</head>" +
                        "<body>" +
                        '<div class="container">' +
                        content +
                        "</div>" +
                        jsincludes +
                        "</body>" +
                        "</html>"
                    );
                    doc.close();

                } else {
                    // ContentBox
                    var content = jQuery(".is-wrapper")
                        .data("contentbox")
                        .html();

                    var doc = modal.querySelector('iframe').contentWindow.document;
                    doc.open();
                    doc.write(
                        "<html>" +
                        "<head>" +
                        basehref +
                        '<meta charset="utf-8">' +
                        "<title></title>" +
                        csslinks +
                        "<style>" +
                        ".slider-image { display:block !important; }" +
                        "</style>" +
                        '<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>' +
                        "</head>" +
                        "<body>" +
                        '<div class="is-wrapper">' +
                        content +
                        "</div>" +
                        jsincludes +
                        "</body>" +
                        "</html>"
                    );
                    doc.close();

                }
                //Or you can specify your custom preview page:
                //modal.find('iframe').attr('src','preview.html');

                e.preventDefault();
            });
        },

        newWYSIWYG: function (element_id) {
            $(element_id).mdbWYSIWYG();
        },

        scrollTop: function (element) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $(element).offset().top
            }, 1000);
        },

        addFlash: function (alertClass, message) {
            $('#seller-admin-flashes').prepend('<div class="mt-4 alert alert-' + alertClass + '" role="alert">' + message + '</div>');
        },

        addCustomFlash: function (element, alertClass, message) {
            $(element).prepend('<div class="mt-4 alert alert-' + alertClass + '" role="alert">' + message + '</div>');
        },

        loadingModal: function (modalId, message) {
            return '<div style="z-index:100000;" class="modal" id="' + modalId + '" tabindex="-1" role="dialog" aria-labelledby="loadingMessageLabel" aria-hidden="true" data-backdrop="static"><div class="modal-dialog modal-notify modal-primary" role="document"><div class="modal-content"><div class="modal-body"><h4 class="h4-responsive black-text font-weight-bold mt-2 mb-5 text-center"><strong style="font-family: "Roboto", sans-serif;">' + message + '</strong></h4><div class="d-flex justify-content-center mb-4"><div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div></div></div></div></div></div>'
        },

        assignLoadingModal: function (modalId, message) {
            $('#seller-admin-loading-modal').html(seller_admin.base.loadingModal(modalId, message));
        },

        enableLoadingModal: function (modalId, wrapper) {
            var loadingElem = $(modalId);

            loadingElem.modal('show');
            wrapper.find('button').attr('disabled', true);
            wrapper.find('input[type=submit]').attr('disabled', true);
            wrapper.find('.modal-content-overlay').show();
        },

        disableLoadingModal: function (modalId, wrapper) {
            var loadingElem = $(modalId);

            wrapper.find('button').attr('disabled', false);
            wrapper.find('input[type=submit]').attr('disabled', false);
            wrapper.find('.modal-content-overlay').hide();
            loadingElem.modal('hide');
        },

        changeSearchLimitParam: function () {
            $('#limit_param').on('change', function () {
                window.location = seller_admin.base.updateQueryStringParameter(window.location.href, 'per_page', this.value);
                $('#search_limit').val(this.value);
            });
        },

        successfulDesignerAutoSave: function () {
            $('#failed-autosave-animation').hide();
            $('#success-autosave-animation').show();
            setTimeout(function () {
                $('#success-autosave-animation').hide();
            }, 3000);
        },

        failedDesignerAutoSave: function () {
            $('#success-autosave-animation').hide();
            $('#failed-autosave-animation').show();
            setTimeout(function () {
                $('#failed-autosave-animation').hide();
            }, 3000);
        },

        disabledLink: function () {
            $('body').on('click', '.disabled', function () {
                return false;
            });
        },

        confirmationModal: function (triggerClass, message, recordId, secondaryRecordId) {
            return '<div class="modal" id="confirmation-form" tabindex="-1" role="dialog" aria-labelledby="confirmationModalLabel"><div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-header"><h5 class="modal-title" id="confirmationLabel">Are you sure?</h5><button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button></div><div class="modal-body"><p>' + message + '</p></div><div class="modal-footer"> <button type="button" class="btn btn-default" data-dismiss="modal">Cancel</button> <a href="#" data-record-id="' + recordId + '" data-secondary-record-id="' + secondaryRecordId + '" class="btn btn-success ' + triggerClass + '">Confirm</a></div></div></div></div>';
        },

        assignConfirmationModal: function (triggerClass, message, recordId, secondaryRecordId) {
            $('#seller-admin-confirmation-modal').html(seller_admin.base.confirmationModal(triggerClass, message, recordId, secondaryRecordId));
        },

        enableConfirmationModal: function () {
            $('#confirmation-form').modal('show');
        },

        disableConfirmationModal: function () {
            $('#confirmation-form').modal('hide');
        },

        largestInArray: function(array) {
            return Math.max.apply({}, array);
        }
    }

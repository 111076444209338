seller_admin.lite =
    {
        revealAuthToken: function () {
            $('.reveal-auth-token').on('click', function () {
                $('#store-authentication-token').removeClass('blur');
                $('.hide-auth-token').show();
                $('.reveal-auth-token').hide();
                return false;
            });
        },

        hideAuthToken: function () {
            $('.hide-auth-token').on('click', function () {
                $('#store-authentication-token').addClass('blur');
                $('.reveal-auth-token').show();
                $('.hide-auth-token').hide();
                return false;
            });
        },

        verifyDomain: function() {
            $('#verify-domain-trigger').on('click', function() {
                seller_admin.base.openModal("#verifyingDomainModal");
                $('.verify-domain-flash').remove();
                $.ajax(
                    {
                        url: '/admin/settings/product_embed/verify_domain',
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (data.success) {
                                $('.verified-domain-badge-wrapper').html('<span id="verified-domain-badge" class="badge badge-success">Verified</span>');
                                seller_admin.base.addCustomFlash('#verified-domain-section', 'success verify-domain-flash', 'Successfully verified the TXT record for your domain.');
                                seller_admin.base.scrollTop("#verified-domain-section");
                            } else {
                                $('.verified-domain-badge-wrapper').html('<span id="verified-domain-badge" class="badge badge-danger">Pending</span>');
                                seller_admin.base.addCustomFlash('#verified-domain-section', 'danger verify-domain-flash', 'Could not verify the TXT record for your domain, please try again.');
                                seller_admin.base.scrollTop("#verified-domain-section");
                            }
                            $('#verifyingDomainModal').modal('hide');
                        },
                        error: function (xhr, evt, status) {
                            $('.verified-domain-badge-wrapper').html('<span id="verified-domain-badge" class="badge badge-danger">Pending</span>');
                            seller_admin.base.addCustomFlash('#verified-domain-section', 'danger verify-domain-flash', 'Could not verify TXT record, please try again.');
                            seller_admin.base.scrollTop("#verified-domain-section");
                            $('#verifyingDomainModal').modal('hide');
                        }
                    });
                return false;
            });
        },


        openProductEmbedModal: function()
        {
              $('#open-product-embed-modal').click(function() {
                  seller_admin.base.openModal('#product-lite-embed-form')
              });
        },

        embedProductVariant: function()
        {
            window.baseApiUrl = $('meta[name=base-api-url]').attr('content');
            $('body').on('change', '#lite_product_id', function() {
                var productId = $(this).val(),
                    accessToken = $('meta[name=api-access-token]').attr('content'),
                    clientId = $('meta[name=api-client]').attr('content'),
                    storeId = $('meta[name=store-id]').attr('content'),
                    uid = $('meta[name=api-uid]').attr('content'),
                    selectElem = $('#lite_sale_option');

                $('#product-lite-embed-form .modal-content-overlay').show();
                $.ajax(
                    {
                        url: window.baseApiUrl + '/v1/store/products/' + productId + '/sale_options',
                        headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            selectElem.empty();
                            selectElem.append('<option>Select Sale Option</option>');
                            for (var i = 0; i < data.sale_options.length; i++) {
                                selectElem.append('<option value="' + data.sale_options[i]['key'] + '">' + data.sale_options[i]['name'] + '</option>');
                            }
                            $('#lite-embed-sale-option').css('display', 'flex');
                            $('#product-lite-embed-form .modal-content-overlay').hide();
                        },
                        error: function (xhr, evt, status) {
                            $('#product-lite-embed-form .modal-content-overlay').hide();
                        }
                    });
            });
        },

        embedProductSaleOption: function()
        {
            window.baseApiUrl = $('meta[name=base-api-url]').attr('content');
            $('body').on('change', '#lite_sale_option', function() {
                var saleOption = $(this).val(),
                    productId = $('#lite_product_id').val(),
                    accessToken = $('meta[name=api-access-token]').attr('content'),
                    clientId = $('meta[name=api-client]').attr('content'),
                    storeId = $('meta[name=store-id]').attr('content'),
                    uid = $('meta[name=api-uid]').attr('content'),
                    selectElemPlan = $('#lite_plan_id'),
                    selectElemVariant = $('#lite_variant_id');

                $('#product-lite-embed-form .modal-content-overlay').show();
                if(saleOption ==='subscription' || saleOption == 'gift')
                {
                    if(saleOption ==='subscription') {
                        $.ajax(
                            {
                                url: window.baseApiUrl + '/v1/store/products/' + productId + '/plans/subscription',
                                headers: {
                                    'access-token': accessToken,
                                    'client': clientId,
                                    'uid': uid,
                                    'store-id': storeId
                                },
                                type: "GET",
                                dataType: "json",
                                success: function (data) {
                                    selectElemPlan.empty();
                                    for (var i = 0; i < data.plans.length; i++) {
                                        selectElemPlan.append('<option value="' + data.plans[i]['uid'] + '">' + data.plans[i]['name'] + '</option>');
                                    }
                                    $('#lite-embed-plan-id').css('display', 'flex');
                                    seller_admin.lite.updateProductEmbed();
                                    $('#product-lite-embed-form .modal-content-overlay').hide();
                                },
                                error: function (xhr, evt, status) {
                                    $('#product-lite-embed-form .modal-content-overlay').hide();
                                }
                            });
                    }
                    if (saleOption == 'gift')
                    {
                        $.ajax(
                            {
                                url: window.baseApiUrl + '/v1/store/products/' + productId + '/plans/gift',
                                headers: {
                                    'access-token': accessToken,
                                    'client': clientId,
                                    'uid': uid,
                                    'store-id': storeId
                                },
                                type: "GET",
                                dataType: "json",
                                success: function (data) {
                                    selectElemPlan.empty();
                                    for (var i = 0; i < data.plans.length; i++) {
                                        selectElemPlan.append('<option value="' + data.plans[i]['uid'] + '">' + data.plans[i]['name'] + '</option>');
                                    }
                                    $('#lite-embed-plan-id').css('display', 'flex');
                                    seller_admin.lite.updateProductEmbed();
                                    $('#product-lite-embed-form .modal-content-overlay').hide();
                                },
                                error: function (xhr, evt, status) {
                                    $('#product-lite-embed-form .modal-content-overlay').hide();
                                }
                            });
                    }
                    $.ajax(
                        {
                            url: window.baseApiUrl + '/v1/store/products/' + productId + '/variants',
                            headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                            type: "GET",
                            dataType: "json",
                            success: function (data) {
                                selectElemVariant.empty();
                                for (var i = 0; i < data.variants.length; i++) {
                                    var skuName = data.variants[i]['sku'],
                                        skuName = skuName === 'MA' ? 'MA (Master)' : skuName;
                                    selectElemVariant.append('<option value="' + data.variants[i]['uid'] + '">' + skuName + '</option>');
                                }
                                $('#lite-embed-variant-id').css('display', 'flex');
                                seller_admin.lite.updateProductEmbed();
                                $('#product-lite-embed-form .modal-content-overlay').hide();
                            },
                            error: function (xhr, evt, status) {
                                $('#product-lite-embed-form .modal-content-overlay').hide();
                            }
                        });
                } else {
                    $('#lite-embed-plan-id').hide();
                    $.ajax(
                        {
                            url: window.baseApiUrl + '/v1/store/products/' + productId + '/variants',
                            headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                            type: "GET",
                            dataType: "json",
                            success: function (data) {
                                selectElemVariant.empty();
                                for (var i = 0; i < data.variants.length; i++) {
                                    var skuName = data.variants[i]['sku'],
                                        skuName = skuName === 'MA' ? 'MA (Master)' : skuName;
                                    selectElemVariant.append('<option value="' + data.variants[i]['uid'] + '">' + skuName + '</option>');
                                }
                                $('#lite-embed-variant-id').css('display', 'flex');
                                seller_admin.lite.updateProductEmbed();
                                $('#product-lite-embed-form .modal-content-overlay').hide();
                            },
                            error: function (xhr, evt, status) {
                                $('#product-lite-embed-form .modal-content-overlay').hide();
                            }
                        });
                }
            });
        },

        productEmbedTrigger: function()
        {
            $('#product-lite-embed-form #lite_button_border').on('change', function() {
                seller_admin.lite.updateProductEmbed();
            });
            $('#product-lite-embed-form #lite_button_background_color').on('change', function() {
                seller_admin.lite.updateProductEmbed();
            });
            $('#product-lite-embed-form #lite_button_background_color').on('change', function() {
                seller_admin.lite.updateProductEmbed();
            });
            var textInput = document.getElementById('lite_button_txt'),
                timeout = null;

            if (typeof (textInput) != 'undefined' && textInput != null) {
                textInput.onkeyup = function (e) {

                    clearTimeout(timeout);

                    timeout = setTimeout(function () {
                        seller_admin.lite.updateProductEmbed();
                    }, 500);
                };
            }
        },

        newColorPicker: function (targetElem, defaultColor, inputField) {
            new Pickr({
                el: targetElem,
                default: defaultColor,
                components: {
                    preview: true,
                    opacity: true,
                    hue: true,

                    interaction: {
                        hex: true,
                        rgba: true,
                        input: true,
                        clear: true,
                        save: true
                    }
                },
                onChange(hsva, instance) {
                    $(inputField).val(hsva.toRGBA().toString());
                    seller_admin.lite.updateProductEmbed();
                }
            });
        },

        updateProductEmbed: function()
        {
            var backgroundColorVal = $('#product-lite-embed-form #lite_button_background_color').val(),
                textColorVal = $('#product-lite-embed-form #lite_button_text_color').val(),
                borderDecorationVal = $('#product-lite-embed-form #lite_button_border').val(),
                borderDecoration = borderDecorationVal === 'sharp' ? '0.125rem' : '0.25rem',
                buttonText = $('#product-lite-embed-form #lite_button_txt').val(),
                variantId = $('#product-lite-embed-form #lite_variant_id').val(),
                planId = $('#product-lite-embed-form #lite_plan_id').val(),
                saleOption = $('#product-lite-embed-form #lite_sale_option').val(),
                cartItemTriggerClass = saleOption === 'single_purchase' ? 'sublite-add-single-cart-item' : 'sublite-add-recurring-cart-item'

            if(saleOption === 'single_purchase') {
                planId = undefined;
            }
            if(planId === undefined) {
                var buttonHtml = '<div class="sublite-btn ' + cartItemTriggerClass + '" style="background-color:' + backgroundColorVal + '!important;color:'+ textColorVal + '!important;border-radius:' + borderDecoration + '" data-sublite-variant-id="' + variantId + '" data-sublite-sale-option="' + saleOption + '">' + buttonText + '</div>'
            } else {
                var buttonHtml = '<div class="sublite-btn ' + cartItemTriggerClass + '" style="background-color:' + backgroundColorVal + ' !important;color:'+ textColorVal + '!important;border-radius:' + borderDecoration + '" data-sublite-variant-id="' + variantId + '" data-sublite-plan-id="' + planId + '" data-sublite-sale-option="' + saleOption + '">' + buttonText + '</div>'
            }
            $('#product-embed-demo-element').html(buttonHtml);
            $('#product_embed_button_code').val(buttonHtml);
        }
    }
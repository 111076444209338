seller_admin.customer = {
    duplicateAddress: function () {
        $('.use-delivery-address').change(function () {
            if (this.checked) {
                $('.copy-delivery').each(function () {
                    var fieldValue = $(this).val(),
                        fieldAttribute = $(this).attr('data-field-name');
                    return $('[data-field-name="billing-' + fieldAttribute + '"]').val(fieldValue).focus();
                });
            } else {
                return $('input[data-field-name*="billing-"]').val('');
            }
        });
    },

    selectAllCustomers: function () {
        $("#check_all_customers").on('click', function () {
            $('.customer_checkbox').prop('checked', this.checked);
        });
    },

    createCsvReport: function () {
        $('#export-customer-csv-button').on('click', function () {
            $('.csv-report-flash').remove();
            var customerIds = [];
            $.each($(".customer_checkbox:checked"), function () {
                customerIds.push($(this).val());
            });
            if (customerIds.length === 0) {
                $('#seller-admin-flashes').prepend('<div class="alert alert-warning csv-report-flash" role="alert">You need to select some orders before requesting a CSV report.</div>');
                seller_admin.base.scrollTop("main");
            } else {
                $.ajax(
                    {
                        url: '/admin/customer_csvs',
                        type: 'POST',
                        data: {'customer_ids': customerIds},
                        dataType: 'json',
                        success: function (data) {
                            $('#seller-admin-flashes').prepend('<div class="alert alert-success csv-report-flash" role="alert">Successfully created the CSV report. Please check your store email for a link to the file.</div>')
                            seller_admin.base.scrollTop("main");
                            $('.customer_checkbox').prop('checked', false);
                        },
                        error: function (xhr, evt, status) {
                            $('#seller-admin-flashes').prepend('<div class="alert alert-warning csv-report-flash" role="alert">Unable to create the CSV report. Please try again.</div>');
                            seller_admin.base.scrollTop("main");
                        }
                    });
            }
            return false;
        });
    },

    changePassword: function () {
        $('body').on('submit', '.change-customer-password-form', function () {
            var $this = $(this),
                url = $(this).attr('action');

            seller_admin.base.assignLoadingModal("loadingChangeCustomerPassword", "Updating the customer's password...");
            seller_admin.base.enableLoadingModal("#loadingChangeCustomerPassword", $this);

            $.ajax(
                {
                    url: url,
                    type: 'PATCH',
                    data: $this.serialize(),
                    dataType: 'json',
                    success: function (data) {
                        $('#user-confirmed-status').text('Yes');
                        $('#change-customer-password-form').modal('hide');
                        seller_admin.base.disableLoadingModal("#loadingChangeCustomerPassword", $this);
                        seller_admin.base.addFlash("success", "Successfully updated the customer's password.");
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        $('#change-customer-password-form').modal('hide');
                        seller_admin.base.disableLoadingModal("#loadingChangeCustomerPassword", $this);
                        seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        seller_admin.base.scrollTop("main");
                    }
                });
            return false;
        });
    },
}
seller_admin.dunning =
    {
        updateDataByDate: function () {
            $('#dunning-date-ranger').on('apply.daterangepicker', function(ev, picker) {
                var dateRange = $(this).val().replace(/ /g,'');

                seller_admin.dunning.getDashboardData(dateRange);
            });
            $('select#churn-product-id').on('change', function()
            {
                var dateRange = $('#dunning-date-ranger').val().replace(/ /g,'');
                seller_admin.dunning.getDashboardData(dateRange);
            });
        },

        getDashboardData: function (dateRange) {

            $.ajax(
                {
                    url: '/admin/dunning/analytics/date_range?start_end_range=' + dateRange,
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        $('#dunning-total-data #recovered-revenue').text(data.recovered_revenue);
                        $('#dunning-date-data #dunning-email-1').text(data.dunning_1);
                        $('#dunning-date-data #dunning-email-2').text(data.dunning_2);
                        $('#dunning-date-data #dunning-email-3').text(data.dunning_3);
                    }
                });
        }
    }
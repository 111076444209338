seller_admin.checkout_additional_fee =
    {
        add: function () {
            $('body').on('click', '.create-checkout-additional-fee', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingAddCheckoutAdditionalFee", "Creating checkout additional fee...");
                seller_admin.base.enableLoadingModal("#loadingAddCheckoutAdditionalFee", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#checkout-additional-fee-modal').html(data.modal);
                            seller_admin.base.openModal('#checkout-additional-fee-form');
                            $('#checkout-additional-fee-modal .mdb-select').materialSelect();
                            $('#checkout-additional-fee-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingAddCheckoutAdditionalFee", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingAddCheckoutAdditionalFee", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        create: function () {
            $('body').on('submit', '#create-checkout-additional-fee', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingAddCheckoutAdditionalFee", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#checkout-additional-fee-form').modal('hide');
                            $('#checkout-additional-fees-table tbody').prepend(data.row);
                            seller_admin.base.addCustomFlash('#additional-fee-section', 'success', 'Successfully added a checkout additional fee to your store.');
                            seller_admin.base.disableLoadingModal("#loadingAddCheckoutAdditionalFee", $this);
                            seller_admin.base.scrollTop("#additional-fee-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingAddCheckoutAdditionalFee", $this);
                        }
                    });
                return false;
            });
        },

        edit: function () {
            $('body').on('click', '.edit-checkout-additional-fee', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingEditCheckoutAdditionalFee", "Updating checkout additional fee...");
                seller_admin.base.enableLoadingModal("#loadingEditCheckoutAdditionalFee", $this);


                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#checkout-additional-fee-modal').html(data.modal);
                            seller_admin.base.openModal('#checkout-additional-fee-form');
                            $('#checkout-additional-fee-modal .mdb-select').materialSelect();
                            $('#checkout-additional-fee-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingEditCheckoutAdditionalFee", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingEditCheckoutAdditionalFee", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        update: function () {
            $('body').on('submit', '#edit-checkout-additional-fee', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingEditCheckoutAdditionalFee", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#checkout-additional-fee-form').modal('hide');
                            $('#checkout-additional-fees-table tbody tr#checkout-additional-fee-' + data.checkout_additional_fee_id).replaceWith(data.row);
                            seller_admin.base.addCustomFlash('#additional-fee-section', 'success', 'Successfully updated a checkout additional fee to your store.');
                            seller_admin.base.disableLoadingModal("#loadingEditCheckoutAdditionalFee", $this);
                            seller_admin.base.scrollTop("#additional-fee-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingEditCheckoutAdditionalFee", $this);
                        }
                    });
                return false;
            });
        },

        initSortable: function () {
            $('#sortable-checkout-additional-fee-list').sortable({
                placeholder: "ui-state-highlight",
                forcePlaceholderSize: true,
                tolerance: "pointer",
                update: function (e, ui) {
                    seller_admin.base.enableLoadingModal("#loadingSortCheckoutAdditionalFee", $('#sort-checkout-additional-fees-form'));
                    $.ajax(
                        {
                            url: $(this).data("url"),
                            type: "PATCH",
                            data: $(this).sortable('serialize'),
                            success: function (data) {
                                $('#checkout-additional-fees-table tbody').html(data.table);
                                seller_admin.base.disableLoadingModal("#loadingSortCheckoutAdditionalFee", $('#sort-checkout-additional-fees-form'));
                            },
                            error: function (xhr, evt, status) {
                                seller_admin.base.disableLoadingModal("#loadingSortCheckoutAdditionalFee", $('#sort-checkout-additional-fees-form'));
                                seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                                seller_admin.base.scrollTop("main");
                            }
                        });
                }
            });
            $("#sortable-checkout-additional-fee-list").disableSelection();
        },

        sortModal: function () {
            $('body').on('click', '#reorder-checkout-additional-fees', function () {
                var url = $(this).attr('href');

                seller_admin.base.assignLoadingModal("loadingSortCheckoutAdditionalFee", "Sorting your checkout additional fees...");
                seller_admin.base.enableLoadingModal("#loadingSortCheckoutAdditionalFee", $('#sort-checkout-additional-fees-form'));

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#sort-checkout-additional-fees-wrapper').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingSortCheckoutAdditionalFee", $('#sort-checkout-additional-fees-form'));
                            seller_admin.base.openModal('#sort-checkout-additional-fees-form');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingSortCheckoutAdditionalFee", $('#sort-checkout-additional-fees-form'));
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        remove: function()
        {
            $('body').on('click', '.prompt-remove-checkout-additional-fee', function() {
                var checkoutTrustId = $(this).attr('data-record-id');

                seller_admin.base.assignConfirmationModal('remove-checkout-additional-fee',
                    'Are you sure you wish to remove a checkout additional fee?', checkoutTrustId);
                seller_admin.base.enableConfirmationModal();
                return false;
            });
            $('body').on('click', '.remove-checkout-additional-fee', function () {
                var $this = $(this),
                    checkoutTrustId = $this.attr('data-record-id');

                seller_admin.base.disableConfirmationModal();
                seller_admin.base.assignLoadingModal("loadingRemoveCheckoutAdditionalFee", "Removing checkout additional fee from your store...");
                seller_admin.base.enableLoadingModal("#loadingRemoveCheckoutAdditionalFee", $this);

                $.ajax(
                    {
                        url: '/admin/settings/checkout_additional_fees/' + checkoutTrustId,
                        type: 'DELETE',
                        dataType: 'json',
                        success: function (data) {
                            $('tr#checkout-additional-fee-' + checkoutTrustId).remove();
                            seller_admin.base.addCustomFlash('#additional-fee-section','success', 'Successfully removed a checkout additional fee from your store.');
                            seller_admin.base.disableLoadingModal("#loadingRemoveCheckoutAdditionalFee", $this);
                            seller_admin.base.scrollTop("#additional-fee-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingRemoveCheckoutAdditionalFee", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }
seller_admin.notification =
    {
        bindNavbarNotifications: function (channel) {
            channel.bind('navbar_notifications', function (data) {
                $('.notifications-nav #unread-notification-count').text(data.unread_count);
                var existingNotification = $('.notifications-nav .dropdown-menu a#notification-' + data.uid);
                if (existingNotification.length < 1) {
                    $('.notifications-nav .dropdown-menu').prepend(data.html);
                }
            });
        },

        markAsRead: function () {
            $('.notifications-nav').on('show.bs.dropdown', function () {
                var notificationIds = [];
                $('.notifications-nav .dropdown-menu a.dropdown-item[data-unread="true"]').each(function () {
                    notificationIds.push($(this).attr('data-notification-id'));
                    $(this).attr('data-unread', 'false')
                });
                if (notificationIds.length > 0) {
                    $('.notifications-nav #unread-notification-count').text(0);
                    $.ajax(
                        {
                            url: '/admin/notifications/read',
                            type: 'PATCH',
                            data: {notification_ids: notificationIds},
                            dataType: 'json',
                            success: function (data) {
                            }
                        });
                }
            });
        },

        markAllAsRead: function () {
            $('body').on('click', '#mark-all-notifications-read', function () {
               var notificationDataIds = $(this).attr('data-notification-ids'),
                   notificationIds = notificationDataIds.split(",").map(Number);
                if (notificationIds.length > 0) {
                    $('.notifications-nav #unread-notification-count').text(0);
                    $.ajax(
                        {
                            url: '/admin/notifications/read',
                            type: 'PATCH',
                            data: {notification_ids: notificationIds},
                            dataType: 'json',
                            success: function (data) {
                                window.location.reload();
                            }
                        });
                }
                return false;
            });
        }
    }
seller_admin.email_integration =
    {
        revealApiKey: function () {
            $('.reveal-api-key').on('click', function () {
                $('#email-integration-api-key').removeClass('blur');
                $('.hide-api-key').show();
                $('.reveal-api-key').hide();
                return false;
            });
        },

        hidelApiKey: function () {
            $('.hide-api-key').on('click', function () {
                $('#email-integration-api-key').addClass('blur');
                $('.reveal-api-key').show();
                $('.hide-api-key').hide();
                return false;
            });
        },

        add: function () {
            $('body').on('click', '.create-email-integration', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingAddEmailIntegration", "Creating email integration...");
                seller_admin.base.enableLoadingModal("#loadingAddEmailIntegration", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#email-integration-modal').html(data.modal);
                            seller_admin.base.openModal('#email-integration-form');
                            $('#email-integration-modal .mdb-select').materialSelect();
                            $('#email-integration-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingAddEmailIntegration", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingAddEmailIntegration", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        create: function () {
            $('body').on('submit', '#create-email-integration', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingAddEmailIntegration", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#email-integration-form').modal('hide');
                            $('#email-integrations-table tbody').prepend(data.email_integration_row);
                            seller_admin.email_integration.updateActiveList(data.email_integration_array);
                            seller_admin.base.addCustomFlash('#email-integrations-section', 'success', 'Successfully added an email integration to your store.');
                            seller_admin.base.disableLoadingModal("#loadingAddEmailIntegration", $this);
                            seller_admin.base.scrollTop("#email-integrations-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingAddEmailIntegration", $this);
                        }
                    });
                return false;
            });
        },

        edit: function () {
            $('body').on('click', '.edit-email-integration', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingEditEmailIntegration", "Updating email integration...");
                seller_admin.base.enableLoadingModal("#loadingEditEmailIntegration", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#email-integration-modal').html(data.modal);
                            seller_admin.base.openModal('#email-integration-form');
                            $('#email-integration-modal .mdb-select').materialSelect();
                            $('#email-integration-modal .mdb-select').click(e => e.stopPropagation());
                            seller_admin.base.disableLoadingModal("#loadingEditEmailIntegration", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingEditEmailIntegration", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        update: function () {
            $('body').on('submit', '#edit-email-integration', function () {
                var $this = $(this),
                    url = $this.attr('action');

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#email-integration-form').modal('hide');
                            $('#email-integrations-table tbody tr#email-integration-' + data.email_integration_id).replaceWith(data.email_integration_row);
                            seller_admin.base.addCustomFlash('#email-integrations-section', 'success', 'Successfully updated an email integration to your store.');
                            seller_admin.base.disableLoadingModal("#loadingEditEmailIntegration", $this);
                            seller_admin.base.scrollTop("#email-integrations-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingEditEmailIntegration", $this);
                        }
                    });
                return false;
            });
        },

        updateActiveList: function (email_integration_array) {
            var $select = $('#store_email_integration_id');
            $select.empty();
            $.each(email_integration_array, function (index, option) {
                $select.append($("<option></option>")
                    .attr("value", option.value).text(option.text));
            });
            $('#email-integration-modal .mdb-select').materialSelect({
                destroy: true
            });
            $('#email-integration-modal .mdb-select').materialSelect();
            $('#email-integration-modal .mdb-select').click(e => e.stopPropagation());
        },

        updateMailchimpForm: function () {
            var selectElem = $('#create-email-integration #email_integration_provider');
            if (selectElem.val() === 'mailchimp')
            {

                $('#create-email-integration #account-id-wrapper').hide().val('');
            }
            $('body').on('change', '#create-email-integration #email_integration_provider', function() {
                if ($(this).val() === 'mailchimp') {
                    $('#create-email-integration #account-id-wrapper').hide().val('');
                } else {
                    $('#create-email-integration #account-id-wrapper').show();
                }
            });
        },

        remove: function()
        {
            $('body').on('click', '.prompt-remove-email-integration', function() {
                var emailIntegrationId = $(this).attr('data-record-id');

                seller_admin.base.assignConfirmationModal('remove-email-integration',
                    'Are you sure you wish to remove your email integration?', emailIntegrationId);
                seller_admin.base.enableConfirmationModal();
                return false;
            });
            $('body').on('click', '.remove-email-integration', function () {
                var $this = $(this),
                    emailIntegrationId = $this.attr('data-record-id');

                seller_admin.base.disableConfirmationModal();
                seller_admin.base.assignLoadingModal("loadingRemoveEmailIntegration", "Removing email integration from your store...");
                seller_admin.base.enableLoadingModal("#loadingRemoveEmailIntegration", $this);

                $.ajax(
                    {
                        url: '/admin/email_integrations/' + emailIntegrationId,
                        type: 'DELETE',
                        dataType: 'json',
                        success: function (data) {
                            $('tr#email-integration-' + emailIntegrationId).remove();
                            seller_admin.base.addCustomFlash('#email-integrations-section', 'success', 'Successfully removed an email integration from your store.');
                            seller_admin.base.disableLoadingModal("#loadingRemoveEmailIntegration", $this);
                            seller_admin.base.scrollTop("#email-integrations-section");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingRemoveEmailIntegration", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        }
    }



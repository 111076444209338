ready = function () {
    seller_admin.base.mdbootstrap();
    seller_admin.base.subscribeStoreChannel();
    seller_admin.base.populateCountryAlpha2();
    seller_admin.base.datePicker();
    seller_admin.base.dateRangePicker();
    seller_admin.base.initCharCounter("#product_description", "#product-description-char-count", 400);
    seller_admin.base.initCharCounter("#store_nav_design_announce_text", "#nav-design-announce-char-count", 104);
    seller_admin.base.initCharCounter("#store_statement_descriptor", "#statement-descriptor-char-count", 22);
    seller_admin.base.initDragDropFileUpload($('.file-upload'));
    seller_admin.base.changeSearchLimitParam();
    seller_admin.base.disabledLink();

    seller_admin.base.initDataTables('#products-table', 4);
    seller_admin.base.initDataTables('#product-search-table', 4);
    seller_admin.base.initDataTables('#collections-table', 2);
    seller_admin.base.initDataTables('#collection-search-table', 2);
    seller_admin.base.initDataTables('#inventory-table', 3);

    seller_admin.dashboard.updateDataByDate();
    seller_admin.dashboard.resetData();
    seller_admin.churn.updateDataByDate();
    seller_admin.dunning.updateDataByDate();

    seller_admin.notification.markAsRead();
    seller_admin.notification.markAllAsRead();

    seller_admin.onboarding.changePlan();
    seller_admin.onboarding.termsDisablePlaceOrder();

    seller_admin.tax_rule.add();
    seller_admin.tax_rule.create();
    seller_admin.tax_rule.edit();
    seller_admin.tax_rule.update();
    seller_admin.tax_rule.archive();

    seller_admin.shipping.add();
    seller_admin.shipping.create();
    seller_admin.shipping.edit();
    seller_admin.shipping.update();
    seller_admin.shipping.remove();
    seller_admin.shipping.onChangeWeightUnit();

    seller_admin.app_integration.initAppCategoryFilter();

    seller_admin.email_integration.revealApiKey();
    seller_admin.email_integration.hidelApiKey();
    seller_admin.email_integration.add();
    seller_admin.email_integration.create();
    seller_admin.email_integration.edit();
    seller_admin.email_integration.update();
    seller_admin.email_integration.remove();
    seller_admin.email_integration.updateMailchimpForm();

    seller_admin.email_integration_rule.add();
    seller_admin.email_integration_rule.create();
    seller_admin.email_integration_rule.edit();
    seller_admin.email_integration_rule.update();
    seller_admin.email_integration_rule.remove();
    seller_admin.email_integration_rule.changeFilterType();

    seller_admin.inventory.newStock();
    seller_admin.inventory.createStock();

    seller_admin.collection.updateNameToSlug();
    seller_admin.collection.formatSlug();
    seller_admin.collection.initProductsSortable();
    seller_admin.collection.sortProductsModal();
    seller_admin.collection.archive();
    seller_admin.collection.unarchive();
    seller_admin.collection.remove();

    seller_admin.product.create();
    seller_admin.product.edit();
    seller_admin.product.updateNameToSlug();
    seller_admin.product.formatSlug();
    seller_admin.product.scheduledPublishModal();
    seller_admin.product.scheduledPublish();
    seller_admin.product.initImagesSortable();
    seller_admin.product.newDuplicate();
    seller_admin.product.createDuplicate();
    seller_admin.product.preArchiveWarning();
    seller_admin.product.confirmArchive();
    seller_admin.product.unarchive();
    seller_admin.product.publish();
    seller_admin.product.unpublish();
    seller_admin.product.changeShippingType();
    seller_admin.product.changeProductType();
    seller_admin.product.imageVariantsModal();
    seller_admin.product.updateImageVariants();
    seller_admin.product.createCsvReport();
    seller_admin.product.changeGoogleMerchant();

    seller_admin.variant.initTagit();
    seller_admin.variant.addOptionType();
    seller_admin.variant.removeOptionType();
    seller_admin.variant.add();
    seller_admin.variant.create();
    seller_admin.variant.edit();
    seller_admin.variant.update();
    seller_admin.variant.archive();
    seller_admin.variant.sortEditOptionTypesModal();
    seller_admin.variant.sortEditOptionValuesModal();
    seller_admin.variant.createModalOptiontype();
    seller_admin.variant.createOptionType();
    seller_admin.variant.removeModalOptiontype();
    seller_admin.variant.removeModalOptiontypeSubmit();


    seller_admin.plan.purchaseDateRebillSelect();
    seller_admin.plan.purchaseDateRebillOnLoad();
    seller_admin.plan.archive();
    seller_admin.plan.sortModal();
    seller_admin.plan.planTypeSelect();

    seller_admin.gift.archive();
    seller_admin.gift.sortModal();

    seller_admin.stock_movement.createStockCollection();

    seller_admin.transaction.newRefund();
    seller_admin.transaction.createRefund();

    seller_admin.order.selectAllOrders();
    seller_admin.order.selectAllOrderAttributes();
    seller_admin.order.openCsvReportModal();
    seller_admin.order.createCsvReport();
    seller_admin.order.retryPayment();
    seller_admin.order.shopSingleFulfillOrder();
    seller_admin.order.shopMarkSingleAsShipped();
    seller_admin.order.singleFulfillOrder();
    seller_admin.order.bulkFulfillOrder();
    seller_admin.order.markSingleAsShipped();
    seller_admin.order.markCollectionAsShipped();
    seller_admin.order.createSingleEmailPdf();
    seller_admin.order.createCombinedEmailPdf();
    seller_admin.order.pickedUpOrder();

    seller_admin.subscription.createSingleEmailPdf();
    seller_admin.subscription.createCombinedEmailPdf();
    seller_admin.subscription.pause();

    seller_admin.order_item.editTracking();
    seller_admin.order_item.updateTracking();
    seller_admin.order_item.singleFulfillOrder();
    seller_admin.order_item.bulkFulfillOrder();
    seller_admin.order_item.markSingleAsShipped();
    seller_admin.order_item.markCollectionAsShipped();

    seller_admin.page.updateNameToSlug();
    seller_admin.page.formatSlug();
    seller_admin.page.sortPagesModal();
    seller_admin.page.newDuplicate();
    seller_admin.page.createDuplicate();
    seller_admin.page.publish();
    seller_admin.page.remove();

    seller_admin.billing.updateCountryAlpha2();

    seller_admin.nav_item.addFormChangeNavType();
    seller_admin.nav_item.sortModal();
    seller_admin.nav_item.remove();

    seller_admin.template.initTemplateCategoryFilter();
    seller_admin.template.previewModal();

    seller_admin.discount.create();
    seller_admin.discount.edit();
    seller_admin.discount.changeDiscountSaleOption();
    seller_admin.discount.changeDiscountType();
    seller_admin.discount.archive();
    seller_admin.discount.changeFilterType();

    seller_admin.cart_abandonment.publishEmail();
    seller_admin.cart_abandonment.unpublishEmail();
    seller_admin.cart_abandonment.sendTestEmailModal();
    seller_admin.cart_abandonment.sendTestEmail();
    seller_admin.cart_abandonment.cancel();

    seller_admin.incentive_email.publish();
    seller_admin.incentive_email.unpublish();
    seller_admin.incentive_email.sendTestEmailModal();
    seller_admin.incentive_email.sendTestEmail();

    seller_admin.review_email.publish();
    seller_admin.review_email.unpublish();
    seller_admin.review_email.sendTestEmailModal();
    seller_admin.review_email.sendTestEmail();

    seller_admin.dunning_email.sendTestEmailModal();
    seller_admin.dunning_email.sendTestEmail();

    seller_admin.member_post.archive();
    seller_admin.member_post.unarchive();
    seller_admin.member_post.newDuplicate();
    seller_admin.member_post.createDuplicate();
    seller_admin.member_post.initSortableList();
    seller_admin.member_post.sortModal();
    seller_admin.member_post.publish();
    seller_admin.member_post.updateTitleToSlug();
    seller_admin.member_post.formatSlug();

    seller_admin.member_category.archive();
    seller_admin.member_category.unarchive();
    seller_admin.member_category.sortModal();

    seller_admin.order_bump.create();
    seller_admin.order_bump.edit();
    seller_admin.order_bump.changeFilterType();
    seller_admin.order_bump.archive();
    seller_admin.order_bump.unarchive();
    seller_admin.order_bump.changeOverridePrice();

    seller_admin.blog_category.archive();
    seller_admin.blog_category.unarchive();
    seller_admin.blog_category.updateNameToSlug();
    seller_admin.blog_category.formatSlug();

    seller_admin.blog_post.archive();
    seller_admin.blog_post.unarchive();
    seller_admin.blog_post.updateNameToSlug();
    seller_admin.blog_post.formatSlug();
    seller_admin.blog_post.publish();

    seller_admin.customer.duplicateAddress();
    seller_admin.customer.selectAllCustomers();
    seller_admin.customer.createCsvReport();
    seller_admin.customer.changePassword();

    seller_admin.checkout_trust.add();
    seller_admin.checkout_trust.create();
    seller_admin.checkout_trust.edit();
    seller_admin.checkout_trust.update();
    seller_admin.checkout_trust.remove();
    seller_admin.checkout_trust.sortModal();

    seller_admin.checkout_additional_fee.add();
    seller_admin.checkout_additional_fee.create();
    seller_admin.checkout_additional_fee.edit();
    seller_admin.checkout_additional_fee.update();
    seller_admin.checkout_additional_fee.remove();
    seller_admin.checkout_additional_fee.sortModal();

    seller_admin.pick_up_location.add();
    seller_admin.pick_up_location.create();
    seller_admin.pick_up_location.edit();
    seller_admin.pick_up_location.update();
    seller_admin.pick_up_location.sortModal();
    seller_admin.pick_up_location.remove();

    seller_admin.vendor.sendAccountSetupEmail();
    seller_admin.vendor.block();
    seller_admin.vendor.unblock();
    seller_admin.vendor.remove();

    seller_admin.setting.changeDefaultShippingCountry();

    seller_admin.pause_option.archive();
    seller_admin.pause_option.unarchive();

    seller_admin.survey.archive();
    seller_admin.survey.unarchive();
    seller_admin.survey.sortTemplateCategoriesModal();

    seller_admin.lite.revealAuthToken();
    seller_admin.lite.hideAuthToken();
    seller_admin.lite.verifyDomain();
    seller_admin.lite.openProductEmbedModal();
    seller_admin.lite.embedProductVariant();
    seller_admin.lite.embedProductSaleOption();
    seller_admin.lite.productEmbedTrigger();

    seller_admin.free_gift.changeFilterType();
    seller_admin.free_gift.selectProduct();
    seller_admin.free_gift.remove();
    seller_admin.free_gift.archive();
    seller_admin.free_gift.unarchive();

    seller_admin.review.edit();
    seller_admin.review.update();
    seller_admin.review.archive();
    seller_admin.review.unarchive();

    seller_admin.api.revealAuthToken();
    seller_admin.api.hideAuthToken();
}

$(document).ready(ready);

$(document).ajaxComplete(function() {

    seller_admin.variant.initTagit();

    seller_admin.variant.initOptionTypeSortable();
    seller_admin.variant.initSortableOptionValueList();
    seller_admin.nav_item.initSortable();
    seller_admin.pick_up_location.initSortable();
    seller_admin.page.initSortable();
    seller_admin.gift.initSortableList();
    seller_admin.plan.initSortableList();
    seller_admin.member_category.initSortable();
    seller_admin.member_post.initSortableList();
    seller_admin.checkout_trust.initSortable();
    seller_admin.checkout_additional_fee.initSortable();
    seller_admin.survey.initOptionsSortable();

    $("input[type=text]").trigger("focusin");
    $("input[type=text]").trigger("blur");
});
seller_admin.product =
    {
        create: function () {
            $("body").on("submit", '#new-product-form', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.assignLoadingModal("loadingCreateProduct", "Creating product...");
                seller_admin.base.enableLoadingModal("#loadingCreateProduct", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $this[0].submit();
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingCreateProduct", $this);
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.scrollTop("#add-product-header");
                        }
                    });
                return false;
            });
        },

        edit: function () {
            $("body").on("submit", '#edit-product-form', function () {
                var $this = $(this),
                    url = $this.attr('action');


                seller_admin.base.assignLoadingModal("loadingUpdateProduct", "Updating product...");
                seller_admin.base.enableLoadingModal("#loadingUpdateProduct", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $this[0].submit();
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUpdateProduct", $this);
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.scrollTop("#edit-product-header");
                        }
                    });
                return false;
            });
        },

        updateNameToSlug: function () {
            var nameInput = document.getElementById('product_name'),
                slugInput = document.getElementById('product_slug'),
                slugLabel = $('label[for="product_slug"]');

            // Init a timeout variable to be used below
            var timeout = null;

            // Listen for keystroke events
            if (typeof (nameInput) != 'undefined' && nameInput != null) {
                nameInput.onkeyup = function (e) {

                    // Clear the timeout if it has already been set.
                    // This will prevent the previous task from executing
                    // if it has been less than <MILLISECONDS>
                    clearTimeout(timeout);

                    // Make a new timeout set to go off in 800ms
                    timeout = setTimeout(function () {
                        if (slugInput.value === "") {
                            slugInput.value = seller_admin.base.string_parameterize(nameInput.value);
                            slugLabel.addClass('active');
                        }
                    }, 2000);
                };
            }
        },

        formatSlug: function () {
            var slugInput = document.getElementById('product_slug');

            // Init a timeout variable to be used below
            var timeout = null;

            // Listen for keystroke events
            if (typeof (slugInput) != 'undefined' && slugInput != null) {
                slugInput.onkeyup = function (e) {

                    // Clear the timeout if it has already been set.
                    // This will prevent the previous task from executing
                    // if it has been less than <MILLISECONDS>
                    clearTimeout(timeout);

                    // Make a new timeout set to go off in 800ms
                    timeout = setTimeout(function () {
                        slugInput.value = seller_admin.base.string_parameterize(slugInput.value);
                    }, 500);
                };
            }
        },

        scheduledPublishModal: function () {
            $('body').on('click', '.scheduled-product-publish', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingProductScheduledPublish", "Scheduling your product for publication...");
                seller_admin.base.enableLoadingModal("#loadingProductScheduledPublish", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#scheduled-product-publish-wrapper').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingProductScheduledPublish", $this);
                            seller_admin.base.openModal('#scheduledProductPublishModal');
                            seller_admin.base.dateTimePicker();

                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingProductScheduledPublish", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        scheduledPublish: function () {
            $('body').on('submit', '#scheduled-product-publish-form', function () {
                var $this = $(this),
                    url = $this.attr('action'),
                    productId = $this.attr('data-product-id');

                seller_admin.base.enableLoadingModal("#loadingProductScheduledPublish", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#scheduledProductPublishModal').modal('hide');
                            $('tr.product-' + productId + ' td.product-publish-status').text('Draft (Scheduled: ' + data.scheduled_publish_at + ')');
                            seller_admin.base.disableLoadingModal("#loadingProductScheduledPublish", $this);
                            seller_admin.base.addFlash("success", "Successfully scheduled a product for publication.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingProductScheduledPublish", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        checkImageCap: function () {
            if ($('#product-images .fields:visible').length === 6) {
                $('#add-product-image-wrapper').hide();
            } else {
                $('#add-product-image-wrapper').show();
            }
        },

        checkVideoCap: function () {
            if ($('#product-videos .fields:visible').length === 1) {
                $('#add-product-video-wrapper').hide();
            } else {
                $('#add-product-video-wrapper').show();
            }
        },

        initImagesSortable: function () {
            $('#product-images').sortable({
                placeholder: "ui-state-highlight",
                forcePlaceholderSize: true,
                tolerance: "pointer",
                update: function (e, ui) {
                    $("ul#product-images li").each(function (index) {
                        $(this).find('.product-image-position').val(index);
                    });
                }
            });
            $("#product-images").disableSelection();
        },

        newDuplicate: function () {
            $('body').on('click', '.duplicate-product', function () {
                var $this = $(this),
                    url = $this.attr('href');

                seller_admin.base.assignLoadingModal("loadingProductDuplication", "Duplicating your product...");
                seller_admin.base.enableLoadingModal("#loadingProductDuplication", $this);

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#duplicate-product-wrapper').html(data.modal);
                            seller_admin.base.disableLoadingModal("#loadingProductDuplication", $this);
                            seller_admin.base.openModal('#duplicate-product-modal');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingProductDuplication", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        createDuplicate: function () {
            $('body').on('submit', '#duplicate-product-form', function () {
                var $this = $(this),
                    url = $this.attr('action');

                seller_admin.base.enableLoadingModal("#loadingProductDuplication", $this);
                $.ajax(
                    {
                        url: url,
                        type: 'POST',
                        data: $this.serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#duplicate-product-modal').modal('hide');
                            seller_admin.base.disableLoadingModal("#loadingProductDuplication", $this);
                            seller_admin.base.addFlash("success", "Successfully duplicated the product, updates will appear in a few moments.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                            seller_admin.base.disableLoadingModal("#loadingProductDuplication", $this);
                        }
                    });
                return false;
            });
        },

        initNewTags: function () {
            $('#new-product-tags').materialChip({
                placeholder: 'Enter a tag',
                secondaryPlaceholder: '+Tag',
            });
            seller_admin.product.tagFieldCallbacks();
        },

        initEditTags: function () {
            seller_admin.base.setupApiAccess();
            var productId = $('#edit-product-header').attr('data-product-id'),
                accessToken = $('meta[name=api-access-token]').attr('content'),
                clientId = $('meta[name=api-client]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                uid = $('meta[name=api-uid]').attr('content');
            $.ajax(
                {
                    url: window.baseApiUrl + '/v1/store/products/' + productId + '/tags',
                    headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                    type: "GET",
                    dataType: "json",
                    success: function (data) {
                        var dataTags = [];
                        for (var i in data.tags) {
                            var item = data.tags[i];

                            dataTags.push({
                                "tag": item.name
                            })
                        }
                        $('#new-product-tags').materialChip({
                            placeholder: 'Enter a tag',
                            secondaryPlaceholder: '+Tag',
                            data: dataTags
                        });
                        seller_admin.product.tagFieldCallbacks();
                    }
                });

        },

        tagFieldCallbacks: function () {
            $('#new-product-tags').on('chip.add', function (e, chip) {
                var new_id = new Date().getTime();
                $('#product-tags-fields').append('<div class="fields">' +
                    '<input value="' + chip.tag + '" type="hidden" name="product[tags_attributes][' + new_id + '][name]" id="product_tags_attributes_' + new_id + '_name">' +
                    '<input type="hidden" value="0" name="product[tags_attributes][' + new_id + '][_destroy]" id="product_tags_attributes_' + new_id + '__destroy" data-input-value="' + chip.tag + '">');
            });
            $('#new-product-tags').on('chip.delete', function (e, chip) {
                $('#product-tags-fields input[data-input-value="' + chip.tag + '"]').val('1');
            });
        },

        preArchiveWarning: function () {
            $('body').on('click', '.archive-product', function () {
                var $this = $(this),
                    productId = $this.attr('data-product-id'),
                    productPlans = $this.attr('data-product-plans');

                seller_admin.base.assignLoadingModal("loadingArchiveProduct", "Archiving your product...");

                if (productPlans === "true") {
                    $('.confirm-archive-product').attr('data-product-id', productId);
                    seller_admin.base.openModal('#pre-archive-product-modal');
                } else {
                    seller_admin.base.enableLoadingModal("#loadingArchiveProduct", $this);
                    seller_admin.product.archive($this);
                }
            });
        },

        archive: function (link) {
            var $this = link,
                productId = $this.attr('data-product-id'),
                productSearchTable = $('table#product-search-table'),
                productRow = $('tr.product-' + productId);

            $.ajax(
                {
                    url: '/admin/products/' + productId + '/archive',
                    type: 'PATCH',
                    dataType: 'json',
                    success: function (data) {
                        if (productRow.length > 0) {
                            if (productSearchTable.length > 0) {
                                productRow.replaceWith(data.row);
                            } else {
                                productRow.remove();
                            }
                        } else {
                            $('#single-product-header span').replaceWith('<span class="badge badge-danger ml-2">Archived</span>');
                            $('a.archive-product').replaceWith('<a class="dropdown-item unarchive-product" data-product-id="' + productId + '" rel="nofollow" data-method="patch" href="/admin/products/' + productId + '/unarchive">Unarchive</a>');
                        }
                        $('#pre-archive-product-modal').modal('hide');
                        seller_admin.base.disableLoadingModal("#loadingArchiveProduct", $this);
                        seller_admin.base.addFlash("success", "Successfully archived a product.");
                        seller_admin.base.scrollTop("main");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingArchiveProduct", $this);
                        admin.base.addFlash("danger", "Something went wrong. Please try again.");
                        admin.base.scrollTop("main");
                    }
                });
        },

        confirmArchive: function () {
            $('body').on('click', '.confirm-archive-product', function () {
                seller_admin.base.enableLoadingModal("#loadingArchiveProduct", $('#pre-archive-product-modal'));
                seller_admin.product.archive($(this));
                return false;
            });
        },

        unarchive: function () {
            $('body').on('click', '.unarchive-product', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    productId = $this.attr('data-product-id'),
                    productSearchTable = $('table#product-search-table'),
                    productRow = $('tr.product-' + productId);

                seller_admin.base.assignLoadingModal("loadingUnarchiveProduct", "Unarchiving your product...");
                seller_admin.base.enableLoadingModal("#loadingUnarchiveProduct", $this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            if (productRow.length > 0) {
                                if (productSearchTable.length > 0) {
                                    productRow.replaceWith(data.row);
                                } else {
                                    productRow.remove();
                                }
                            } else {
                                $('#single-product-header span').replaceWith('<span class="badge badge-warning ml-2">Draft</span>');
                                $('a.unarchive-product').replaceWith('<a class="dropdown-item archive-product" data-product-id="' + productId + '" href="#" data-product-plans="' + data.has_product_plans + '">Archive</a>');
                            }
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveProduct", $this);
                            seller_admin.base.addFlash("success", "Successfully unarchived a product.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUnarchiveProduct", $this);
                            admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        publish: function () {
            $('body').on('click', '.publish-product', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    productId = $this.attr('data-product-id');
                seller_admin.base.assignLoadingModal("loadingPublishProduct", "Publishing your product...");
                seller_admin.base.enableLoadingModal("#loadingPublishProduct", $this);
                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('tr.product-' + productId).replaceWith(data.row);
                            $('#product-publishing-buttons').html(data.buttons);
                            seller_admin.base.disableLoadingModal("#loadingPublishProduct", $this);
                            seller_admin.base.addFlash("success", "Successfully published a product.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingPublishProduct", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        unpublish: function () {
            $('body').on('click', '.unpublish-product', function () {
                var $this = $(this),
                    url = $this.attr('href'),
                    productId = $this.attr('data-product-id');

                seller_admin.base.assignLoadingModal("loadingUnpublishProduct", "Unpublishing your product...");
                seller_admin.base.enableLoadingModal("#loadingUnpublishProduct", $this);
                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            $('tr.product-' + productId).replaceWith(data.row);
                            $('#product-publishing-buttons').html(data.buttons);
                            seller_admin.base.disableLoadingModal("#loadingUnpublishProduct", $this);
                            seller_admin.base.addFlash("success", "Successfully unpublished a product.");
                            seller_admin.base.scrollTop("main");
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingUnpublishProduct", $this);
                            admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        changeShippingType: function () {
            $('select#product_shipping_type').on('change', function () {
                if (this.value === 'weight') {
                    $('#new-product-shippings').hide().html();
                    $('#edit-product-shippings').hide();
                    $('#add-product-shipping-wrapper').hide();
                    $('#product-row-section').hide();
                    $('#product-weight-wrapper').show();
                    $('#new-product-shippings').empty();
                } else {
                    $('#new-product-shippings').show();
                    $('#edit-product-shippings').show();
                    $('#add-product-shipping-wrapper').show();
                    $('#product-row-section').show();
                    $('#product-weight-wrapper').hide();
                }
            });
        },

        addImage: function (link, association, content) {
            var new_id = new Date().getTime(),
                regexp = new RegExp("new_" + association, "g");
            $('ul#product-images').append(content.replace(regexp, new_id));
            var productImageFileUpload = $('#product_images_attributes_' + new_id + '_imageable_type').parent().find('.file-upload-wrapper input');
            seller_admin.base.initDragDropFileUpload(productImageFileUpload);
            seller_admin.product.checkImageCap();
        },

        removeImage: function (link) {
            $(link).prev("input[type=hidden]").val("1");
            $(link).closest(".fields").hide();
            seller_admin.product.checkImageCap();
        },

        addShipping: function (link, association, content) {
            var new_id = new Date().getTime(),
                regexp = new RegExp("new_" + association, "g");
            $('#new-product-shippings').append(content.replace(regexp, new_id));
            var countryIdSelect = $('#product_shippings_attributes_' + new_id + '_country_ids'),
                countryIdInput = $('#product_shippings_attributes_' + new_id + '_country_ids_input');
            countryIdInput.attr('name', 'product[shippings_attributes][][country_ids][' + new_id + ']');
            countryIdSelect.materialSelect();
            countryIdSelect.click(e => e.stopPropagation());
            $('#product_shippings_attributes_' + new_id + '_price').focus();
        },

        editShipping: function (link, association, content) {
            var new_id = new Date().getTime(),
                regexp = new RegExp("new_" + association, "g");
            $('#edit-product-shippings').append(content.replace(regexp, new_id));
            var countryIdSelect = $('#product_shippings_attributes_' + new_id + '_country_ids'),
                countryIdInput = $('#product_shippings_attributes_' + new_id + '_country_ids_input');
            countryIdInput.attr('name', 'product[shippings_attributes][][country_ids][' + new_id + ']');
            countryIdSelect.materialSelect();
            countryIdSelect.click(e => e.stopPropagation());
            $('#product_shippings_attributes_' + new_id + '_price').focus();
        },

        removeShipping: function (link) {
            $(link).prev("input[type=hidden]").val("1");
            $(link).closest(".fields").hide();
            seller_admin.product.checkImageCap();
        },

        saveContent: function () {
            var $this = $(this),
                accessToken = $('meta[name=api-access-token]').attr('content'),
                clientId = $('meta[name=api-client]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                uid = $('meta[name=api-uid]').attr('content'),
                productId = $('.is-wrapper').attr('data-product-id'),
                html = window.contentBuilder.html();

            seller_admin.base.assignLoadingModal("loadingSaveProductContent", "Saving your product content...");
            seller_admin.base.enableLoadingModal("#loadingSaveProductContent", $this);

            $('#design-image-progress .progress-bar').css('width', '100%');

            $.ajax(
                {
                    url: window.baseApiUrl + '/v1/store/products/' + productId + '/update_draft_content',
                    headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                    type: 'PATCH',
                    data: {'product_content': {'content': html}},
                    dataType: 'json',
                    success: function (data) {
                        seller_admin.base.disableLoadingModal("#loadingSaveProductContent", $this);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE SUCCESSFUL");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.disableLoadingModal("#loadingSaveProductContent", $this);
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE FAILED");
                    }
                });
        },

        autoSaveContent: function () {
            var accessToken = $('meta[name=api-access-token]').attr('content'),
                clientId = $('meta[name=api-client]').attr('content'),
                storeId = $('meta[name=store-id]').attr('content'),
                uid = $('meta[name=api-uid]').attr('content'),
                productId = $('.is-wrapper').attr('data-product-id'),
                html = window.contentBuilder.html();

            $('#design-image-progress .progress-bar').css('width', '100%');

            $.ajax(
                {
                    url: window.baseApiUrl + '/v1/store/products/' + productId + '/update_draft_content',
                    headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                    type: 'PATCH',
                    data: {'product_content': {'content': html}},
                    dataType: 'json',
                    success: function (data) {
                        seller_admin.base.successfulDesignerAutoSave();
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE SUCCESSFUL");
                    },
                    error: function (xhr, evt, status) {
                        seller_admin.base.failedDesignerAutoSave();
                        $('#design-image-progress .progress-bar').css('width', '0%');
                        console.log("DESIGN UPDATE FAILED");
                    }
                });
        },


        publishContent: function () {
            $('body').on('click', '.publish-content-to-product', function () {
                var $this = $(this),
                    accessToken = $('meta[name=api-access-token]').attr('content'),
                    clientId = $('meta[name=api-client]').attr('content'),
                    storeId = $('meta[name=store-id]').attr('content'),
                    uid = $('meta[name=api-uid]').attr('content'),
                    productId = $('.is-wrapper').attr('data-product-id');

                seller_admin.base.assignLoadingModal("loadingPublishProductContent", "Publishing your product content...");
                seller_admin.base.enableLoadingModal("#loadingPublishProductContent", $this);

                $.ajax(
                    {
                        url: window.baseApiUrl + '/v1/store/products/' + productId + '/publish_draft_content',
                        headers: {'access-token': accessToken, 'client': clientId, 'uid': uid, 'store-id': storeId},
                        type: 'PATCH',
                        dataType: 'json',
                        success: function (data) {
                            seller_admin.base.openModal("#publishProductContentModal");
                            seller_admin.base.disableLoadingModal("#loadingPublishProductContent", $this);
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.disableLoadingModal("#loadingPublishProductContent", $this);
                            seller_admin.base.addFlash("danger", "Something went wrong. Please try again.");
                            seller_admin.base.scrollTop("main");
                        }
                    });
            });
        },

        changeProductType: function () {
            $('select#product_product_type').on('change', function () {
                if (this.value === 'digital') {
                    $('#product-row-section').hide();
                    $('.product-shipping-section').hide();
                    $('#new-product-shippings').html('');
                    $('#product-pricing-section').show();
                    $('#product-inventory-oos-section').show();
                    $('#product-inventory-quantity-section').show();
                    $('#product-fulfillment-section').hide();
                    $('#product-variant-section').show();
                    $('#product-sale-option-section').show();
                    $('#physical-incentive-save').hide();
                    $('#non-physical-incentive-save').show();
                } else if (this.value == 'physical_incentive') {
                    $('#product-row-section').hide();
                    $('.product-shipping-section').hide();
                    $('#product-pricing-section').hide();
                    $('#product-inventory-oos-section').hide();
                    $('#product-inventory-quantity-section').hide();
                    $('#product-fulfillment-section').hide();
                    $('#product-variant-section').hide();
                    $('#product-sale-option-section').hide();
                    $('#physical-incentive-save').show();
                    $('#non-physical-incentive-save').hide();
                } else {
                    $('#product-row-section').show();
                    $('.product-shipping-section').show();
                    $('#product-pricing-section').show();
                    $('#product-inventory-oos-section').show();
                    $('#product-inventory-quantity-section').show();
                    $('#product-fulfillment-section').show();
                    $('#product-variant-section').show();
                    $('#product-sale-option-section').show();
                    $('#physical-incentive-save').hide();
                    $('#non-physical-incentive-save').show();
                }
            });
        },

        imageVariantsModal: function () {
            $('body').on('click', '.edit-image-variants', function () {
                var url = $(this).attr('href');

                $.ajax(
                    {
                        url: url,
                        type: "GET",
                        dataType: "json",
                        success: function (data) {
                            $('#image-variants-wrapper').html(data.modal);

                            $('#image-variants-form .mdb-select').materialSelect();
                            seller_admin.base.openModal('#image-variants-form');
                        }
                    });
                return false;
            });
        },

        updateImageVariants: function () {
            $("body").on("submit", '.update-image-variants', function () {
                var url = $(this).attr('action'),
                    $this = $(this);

                $.ajax(
                    {
                        url: url,
                        type: 'PATCH',
                        data: $(this).serialize(),
                        dataType: 'json',
                        success: function (data) {
                            $('#image-variants-form').modal('hide');
                        },
                        error: function (xhr, evt, status) {
                            seller_admin.base.jsonErrors(xhr, evt, status, $this);
                        }
                    });
                return false;
            });
        },

        createCsvReport: function () {
            $('#export-product-csv-button').on('click', function () {
                $('.csv-report-flash').remove();
                var productIds = $('#product-list-section').attr('data-product-ids').split(',');

                $.ajax(
                    {
                        url: '/admin/product_csvs',
                        type: 'POST',
                        data: {'product_ids': productIds},
                        dataType: 'json',
                        success: function (data) {
                            $('#seller-admin-flashes').prepend('<div class="alert alert-success csv-report-flash" role="alert">Successfully created the CSV report. Please check your store email for a link to the file.</div>')
                            seller_admin.base.scrollTop("main");
                            $('.customer_checkbox').prop('checked', false);
                        },
                        error: function (xhr, evt, status) {
                            $('#seller-admin-flashes').prepend('<div class="alert alert-warning csv-report-flash" role="alert">Unable to create the CSV report. Please try again.</div>');
                            seller_admin.base.scrollTop("main");
                        }
                    });
                return false;
            });
        },

        changeGoogleMerchant: function () {
            $('select#product_google_merchant').on('change', function () {
                if (this.value === 'enabled') {
                    $('#google-merchant-product-wrapper').show();
                } else {
                    $('#google-merchant-product-wrapper').hide();
                }
            });
        }
    }